import React, { Component } from 'react';
import SelectPositiveNegative from './control-select-postive-negative'
import RadioYesNo from './control-radio-yes-no'
import ErrorSummary from './error-summary'
import { cloneDeep } from 'lodash'
import { radix_bases } from '../helpers/helper-number-bases';

import skip_logic_map from '../assets/json/diseases-skip-logic-map.json';
const keys_mapping = skip_logic_map.dcis;

class SkipLogicDCISForm extends Component {

  constructor(props) {
    super(props)

    let inputs = cloneDeep(keys_mapping);
    for (var key in inputs) {
      inputs[key] = null
    }
    inputs['ipsilateral'] = 'n'
    inputs['bilateral'] = 'n'

    this.state = {
      ...inputs,
      errorMessages: []
    }

  }

  async componentDidUpdate(prevProps) {
    if(prevProps.skip_logics.length != this.props.skip_logics.length) {
      for(var key in keys_mapping) {
        if (key.includes("age")) {continue;}

        var val = this.getValue(key)
        if(val === null) continue;

        if(key === "ipsilateral" || key === "bilateral") {
          if(val === 'n') {
            await this.setState({ [key]: 'n', [key.replace(key, key+"_age")]: null });
          } else if(parseInt(val, radix_bases.base10) === 0) {
            await this.setState({ [key]: 'y', [key.replace(key, key+"_age")]: null });
          } else {
            if (!isNaN(parseInt(val, radix_bases.base10))) {
              await this.setState({ [key]: 'y', [key.replace(key, key+"_age")]: val });
            }
          }
        } else {
          await this.setState({ [key]: val });
        }
      }

      // Reconstruct payload
      var skipLogicPayload = this.buildSkipLogicPayload();
      this.props.onUpdate(this.state, skipLogicPayload, true);

    }
  }

  getValue(key) {
    if(!(key in keys_mapping)) return null

    let code = keys_mapping[key]
    let data = this.props.skip_logics.find(item => item.skip_logic_code == code)

    if(typeof(data) === 'undefined') return null
    return data.answer
  }

  /*
  [
    {skip_logic_id, answer}
  ]
  */
  buildSkipLogicPayload() {
    let inputs = this.state;
    let mapping = keys_mapping;
    let skipLogicPayloads = []
    for (var key in mapping) {
      if (key.includes("age")) continue;

      var static_skip_logic_item = this.props.static_skip_logic.find(item => item.skip_logic_code === mapping[key])
      if (typeof (static_skip_logic_item) === 'undefined') {
        throw new Error('Skip Logic code not found');
      }

      var input = inputs[key];
      var answer = input && typeof(input) === "object" ? input.value : input;
      answer = input == null ? '' : input
      answer = typeof(answer) == 'boolean' ? answer : answer.trim()

      if(key === "ipsilateral" || key === "bilateral") {
        if(answer === 'y') {
          let age_key = key+"_age";
          if(inputs[age_key] === null) {
            answer = '0';
          } else if( !isNaN(parseInt(inputs[age_key], radix_bases.base10)) ) {
            answer = inputs[age_key];
          }
        }
      }

      if (answer) {
        skipLogicPayloads.push({
          skip_logic_code: static_skip_logic_item.skip_logic_code,
          skip_logic_id: static_skip_logic_item.id,
          short_name: static_skip_logic_item.short_name,
          answer
        })
      }


    }

    return skipLogicPayloads;
  }

  validateTripleNegative() {
    if(this.state.triple_negative === 'y') {
      let er = this.state.er;
      let pr = this.state.pr;
      let her2 = this.state.her2;
      return (er === 'n' && pr === 'n' && her2 === 'n')
    }

    return true;
  }

  async onChange(data) {
    try {
      await this.setState({ ...this.state, ...data, errorMessages: [] });
      this.toggleTripleNegative(data)
      let isValid = this.validateTripleNegative();
      if(isValid === false) {
        this.setState({errorMessages: ['Triple negative is not valid.']})
      }

      if(data.bilateral  && data.bilateral === "n") this.setState({bilateral_age: ''});
      if(data.ipsilateral && data.ipsilateral === "n") this.setState({ipsilateral_age: ''});
      let validAge = this.validateAge({...this.state, ...data});
      if(!validAge) {
        isValid = false;
        this.setState({errorMessages: ["The age of diagnosis cannot be greater than this person’s current age/age of death."]});
      }

      var skipLogicPayload = this.buildSkipLogicPayload();
      this.props.onUpdate(this.state, skipLogicPayload, isValid);

    } catch (error) {
      console.log(error)
      this.setState({ errorMessages: [error.message] })
    }
  }

  toggleTripleNegative(data) {
    if(('triple_negative' in data)) {
      if(this.state.triple_negative == 'y') {
        this.setState({
          er: 'n',
          pr: 'n',
          her2: 'n'
        })
      } else {
        this.setState({
          er: null,
          pr: null,
          her2: null
        })
      }
    } else if ('er' in data || 'pr' in data || 'her2' in data) {
      let { er, pr, her2 } = this.state
      let triple_negative = (er == 'n' && pr == 'n' && her2 =='n') ? 'y' : 'n'
      this.setState({triple_negative})
    }
  }

  validateAge(data) {
    let valid = true
    const { ipsilateral_age,  bilateral_age } = data;
    if(ipsilateral_age && !this.isAgeValid(ipsilateral_age)) {
      valid = false;
    }
    if(bilateral_age && !this.isAgeValid(bilateral_age)){
      valid = false;
    }
    return valid;
  }

  isAgeValid(value) {
    const { age } = this.props.profile;
    const should_validate = age !== null && age !== undefined && age !== '';
    if(should_validate) {
      return parseInt(value) <= parseInt(age);
    }
    return true;
  }

  render() {
    return (
      <div className="panel-form-2 sl-content colorectal-polyps">

        <ErrorSummary errorMessages={this.state.errorMessages} />

        <div className="form-horizontal">
          <div class="row">
            <div class="col-md-3">
              <div class="row margin-five">
                <label class="control-label col-md-6 col-sm-6 col-xs-12 mr-15">
                  Triple Negative
                </label>

                <div class="checkbox col-md-6 col-sm-6 col-xs-12">
                  <label class="switch">
                    <input
                      onChange={e => this.onChange({ triple_negative: e.target.checked ? 'y': 'n'})}
                      checked={this.state.triple_negative == 'y'}
                      type="checkbox"/>

                    <span class="slider round" />
                  </label>
                </div>

              </div>

              <div class="row margin-five">
                <label className="control-label col-md-4">ER</label>
                <div className="col-md-8">
                  <div className="custom-select">
                    <SelectPositiveNegative
                      name="er"
                      value={this.state.er}
                      onChange={(er) => this.onChange({ er: er.value })}
                    />
                  </div>
                </div>
              </div>

              <div class="row margin-five">
                <label className="control-label col-md-4">PR</label>
                <div className="col-md-8">
                  <div className="custom-select">
                    <SelectPositiveNegative
                      name="pr"
                      value={this.state.pr}
                      onChange={(pr) => this.onChange({ pr: pr.value })}
                    />
                  </div>
                </div>
              </div>

              <div class="row margin-five">
                <label className="control-label col-md-4">HER2</label>
                <div className="col-md-8">
                  <div className="custom-select">
                    <SelectPositiveNegative
                      name="her2"
                      value={this.state.her2}
                      onChange={(her2) => this.onChange({ her2: her2.value })}
                    />
                  </div>
                </div>
              </div>

              <div class="row margin-five">
                <label className="control-label col-md-4">CK14</label>
                <div className="col-md-8">
                  <div className="custom-select">
                    <SelectPositiveNegative
                      name="ck14"
                      value={this.state.ck14}
                      onChange={(ck14) => this.onChange({ ck14: ck14.value })}
                    />
                  </div>
                </div>
              </div>

              <div class="row margin-five">
                <label className="control-label col-md-4">CK5/6</label>
                <div className="col-md-8">
                  <div className="custom-select">
                    <SelectPositiveNegative
                      name="ck5_6"
                      value={this.state.ck5_6}
                      onChange={(ck5_6) => this.onChange({ ck5_6: ck5_6.value })}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-9">
              <div className="row margin-five">
                <div className="col-md-3 no-padding-top">
                  <label className="control-label">
                    More than one breast primary in same breast (ipsilateral)?
                  </label>
                </div>

                <div className="col-md-7 col-sm-10 col-xs-12">
                  <div class="row">
                    <div class="col-md-7">
                      <RadioYesNo
                        name="ipsilateral"
                        value={this.state.ipsilateral}
                        onChange={(ipsilateral) => this.onChange({ ipsilateral })}
                      />
                    </div>

                    <div class="col-md-2">
                      <label className="control-label no-margin-bottom">Age:</label>
                    </div>

                    <div class="col-md-3">
                      <input
                        readOnly={this.state.ipsilateral !== "y"}
                        name="ipsilateral_age"
                        value={this.state.ipsilateral_age}
                        type="text"
                        className="form-control normal-input-text"
                        onChange={(e) => this.onChange({ ipsilateral_age: e.target.value })}
                      />
                    </div>
                  </div>
                </div>

              </div>

              <div className="row margin-five">
                <div className="col-md-3 no-padding-top">
                  <label className="control-label">
                    Bilateral?
                  </label>
                </div>

                <div className="col-md-7 col-sm-10 col-xs-12">
                  <div class="row">
                    <div class="col-md-7">
                      <RadioYesNo
                        name="bilateral"
                        value={this.state.bilateral}
                        onChange={(bilateral) => this.onChange({ bilateral })}
                      />
                    </div>

                    <div class="col-md-2">
                      <label className="control-label no-margin-bottom">Age:</label>
                    </div>

                    <div class="col-md-3">
                      <input
                        readOnly={this.state.bilateral !== "y"}
                        name="bilateral_age"
                        value={this.state.bilateral_age}
                        type="text"
                        className="form-control normal-input-text"
                        onChange={(e) => this.onChange({ bilateral_age: e.target.value })}
                      />
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }

}

export default SkipLogicDCISForm
