import React, { Component } from "react";

class NotificationOther extends Component {
  render() {
    return null;
    let activeClass = this.props.show ? ' in active' : '';
    return (
      <div className={"tab-pane fade " + activeClass}>
        <div className=" margin-four-bottom">
          <a href="#" className="link">
            Dismiss all
          </a>
        </div>
        <div className="notif-item">
          <a href="#" className="del">
            <i className="fa fa-trash"></i>
          </a>
          <p>
            <strong>What's New in version 1.5</strong>
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo.
          </p>
          <p>
            <span className="date">June 12, 2019</span>{" "}
            <span className="margin-two-right time">3:49 PM</span>
          </p>
        </div>
        <div className="notif-item">
          <a href="#" className="del">
            <i className="fa fa-trash"></i>
          </a>
          <p>
            <strong>What's New in version 1.5</strong>
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo.
          </p>
          <p>
            <span className="date">June 12, 2019</span>{" "}
            <span className="margin-two-right time">3:49 PM</span>
          </p>
        </div>
        <div className="notif-item">
          <a href="#" className="del">
            <i className="fa fa-trash"></i>
          </a>
          <p>
            <strong>What's New in version 1.5</strong>
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo.
          </p>
          <p>
            <span className="date">June 12, 2019</span>{" "}
            <span className="margin-two-right time">3:49 PM</span>
          </p>
        </div>
        <div className=" margin-four-bottom">
          <a href="#" className="link">
            Show More <i className="fa fa-angle-down"></i>
          </a>
        </div>
      </div>
    );
  }
}

export default NotificationOther;
