import React, { Component } from 'react';
import { connect } from "react-redux";

import ActivityIndicator from "../activity-indicator";
import ErrorSummary from "../error-summary";
import api_auth from '../../api/authentication';
import helpers from '../../helpers/index';

import * as action_auth from '../../store/session/actions';
import { save_risk_settings } from '../../store/patient/actions';
import Cookies from 'js-cookie';

import {
  save_login_data
} from '../../helpers/helper-login';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      loading: false,
      errorMessages: [],
      networkLog: [],
      organization_locked: false,
      showPassword: false,
      samlUser: false,
      oauthLogin: false
    };
    this.handleChange  = this.handleChange.bind(this);
    this.handleSubmit  = this.handleSubmit.bind(this);
    this.togglePassword = this.togglePassword.bind(this);

    this.checkServerStatus = this.checkServerStatus.bind(this);
    this.logNetworkData    = this.logNetworkData.bind(this);
    this.loginTypeCheck = this.loginTypeCheck.bind(this);
    this.resetLogin = this.resetLogin.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async checkServerStatus(){
    try {
      let server_status = await api_auth.server_status();
      return server_status;
    } catch(err) {
      return false;
    }
  }

  logNetworkData(serverStatus, loginSuccess){
    let timeStamp = new Date()

    let log ={
      'clientTimeStamp': timeStamp,
      'user': this.state.username,
      'networkStatus': window.navigator.onLine,
      'serverStatus': serverStatus,
      'loginSuccess': loginSuccess
    }

    this.setState(prevState => ({
      networkLog: [...prevState.networkLog, log]
    }))
    Cookies.set('logStatus', JSON.stringify(this.state.networkLog))
  }

  handleChange(event) {
    const value = event.target.value;
    const name  = event.target.name;
    if(name == 'username') this.loginTypeCheck(value)
    this.setState({ [name]: value });
  }

  loginTypeCheck(value){
    if(value){
      api_auth.login_type_check(value).then((response)=>{
        if(response == 'saml'){
          this.props.hidePasswordLink()
            this.setState({samlUser: true})
        }else if(response =='oauth'){
          this.props.hidePasswordLink()
          this.setState({oauthLogin: true})
        }else if(this.state.oauthLogin || this.state.samlUser){
        this.resetLogin()
        }})
      }else if(this.state.oauthLogin || this.state.samlUser){
        this.resetLogin()
      }
  }

  resetLogin(){
    this.setState({oauthLogin: false, samlUser: false })
    this.props.showPasswordLink()
  }
  validate() {
    let options = {
      username: {
        labelName: 'Username',
        validations: { required: true }
      },
      password: {
        labelName: 'Password',
        validations: { required: true }
      }
    }

    let validations = helpers.validateInputs(this.state, options);
    this.setState({ errorMessages: validations.summary });

    return validations.isValid;

  }

  togglePassword() {
    const showPassword = !this.state.showPassword;
    this.setState({ showPassword: showPassword });
  }

  async handleSubmit(event) {
    event.preventDefault();

    const server_status = await this.checkServerStatus();

    if(!window.navigator.onLine || !server_status) this.logNetworkData(server_status, false)

    if (!window.navigator.onLine) {
      this.setState({errorMessages: ["No internet connection, please check you wifi or VPN"]});
      return;
    }

    if (!server_status) {
      this.setState({errorMessages: ["We apologize, our servers are currently down. Please try again momentarily."]});
      return;
    }

    // when using default login, remove downgrading to read only
    sessionStorage.removeItem('downgraded_to_read_only')
    sessionStorage.removeItem('is_emr_session')

    if(this.state.samlUser){
      let response = await api_auth.saml_login(this.state.username)
      window.open(response['redirect_url'],"_self")
      return
    }else if(this.state.oauthLogin){
      let response = await api_auth.get_oauth_redirect(this.state.username)
      Cookies.set('OAuth', response['state']);
      window.open(response['url'],"_self")
      return
    }else{
    try {
      this.setState({loading: true, errorMessages: []})

      let isValid = this.validate()
      if(!isValid) return

      //strip spaces
      if (typeof (String.prototype.trim) === "undefined") {
        String.prototype.trim = function () {
          return String(this).replace(/^\s+|\s+$/g, '');
        };
      }

      await this.setState({password: this.state.password.trim()})
      let payload = await api_auth.login(this.state.username, this.state.password)
      this.logNetworkData(server_status, true)

      save_login_data(this.props.session.user, payload, this.props.dispatch);
      this.props.dispatch(action_auth.authenticated(true));
      this.props.onLoginSuccess(payload.organization.org_type, payload.authenticator);

    } catch (error) {
      let parsedError = api_auth.parse_error_message(error);
      // this is a little brittle because if anythign changes in the string it fails, but okay for now
      // Same problem as locked organization but dont want to log a failed login because of an incorrect password
      this.logNetworkData(server_status, false);
      if(parsedError.message.toLowerCase() === 'organization_locked') {
        this.setState({ organization_locked: true });
      } else {
        this.setState({errorMessages: [parsedError.message]});
      }
      this.props.onLoginFail();

    } finally {

      if(this.mounted) {
        this.setState({ loading: false });
      }

    }
  }
}

  render() {
    let disabled = this.props.disabled ? ['Too many login attempts. Use the forgot password link below to reset your password.'] : this.state.errorMessages;
    let pswdTextField = this.state.showPassword ? "text" : "password";
    let slashedEye = this.state.showPassword ? "fa fa-eye" : "fa fa-eye-slash";
    return (
      <React.Fragment>

        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <input type="text"
                  className="form-control input-lg"
                  placeholder="Username"
                  required=""
                  id="username"
                  name="username"
                  onChange={this.handleChange}
                  disabled={this.props.disabled} />
          </div>
          {(this.state.samlUser || this.state.oauthLogin )&& (
            <div className="form-group">
              <i className="fa fa-lock"></i>
                Single sign-on enabled
            </div>
          )}
          {(!this.state.samlUser && !this.state.oauthLogin) && (
            <div className="form-group">
              <input type={pswdTextField}
                  className="form-control input-lg"
                  placeholder="Password"
                  required=""
                  id="password"
                  name="password"
                  onChange={this.handleChange}
                  disabled={this.props.disabled} />

                  <a className="reveal-password-eye" onClick={this.togglePassword}><i className={slashedEye}></i></a>
            </div>
          )}

          <div className="form-group">
            <button type="submit" className="btn btn-teal btn-block btn-lg no-margin-top" disabled={this.props.disabled}>Log in</button>
          </div>
        </form>

        {this.state.organization_locked && (
          <div className="alert alert-danger">
            <ul className="validation-summary">
              <li className="validation-summary__li">
                {'Your organization’s account is currently not active.  Please contact '}
                <a href="https://famgenix.com/contact/">FamGenix support</a>
                {' for assistance.'}
              </li>
            </ul>
          </div>
        )}

        <ErrorSummary loginError={true} errorMessages={disabled} />
        <ActivityIndicator loading={this.state.loading} />

      </React.Fragment>

    );
  }
}

const redux_state = state => ({
  session: state.session
});

const redux_actions = dispatch => ({
  dispatch: (action) => dispatch(action)
});

export default connect(redux_state, redux_actions)(LoginForm);
