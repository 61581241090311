import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import route_helper from "../../../route-helper";

import ActivityIndicator from "../../activity-indicator";
import ValidationErrorField from "../../validation-error-field";
import Select from "react-select";
import { RadioGroup, Radio } from "react-ui-icheck";
import settings from "../../../configs/settings";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import * as patient_actions from "../../../store/patient/actions";
import * as helper_family_tree from "../../../helpers/helper-family-tree";
import family_api from "../../../api/family-api";
import { cloneDeep } from "lodash";
import helpers from "../../../helpers/index";
import * as countryCodes from "./dial-code";
import lodash from "lodash";
import { yearBirthToAgeString } from "../../../helpers/age-utils";
import moment from "moment";
import mock from "../../../store/patient/mock";

import DatePicker from "react-date-picker";
import * as static_list_actions from "../../../store/static_list/actions";

class PatientPersonalData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unique_id: true,
      genderError: false,
      org_clinicians: [],
      initial_patient_clinicians: [],
    };

    this.onChange = this.onChange.bind(this);
    this.changeBiologicalGender = this.changeBiologicalGender.bind(this);
    this.changeGenderIdentity = this.changeGenderIdentity.bind(this);
    this.changePronoun = this.changePronoun.bind(this);
    this.changeEthnicity = this.changeEthnicity.bind(this);
    this.onPhoneSelect = this.onPhoneSelect.bind(this);
    this.onPhoneChange = this.onPhoneChange.bind(this);

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.updateReduxProfileByKey = this.updateReduxProfileByKey.bind(this);
    this.getStringValue = this.getStringValue.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);

    this.patient_id = null;
  }

  async componentDidMount() {
    try {
      let patient = null;

      if (this.props.isNewPatient) {
        patient = cloneDeep(mock.patient);
        patient.father_adopted = false;
        patient.mother_adopted = false;
      } else {
        if (this.props.member_id) {
          this.setState({ loading: true });
          await helper_family_tree.populateProband(
            this.props.dispatch,
            this.props.member_id
          );
          this.setState({ loading: false });
        }

        patient =
          this.props.getPedigreeData !== null &&
          this.props.getPedigreeData !== undefined
            ? cloneDeep(this.props.getPedigreeData().proband)
            : cloneDeep(this.props.patient);

        let profiles =
          this.props.getPedigreeData !== null &&
          this.props.getPedigreeData !== undefined
            ? this.props.getPedigreeData().profiles
            : this.props.profiles;
        let proband_patient =
          this.props.getPedigreeData !== null &&
          this.props.getPedigreeData !== undefined
            ? this.props.getPedigreeData().proband
            : this.props.patient;

        const father = profiles[proband_patient.father.rkey];
        const mother = profiles[proband_patient.mother.rkey];
        const father_adopted = father ? father.adopted_out : false;
        const mother_adopted = mother ? mother.adopted_out : false;
        patient.father_adopted = father_adopted;
        patient.mother_adopted = mother_adopted;
      }

      let dataKey = "father_adopted";
      let data = patient.father_adopted;
      let payload = { dataKey, data };
      this.props.dispatch(patient_actions.save_patient_data(payload));
      if (
        this.props.getPedigreeData !== undefined &&
        this.props.getPedigreeData !== null
      ) {
        let deep_clone = cloneDeep(this.props.getPedigreeData().proband);
        deep_clone["father_adopted"] = data;
        await this.props.getPedigreeData().setProband(deep_clone);
      }

      dataKey = "mother_adopted";
      data = patient.mother_adopted;
      payload = { dataKey, data };
      this.props.dispatch(patient_actions.save_patient_data(payload));
      if (
        this.props.getPedigreeData !== undefined &&
        this.props.getPedigreeData !== null
      ) {
        let deep_clone = cloneDeep(this.props.getPedigreeData().proband);
        deep_clone["mother_adopted"] = data;
        await this.props.getPedigreeData().setProband(deep_clone);
      }

      await this.loadAncestryList();
      // Save profile to pedigree centralize profile redux
      helper_family_tree.saveProfileToRedux(this.props.dispatch, {
        ...patient,
      });
      if (
        this.props.getPedigreeData !== undefined &&
        this.props.getPedigreeData !== null
      ) {
        await this.props.getPedigreeData().setProfile(patient.rkey, patient);
      }

      let org_clinicians = [];

      for (let clinician of this.props.org_clinicians) {
        let clinician_object = {
          value: String(clinician.user_id),
          label: `${clinician.first_name} ${clinician.last_name}`,
        };
        org_clinicians.push(clinician_object);
      }

      if (patient.patient_id !== "" && patient.patient_id !== null) {
        this.patient_id = patient.patient_id;
      }

      this.setState(
        {
          ...patient,
          org_clinicians,
          initial_patient_clinicians: patient.clinicians,
        },
        () => console.log(this.state)
      );

      document.addEventListener("click", this.handleClickOutside, true);
    } catch (e) {
      console.log(e.stack);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  async handleClickOutside(event) {
    // Targets are ID's of all links to home from navigation
    let link_targets = ["home_icon", "home_link", "home_link2", "logo"];
    let search_targets = ["search_button", "search_icon"];
    let target_hit =
      link_targets.includes(event.target.id) ||
      search_targets.includes(event.target.id);

    // Suspend event if in targets - that way we can save first
    if (!this.props.isNewPatient && target_hit) event.preventDefault();
    const domNode = ReactDOM.findDOMNode(this);

    if (
      (!domNode || !domNode.contains(event.target)) &&
      !this.props.isNewPatient
    ) {
      try {
        let payload = this.buildPayload();
        let proband =
          this.props.getPedigreeData !== null &&
          this.props.getPedigreeData !== undefined
            ? cloneDeep(this.props.getPedigreeData().proband)
            : cloneDeep(this.props.patient);

        // Redux Save
        let { payload_father, payload_mother } = this.saveParentAdopted();

        // API Saves

        // This could be a good reference in the future for when we're calling multiple API calls in the same function, what this does is it executes the api calls at the same time
        // and doesn't wait for the previous api call to finish before starting to execute, this will also implement a fail-fast behavior.
        // let startTime = performance.now()

        await Promise.all([
          this.saveProband(proband, payload),
          this.parentAPISave(payload_father, payload_mother),
        ]);

        // await this.saveProband(proband, payload);
        // await this.parentAPISave(payload_father, payload_mother);

        // let endTime = performance.now()

        // console.log(endTime-startTime)

        let initial_patient_clinicians = this.state.initial_patient_clinicians;
        let current_clinicians = proband.clinicians;

        //checking if clinicians array is changed or not, if no changes, don't call the endpoint
        if (!lodash.isEqual(initial_patient_clinicians, current_clinicians)) {
          await this.props.saveClinician(proband);
        }
      } catch (error) {
        helpers.logger(error);
      }
    }

    // If event is a search, fix url. Then redirect to search-results

    let url = route_helper.query.query_root;

    if (!this.props.isNewPatient && target_hit) this.props.history.push(url);
  }

  async loadAncestryList() {
    try {
      let ancestryList = await family_api.get_ancestry_list();
      const ancestry_options = ancestryList.map((item) => ({
        value: item.id,
        label: item.display_name,
      }));

      await this.props.dispatch(
        static_list_actions.initialize_ancestry_list(ancestry_options)
      );
    } catch (error) {
      console.log(error);
    }
  }

  async updateReduxProfileByKey(dataKey, data) {
    await this.setState({ [dataKey]: data });
    let payload = { dataKey, data };
    this.props.dispatch(patient_actions.save_patient_data(payload));

    if (dataKey === "father_adopted") {
      let father = cloneDeep(this.state.father);
      father.adopted_out = data;
      this.setState({ father });
    }
    if (dataKey === "mother_adopted") {
      let mother = cloneDeep(this.state.mother);
      mother.adopted_out = data;
      this.setState({ mother });
    }

    let profile = cloneDeep(this.state);
    delete profile.unique_id;
    // Save profile to pedigree centralize profile redux
    helper_family_tree.saveProfileToRedux(this.props.dispatch, profile);
  }

  async updateDOMPedigreeDataStoreProfileByKey(dataKey, data) {
    await this.setState({ [dataKey]: data });
    let payload = { dataKey, data };
    this.props.dispatch(patient_actions.save_patient_data(payload));
    let deep_clone_proband = cloneDeep(this.props.getPedigreeData().proband);
    deep_clone_proband[dataKey] = data;
    await this.props.getPedigreeData().setProband(deep_clone_proband);

    if (dataKey === "father_adopted") {
      let father = cloneDeep(
        this.props.getPedigreeData().getProfile(deep_clone_proband.father.rkey)
      );
      father.adopted_out = data;
      this.setState({ father });
    }
    if (dataKey === "mother_adopted") {
      let mother = cloneDeep(
        this.props.getPedigreeData().getProfile(deep_clone_proband.mother.rkey)
      );
      mother.adopted_out = data;
      this.setState({ mother });
    }
  }

  async onChange(event) {
    let dataKey = event.target.name;
    let data = "";

    if (event.target.type === "checkbox") {
      data = event.target.checked;
    } else {
      data = event.target.value;
    }

    this.updateReduxProfileByKey(dataKey, data);
    if (
      this.props.getPedigreeData !== undefined &&
      this.props.getPedigreeData !== null
    ) {
      this.updateDOMPedigreeDataStoreProfileByKey(dataKey, data);
    }

    if (dataKey === "patient_id" && this.patient_id !== data && data !== "" && data != null) {
      await this.idValidation(data);
    }
  }

  async idValidation(patient_id) {
    try {
      let response = await family_api.validate_patient_id({
        patient_id: patient_id,
      });
      if (response && response.message) this.setState({ unique_id: false });
      else this.setState({ unique_id: true });
    } catch (error) {
      console.log(error);
    }
  }

  async onPhoneSelect(c, country) {
    await this.setState({ phone_valid: true, phone_number: "" });
    let dataKey = "phone_valid";
    let data = true;
    let payload = { dataKey, data };
    this.props.dispatch(patient_actions.save_patient_data(payload));

    dataKey = "phone_number";
    data = "";
    payload = { dataKey, data };
    this.props.dispatch(patient_actions.save_patient_data(payload));

    let profile = cloneDeep(this.state);
    delete profile.unique_id;
    // Save profile to pedigree centralize profile redux
    helper_family_tree.saveProfileToRedux(this.props.dispatch, profile);

    let proband = this.props.getPedigreeData().getProband();
    let proband_profile = cloneDeep(
      this.props.getPedigreeData().getProfile(proband.rkey)
    );
    proband_profile.phone_valid = true;
    proband_profile.phone_number = "";
    delete proband_profile.unique_id;

    this.props.getPedigreeData().setProfile(proband.rkey, proband_profile);
  }

  async onPhoneChange(phone_valid, phone, c) {
    await this.setState({ phone_valid: phone_valid, phone_number: phone });
    let dataKey = "phone_valid";
    let data = phone_valid;
    let payload = { dataKey, data };
    this.props.dispatch(patient_actions.save_patient_data(payload));

    dataKey = "phone_number";
    data = phone;
    payload = { dataKey, data };
    this.props.dispatch(patient_actions.save_patient_data(payload));
    dataKey = "dial_code";
    data = c.dialCode;
    payload = { dataKey, data };
    this.props.dispatch(patient_actions.save_patient_data(payload));

    let profile = cloneDeep(this.state);
    delete profile.unique_id;
    // Save profile to pedigree centralize profile redux
    helper_family_tree.saveProfileToRedux(this.props.dispatch, profile);

    let proband = this.props.getPedigreeData().getProband();
    let proband_profile = cloneDeep(
      this.props.getPedigreeData().getProfile(proband.rkey)
    );
    proband_profile.phone_valid = phone_valid;
    proband_profile.phone_number = phone;
    proband_profile.dial_code = c.dialCode;
    delete proband_profile.unique_id;

    this.props.getPedigreeData().setProfile(proband.rkey, proband_profile);
  }

  // Radio Input Event
  async changeBiologicalGender(event, value) {
    if (this.state.partners && this.state.partners.length > 0) {
      this.setState({ genderError: true });
    } else {
      this.updateReduxProfileByKey("gender", value);
      if (
        this.props.getPedigreeData !== null &&
        this.props.getPedigreeData !== undefined
      ) {
        await this.props.getPedigreeData().setProbandDetail("gender", value);
      }
    }
  }

  // Dropdown Input Event
  async changeGenderIdentity(item) {
    item
      ? this.updateReduxProfileByKey("gender_identity", item.value)
      : this.updateReduxProfileByKey("gender_identity", null);
    if (
      this.props.getPedigreeData !== null &&
      this.props.getPedigreeData !== undefined
    ) {
      item
        ? this.updateDOMPedigreeDataStoreProfileByKey(
            "gender_identity",
            item.value
          )
        : this.updateDOMPedigreeDataStoreProfileByKey("gender_identity", null);
    }
  }

  // Dropdown Input Event
  async changePronoun(item) {
    item
      ? this.updateReduxProfileByKey("pronoun", item.value)
      : this.updateReduxProfileByKey("pronoun", null);
    if (item && item.value !== "other")
      this.updateReduxProfileByKey("other_pronoun", null);
    if (
      this.props.getPedigreeData !== null &&
      this.props.getPedigreeData !== undefined
    ) {
      item
        ? this.updateDOMPedigreeDataStoreProfileByKey("pronoun", item.value)
        : this.updateDOMPedigreeDataStoreProfileByKey("pronoun", null);
      if (item && item.value !== "other")
        this.updateDOMPedigreeDataStoreProfileByKey("other_pronoun", null);
    }
  }

  // Dropdown Input Event
  async changeEthnicity(item) {
    item
      ? this.updateReduxProfileByKey("ethnicity", item.value)
      : this.updateReduxProfileByKey("ethnicity", null);
    if (
      this.props.getPedigreeData !== null &&
      this.props.getPedigreeData !== undefined
    ) {
      item
        ? this.updateDOMPedigreeDataStoreProfileByKey("ethnicity", item.value)
        : this.updateDOMPedigreeDataStoreProfileByKey("ethnicity", null);
    }
  }

  // Dropdown Input Event
  async changeAncestry(side, ancestry_array) {
    let patient =
      this.props.getPedigreeData !== null &&
      this.props.getPedigreeData !== undefined
        ? this.props.getPedigreeData().proband
        : this.props.patient;
    let patient_data = cloneDeep(patient);
    let profile = null;
    let update_key = "";
    if (side === "m") {
      update_key = "mother";
      if (
        this.props.getPedigreeData !== null &&
        this.props.getPedigreeData !== undefined
      ) {
        profile = cloneDeep(
          this.props.getPedigreeData().getProfile(patient_data.mother.rkey)
        );
      } else {
        profile = cloneDeep(patient_data.mother);
      }
    } else {
      update_key = "father";
      if (
        this.props.getPedigreeData !== null &&
        this.props.getPedigreeData !== undefined
      ) {
        profile = cloneDeep(
          this.props.getPedigreeData().getProfile(patient_data.father.rkey)
        );
      } else {
        profile = cloneDeep(patient_data.father);
      }
    }

    profile.ancestry = ancestry_array;
    helper_family_tree.saveProfileToRedux(this.props.dispatch, profile);
    profile
      ? this.updateReduxProfileByKey(update_key, profile)
      : this.updateReduxProfileByKey(update_key, null);
    if (
      this.props.getPedigreeData !== null &&
      this.props.getPedigreeData !== undefined
    ) {
      ancestry_array
        ? this.updateDOMPedigreeDataStoreProfileByKey(update_key, profile)
        : this.updateDOMPedigreeDataStoreProfileByKey(update_key, null);
      this.props.getPedigreeData().setProfile(profile.rkey, profile);
    }
  }

  async changeClinician(clinician_array) {
    let proband =
      this.props.getPedigreeData !== null &&
      this.props.getPedigreeData !== undefined
        ? this.props.getPedigreeData().proband
        : this.props.patient;
    proband.clinicians = cloneDeep(clinician_array);

    if (
      this.props.getPedigreeData !== null &&
      this.props.getPedigreeData !== undefined
    ) {
      this.props.getPedigreeData().setProfile(proband.rkey, proband);
    }

    this.updateReduxProfileByKey("clinicians", clinician_array);
    if (
      this.props.getPedigreeData !== null &&
      this.props.getPedigreeData !== undefined
    ) {
      clinician_array
        ? this.updateDOMPedigreeDataStoreProfileByKey(
            "clinicians",
            clinician_array
          )
        : this.updateDOMPedigreeDataStoreProfileByKey("clinicians", null);
    }
  }

  buildPayload() {
    let input =
      this.props.getPedigreeData !== null &&
      this.props.getPedigreeData !== undefined
        ? this.props.getPedigreeData().proband
        : this.props.patient;
    let patient =
      this.props.getPedigreeData !== null &&
      this.props.getPedigreeData !== undefined
        ? this.props.getPedigreeData().proband
        : this.props.patient;
    let age = input.age;
    let dob = input.dob;
    let yob = input.yob;
    if (dob !== "" && dob !== null) {
      if (!patient.is_dead) age = moment().diff(input.dob, "years");
      yob = moment(dob).year();
    }

    let payload = {
      clinician_id: this.props.clinician_id,
      first_name: input.first_name,
      middle_name: input.middle_name,
      last_name: input.last_name,
      gender: input.gender.toLowerCase(),
      age_string: input.age_string,
      age: age,
      dob: dob,
      yob: yob,
      patient_id: input.patient_id,
      gender_identity:
        input.gender_identity === null ? "" : input.gender_identity,
      pronoun: input.pronoun === null ? "" : input.pronoun,
      other_pronoun: input.other_pronoun === null ? "" : input.other_pronoun,
      email: input.email,
      dial_code: input.dial_code,
      phone_number: input.phone_number,
      ethnicity: input.ethnicity === null ? "" : input.ethnicity,
      ashkenazi: input.ashkenazi === true,
      father_adopted: input.father_adopted === true,
      mother_adopted: input.mother_adopted === true,
      adopted_out: input.adopted_out,
      adopted_in: input.adopted_out ? false : input.adopted_in,
    };

    return payload;
  }

  async saveProband(proband, payload) {
    if (proband.id === null) return null;
    // helper_family_tree.savePatientToRedux(this.props.dispatch, proband);

    let member = await family_api.patch_member_memberid(proband.id, payload);
    helper_family_tree.saveProfileToRedux(this.props.dispatch, {
      rkey: proband.rkey,
      ...member,
    });

    return member;
  }

  saveParentAdopted() {
    let patient =
      this.props.getPedigreeData !== null &&
      this.props.getPedigreeData !== undefined
        ? this.props.getPedigreeData().proband
        : this.props.patient;
    let { father, mother } = patient;

    if (father == null) return;
    if (mother == null) return;

    let payload_father = {
      rkey: father.rkey,
      adopted_in: patient.father_adopted ? false : father.adopted_in,
      adopted_out: patient.father_adopted,
    };
    helper_family_tree.saveProfileToRedux(this.props.dispatch, payload_father);
    if (
      this.props.getPedigreeData !== undefined &&
      this.props.getPedigreeData !== null
    ) {
      this.props
        .getPedigreeData()
        .setProfile(payload_father.rkey, payload_father);
    }

    let payload_mother = {
      rkey: mother.rkey,
      adopted_in: patient.mother_adopted ? false : mother.adopted_in,
      adopted_out: patient.mother_adopted,
    };
    helper_family_tree.saveProfileToRedux(this.props.dispatch, payload_mother);
    if (
      this.props.getPedigreeData !== undefined &&
      this.props.getPedigreeData !== null
    ) {
      this.props
        .getPedigreeData()
        .setProfile(payload_mother.rkey, payload_mother);
    }

    return { payload_father: payload_father, payload_mother: payload_mother };
  }

  async parentAPISave(payload_father, payload_mother) {
    try {
      let father = null;
      let mother = null;
      if (
        this.props.getPedigreeData !== null &&
        this.props.getPedigreeData !== undefined
      ) {
        mother = this.props
          .getPedigreeData()
          .getProfile(this.props.getPedigreeData().proband.mother.rkey);
        father = this.props
          .getPedigreeData()
          .getProfile(this.props.getPedigreeData().proband.father.rkey);
      } else {
        father = this.props.patient.father;
        mother = this.props.patient.mother;
      }
      payload_mother["ancestry"] = [];
      payload_mother["member_id"] = mother.id;
      if (mother.ancestry) {
        payload_mother["ancestry"] = mother.ancestry.map((item) => {
          let value = item.value ? item.value : item.ancestry_id;
          return value;
        });
      }

      payload_father["ancestry"] = [];
      payload_father["member_id"] = father.id;
      if (father.ancestry) {
        payload_father["ancestry"] = father.ancestry.map((item) => {
          let value = item.value ? item.value : item.ancestry_id;
          return value;
        });
      }

      await Promise.all([
        family_api.update_member_ancestry(payload_father),
        family_api.update_member_ancestry(payload_mother),
        family_api.update_adopted_info(payload_father),
        family_api.update_adopted_info(payload_mother),
      ]);
    } catch (err) {
      console.log(err);
    }
  }

  getStringValue(key) {
    let val = this.state[key];
    if (val) return val;
    return "";
  }

  async onChangeDate(e) {
    let string_dob = "";
    let is_dead = this.state.is_dead;
    if (e == null) {
      string_dob = null;
    } else {
      let month = e.getMonth() + 1;
      let day = e.getDate();
      string_dob =
        e.getFullYear() +
        "-" +
        month.toString().padStart(2, "0") +
        "-" +
        day.toString().padStart(2, "0");
    }
    let updateAge = yearBirthToAgeString(string_dob);
    if(!is_dead) this.updateReduxProfileByKey("age", updateAge);
    this.updateReduxProfileByKey("dob", string_dob);
    if (
      this.props.getPedigreeData !== undefined &&
      this.props.getPedigreeData !== null
    ) {
      this.updateDOMPedigreeDataStoreProfileByKey("dob", string_dob);
      //if not dead we want to update the age_string to be displayed correctly when going back to sidebar
      if (!is_dead) {
        let updateAge = yearBirthToAgeString(string_dob);
        this.updateDOMPedigreeDataStoreProfileByKey("age_string", updateAge);
      }
    }
  }

  getFormatForDatePicker() {
    var formats = {
      "ar-SA": "dd/MM/y",
      "bg-BG": "dd.MM.y",
      "ca-ES": "dd/MM/y",
      "zh-TW": "y/MM/dd",
      "cs-CZ": "dd.MM.y",
      "da-DK": "dd-MM-y",
      "de-DE": "dd.MM.y",
      "el-GR": "dd/MM/y",
      "en-US": "MM/dd/y",
      "fi-FI": "dd.MM.y",
      "fr-FR": "dd/MM/y",
      "he-IL": "dd/MM/y",
      "hu-HU": "y. MM. dd.",
      "is-IS": "dd.MM.y",
      "it-IT": "dd/MM/y",
      "ja-JP": "y/MM/dd",
      "ko-KR": "y-MM-dd",
      "nl-NL": "dd-MM-y",
      "nb-NO": "dd.MM.y",
      "pl-PL": "y-MM-dd",
      "pt-BR": "dd/MM/y",
      "ro-RO": "dd.MM.y",
      "ru-RU": "dd.MM.y",
      "hr-HR": "dd.MM.y",
      "sk-SK": "dd. MM. y",
      "sq-AL": "y-MM-dd",
      "sv-SE": "y-MM-dd",
      "th-TH": "dd/MM/y",
      "tr-TR": "dd.MM.y",
      "ur-PK": "dd/MM/y",
      "id-ID": "dd/MM/y",
      "uk-UA": "dd.MM.y",
      "be-BY": "dd.MM.y",
      "sl-SI": "dd.MM.y",
      "et-EE": "dd.MM.y",
      "lv-LV": "y.MM.dd.",
      "lt-LT": "y.MM.dd",
      "fa-IR": "MM/dd/y",
      "vi-VN": "dd/MM/y",
      "hy-AM": "dd.MM.y",
      "az-Latn-AZ": "dd.MM.y",
      "eu-ES": "y/MM/dd",
      "mk-MK": "dd.MM.y",
      "af-ZA": "y/MM/dd",
      "ka-GE": "dd.MM.y",
      "fo-FO": "dd-MM-y",
      "hi-IN": "dd-MM-y",
      "ms-MY": "dd/MM/y",
      "kk-KZ": "dd.MM.y",
      "ky-KG": "dd.MM.YY",
      "sw-KE": "MM/dd/y",
      "uz-Latn-UZ": "dd/MM y",
      "tt-RU": "dd.MM.y",
      "pa-IN": "dd-MM-YY",
      "gu-IN": "dd-MM-YY",
      "ta-IN": "dd-MM-y",
      "te-IN": "dd-MM-YY",
      "kn-IN": "dd-MM-YY",
      "mr-IN": "dd-MM-y",
      "sa-IN": "dd-MM-y",
      "mn-MN": "YY.MM.dd",
      "gl-ES": "dd/MM/YY",
      "kok-IN": "dd-MM-y",
      "syr-SY": "dd/MM/y",
      "dv-MV": "dd/MM/YY",
      "ar-IQ": "dd/MM/y",
      "zh-CN": "y/MM/dd",
      "de-CH": "dd.MM.y",
      "en-GB": "dd/MM/y",
      "es-MX": "dd/MM/y",
      "fr-BE": "dd/MM/y",
      "it-CH": "dd.MM.y",
      "nl-BE": "dd/MM/y",
      "nn-NO": "dd.MM.y",
      "pt-PT": "dd-MM-y",
      "sr-Latn-CS": "dd.MM.y",
      "sv-FI": "dd.MM.y",
      "az-Cyrl-AZ": "dd.MM.y",
      "ms-BN": "dd/MM/y",
      "uz-Cyrl-UZ": "dd.MM.y",
      "ar-EG": "dd/MM/y",
      "zh-HK": "dd/MM/y",
      "de-AT": "dd.MM.y",
      "en-AU": "dd/MM/y",
      "es-ES": "dd/MM/y",
      "fr-CA": "y-MM-dd",
      "sr-Cyrl-CS": "dd.MM.y",
      "ar-LY": "dd/MM/y",
      "zh-SG": "dd/MM/y",
      "de-LU": "dd.MM.y",
      "en-CA": "dd/MM/y",
      "es-GT": "dd/MM/y",
      "fr-CH": "dd.MM.y",
      "ar-DZ": "dd-MM-y",
      "zh-MO": "dd/MM/y",
      "de-LI": "dd.MM.y",
      "en-NZ": "dd/MM/y",
      "es-CR": "dd/MM/y",
      "fr-LU": "dd/MM/y",
      "ar-MA": "dd-MM-y",
      "en-IE": "dd/MM/y",
      "es-PA": "MM/dd/y",
      "fr-MC": "dd/MM/y",
      "ar-TN": "dd-MM-y",
      "en-ZA": "y/MM/dd",
      "es-DO": "dd/MM/y",
      "ar-OM": "dd/MM/y",
      "en-JM": "dd/MM/y",
      "es-VE": "dd/MM/y",
      "ar-YE": "dd/MM/y",
      "en-029": "MM/dd/y",
      "es-CO": "dd/MM/y",
      "ar-SY": "dd/MM/y",
      "en-BZ": "dd/MM/y",
      "es-PE": "dd/MM/y",
      "ar-JO": "dd/MM/y",
      "en-TT": "dd/MM/y",
      "es-AR": "dd/MM/y",
      "ar-LB": "dd/MM/y",
      "en-ZW": "MM/dd/y",
      "es-EC": "dd/MM/y",
      "ar-KW": "dd/MM/y",
      "en-PH": "MM/dd/y",
      "es-CL": "dd-MM-y",
      "ar-AE": "dd/MM/y",
      "es-UY": "dd/MM/y",
      "ar-BH": "dd/MM/y",
      "es-PY": "dd/MM/y",
      "ar-QA": "dd/MM/y",
      "es-BO": "dd/MM/y",
      "es-SV": "dd/MM/y",
      "es-HN": "dd/MM/y",
      "es-NI": "dd/MM/y",
      "es-PR": "dd/MM/y",
      "am-ET": "dd/MM/y",
      "tzm-Latn-DZ": "dd-MM-y",
      "iu-Latn-CA": "dd/MM/y",
      "sma-NO": "dd.MM.y",
      "mn-Mong-CN": "y/MM/dd",
      "gd-GB": "dd/MM/y",
      "en-MY": "dd/MM/y",
      "prs-AF": "dd/MM/YY",
      "bn-BD": "dd-MM-YY",
      "wo-SN": "dd/MM/y",
      "rw-RW": "MM/dd/y",
      "qut-GT": "dd/MM/y",
      "sah-RU": "MM.dd.y",
      "gsw-FR": "dd/MM/y",
      "co-FR": "dd/MM/y",
      "oc-FR": "dd/MM/y",
      "mi-NZ": "dd/MM/y",
      "ga-IE": "dd/MM/y",
      "se-SE": "y-MM-dd",
      "br-FR": "dd/MM/y",
      "smn-FI": "dd.MM.y",
      "moh-CA": "MM/dd/y",
      "arn-CL": "dd-MM-y",
      "ii-CN": "y/mM/dd",
      "dsb-DE": "dd. MM. y",
      "ig-NG": "dd/MM/y",
      "kl-GL": "dd-MM-y",
      "lb-LU": "dd/MM/y",
      "ba-RU": "dd.MM.YY",
      "nso-ZA": "y/MM/dd",
      "quz-BO": "dd/MM/y",
      "yo-NG": "dd/MM/y",
      "ha-Latn-NG": "dd/MM/y",
      "fil-PH": "MM/dd/y",
      "ps-AF": "dd/MM/YY",
      "fy-NL": "dd-MM-y",
      "ne-NP": "MM/dd/y",
      "se-NO": "dd.MM.y",
      "iu-Cans-CA": "dd/MM/y",
      "sr-Latn-RS": "dd.MM.y",
      "si-LK": "y-MM-dd",
      "sr-Cyrl-RS": "dd.MM.y",
      "lo-LA": "dd/MM/y",
      "km-KH": "y-MM-dd",
      "cy-GB": "dd/MM/y",
      "bo-CN": "y/MM/dd",
      "sms-FI": "dd.MM.y",
      "as-IN": "dd-MM-y",
      "ml-IN": "dd-MM-YY",
      "en-IN": "dd-MM-y",
      "or-IN": "dd-MM-YY",
      "bn-IN": "dd-MM-YY",
      "tk-TM": "dd.MM.YY",
      "bs-Latn-BA": "dd.MM.y",
      "mt-MT": "dd/MM/y",
      "sr-Cyrl-ME": "dd.MM.y",
      "se-FI": "dd.MM.y",
      "zu-ZA": "y/MM/dd",
      "xh-ZA": "y/MM/dd",
      "tn-ZA": "y/MM/dd",
      "hsb-DE": "dd. MM. y",
      "bs-Cyrl-BA": "dd.MM.y",
      "tg-Cyrl-TJ": "dd.MM.YY",
      "sr-Latn-BA": "dd.MM.y",
      "smj-NO": "dd.MM.y",
      "rm-CH": "dd/MM/y",
      "smj-SE": "y-MM-dd",
      "quz-EC": "dd/MM/y",
      "quz-PE": "dd/MM/y",
      "hr-BA": "dd.MM.y.",
      "sr-Latn-ME": "dd.MM.y",
      "sma-SE": "y-MM-dd",
      "en-SG": "dd/MM/y",
      "ug-CN": "y-MM-dd",
      "sr-Cyrl-BA": "dd.MM.y",
      "es-US": "MM/dd/y",
    };
    return formats[navigator.language] || "dd/MM/y";
  }

  render() {
    let gender_identity = null;
    let gender_identities = settings.app_constants.patientData.genderIdentity;

    for (var key in gender_identities) {
      if (
        this.state.gender_identity &&
        this.state.gender_identity.toLocaleLowerCase() ===
          gender_identities[key].value.toLowerCase()
      ) {
        gender_identity = gender_identities[key];
      }
    }

    let pronoun = null;
    let pronouns = settings.app_constants.patientData.pronouns;
    for (var key in pronouns) {
      if (
        this.state.pronoun &&
        this.state.pronoun.toLowerCase() === pronouns[key].label.toLowerCase()
      ) {
        pronoun = pronouns[key];
      }
    }

    let ethnicity = null;
    let ethnicities = settings.ethnicity_options;
    for (var item of ethnicities) {
      if (
        this.state.ethnicity &&
        this.state.ethnicity.toLowerCase() === item.label.toLowerCase()
      ) {
        ethnicity = item;
      }
    }

    let ancestry_options = this.props.static_list.ancestry_list;

    let phone_number = this.state.phone_number ? this.state.phone_number : "";

    let patient =
      this.props.getPedigreeData !== null &&
      this.props.getPedigreeData !== undefined
        ? this.props.getPedigreeData().proband
        : this.props.patient;
    let profiles =
      this.props.getPedigreeData !== null &&
      this.props.getPedigreeData !== undefined
        ? this.props.getPedigreeData().profiles
        : this.props.profiles;

    const father = profiles[patient.father.rkey];
    const mother = profiles[patient.mother.rkey];
    let father_ancestry = father ? father.ancestry : [];
    let mother_ancestry = mother ? mother.ancestry : [];

    let father_adopted = patient.father_adopted;
    let mother_adopted = patient.mother_adopted;
    let country_dial_code = " ";
    if (
      patient.phone_number &&
      patient.dial_code &&
      country_dial_code !== patient.dial_code &&
      this.props.isNewPatient === false
    ) {
      let number = patient.phone_number.replace(/[()]/g, "");
      let area_code = number.substring(0, 3);
      for (let code of countryCodes.allCountries) {
        if (patient.dial_code === code[2]) {
          country_dial_code =
            patient.dial_code === "1" &&
            countryCodes.canadaAreaCodes.includes(area_code)
              ? "ca"
              : code[1];
          break;
        }
      }
    } else if (this.props.dial_code) {
      country_dial_code = this.props.dial_code;
    }

    let date_format = this.getFormatForDatePicker();

    const isIE = window.navigator.userAgent.indexOf("Trident") != -1;
    let dob_field = null;
    // if (isIE) {
    let dob = "";
    if (this.getStringValue("dob")) {
      try {
        dob = new Date(this.getStringValue("dob") + "T12:00:00");
      } catch (error) {
        dob = "";
      }
    }

    let minDate = new Date("1800-01-01" + "T12:00:00");

    dob_field = (
      <DatePicker
        name="dob"
        onChange={this.onChangeDate}
        value={dob}
        className="form-control"
        calendarIcon={null}
        clearIcon={null}
        dayPlaceholder={"dd"}
        monthPlaceholder={"mm"}
        yearPlaceholder={"yyyy"}
        disableCalendar={true}
        // showLeadingZeros={true}
        minDate={minDate} //new Date("October 13, 1800 11:13:00")}
      />
    );
    // } else {
    //   dob_field = (
    //     <input
    //       name="dob"
    //       onChange={this.onChange} value={this.getStringValue('dob')}
    //       style={{ lineHeight: 1.42857143 }} type="date" className="form-control" />
    //   );
    // }

    return (
      <React.Fragment>
        <ActivityIndicator loading={this.state.loading} modal={true} />

        <div className="form-horizontal">
          <div className="row">
            <div className="col-md-6">
              <div className="padding-left-right">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-4 col-sm-3 col-xs-12">
                      <label htmlFor="patient_id" className="control-label">
                        Patient ID *
                      </label>
                    </div>
                    <div className="col-md-8 col-sm-9 col-xs-12">
                      <input
                        name="patient_id"
                        id="patient_id"
                        onChange={this.onChange}
                        value={this.getStringValue("patient_id")}
                        type="text"
                        className="form-control normal-input-text"
                        autoComplete="off"
                      />
                      {!this.state.unique_id && (
                        <div className="validation-inline__error-text validation-warning">
                          Warning: This Patient ID is already in use.
                        </div>
                      )}
                      {this.getStringValue("patient_id").length > 40 && (
                        <div className="validation-inline__error-text validation-warning">
                          Warning: Too many characters.
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-md-4 col-sm-3 col-xs-12">
                      <label htmlFor="first_name" className="control-label">
                        First Name *
                      </label>
                    </div>
                    <div className="col-md-8 col-sm-9 col-xs-12">
                      <input
                        name="first_name"
                        id="first_name"
                        onChange={this.onChange}
                        value={this.getStringValue("first_name")}
                        type="text"
                        className="form-control normal-input-text"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-md-4 col-sm-3 col-xs-12">
                      <label htmlFor="middle_name" className="control-label">
                        Middle Initial
                      </label>
                    </div>
                    <div className="col-md-8 col-sm-9 col-xs-12">
                      <input
                        name="middle_name"
                        id="middle_name"
                        onChange={this.onChange}
                        value={this.getStringValue("middle_name")}
                        type="text"
                        className="form-control normal-input-text"
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-md-4 col-sm-3 col-xs-12">
                      <label htmlFor="last_name" className="control-label">
                        Last Name
                      </label>
                    </div>
                    <div className="col-md-8 col-sm-9 col-xs-12">
                      <input
                        name="last_name"
                        id="last_name"
                        onChange={this.onChange}
                        value={this.getStringValue("last_name")}
                        type="text"
                        className="form-control normal-input-text"
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-md-4 col-sm-3 col-xs-12">
                      <label className="control-label">
                        Sex Assigned at Birth *
                      </label>
                    </div>
                    <div className="col-md-8 col-sm-9 col-xs-12 biological-gender__wrapper">
                      <RadioGroup
                        name="gender"
                        radioWrapClassName="form-check"
                        radioWrapTag="div"
                        value={String(this.state.gender).toLowerCase()}
                        onChange={(e, value) => {
                          this.changeBiologicalGender(e, value);
                        }}
                      >
                        <Radio
                          value={settings.app_constants.patientData.genders.male.value.toLowerCase()}
                          radioClassName="iradio_flat-green"
                          increaseArea="33%"
                          label="&nbsp;Male"
                        />

                        <Radio
                          value={settings.app_constants.patientData.genders.female.value.toLowerCase()}
                          radioClassName="iradio_flat-green"
                          increaseArea="33%"
                          label="&nbsp;Female"
                        />

                        <Radio
                          value={settings.app_constants.patientData.genders.unknown.value.toLowerCase()}
                          radioClassName="iradio_flat-green"
                          increaseArea="33%"
                          label="&nbsp;Unknown"
                        />
                      </RadioGroup>
                      {this.state.genderError && (
                        <div className="validation-inline__error-text validation-warning">
                          You cannot change the sex assigned at birth of
                          pedigree members who have partners.
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-md-4 col-sm-3 col-xs-12">
                      <label
                        htmlFor="gender_identity"
                        className="control-label"
                      >
                        Gender Identity
                      </label>
                    </div>
                    <div className="col-md-8 col-sm-9 col-xs-12">
                      <Select
                        value={gender_identity}
                        name="gender_identity"
                        onChange={(item) => {
                          this.changeGenderIdentity(item);
                        }}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        isClearable={true}
                        placeholder=""
                        options={[
                          settings.app_constants.patientData.genderIdentity
                            .male,
                          settings.app_constants.patientData.genderIdentity
                            .female,
                          settings.app_constants.patientData.genderIdentity
                            .nonBinary,
                        ]}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-md-4 col-sm-3 col-xs-12">
                      <label htmlFor="pronoun" className="control-label">
                        Pronoun
                      </label>
                    </div>
                    <div className="col-md-8 col-sm-9 col-xs-12">
                      <Select
                        value={pronoun}
                        name="pronoun"
                        onChange={(item) => {
                          this.changePronoun(item);
                        }}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        isClearable={true}
                        placeholder=""
                        options={[
                          settings.app_constants.patientData.pronouns.h,
                          settings.app_constants.patientData.pronouns.s,
                          settings.app_constants.patientData.pronouns.t,
                          settings.app_constants.patientData.pronouns.o,
                        ]}
                      />
                    </div>
                  </div>
                </div>

                {patient.pronoun === "other" && (
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-4 col-sm-3 col-xs-12">
                        <label
                          htmlFor="other_pronoun"
                          className="control-label"
                        >
                          Other Pronoun
                        </label>
                      </div>
                      <div className="col-md-8 col-sm-9 col-xs-12">
                        <input
                          name="other_pronoun"
                          id="other_pronoun"
                          onChange={this.onChange}
                          value={this.getStringValue("other_pronoun")}
                          type="text"
                          className="form-control normal-input-text"
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="form-group selectric-multiple">
                  <div className="row">
                    <div className="col-md-4 col-sm-3 col-xs-12">
                      <label
                        htmlFor="paternal_ancestry"
                        className="control-label"
                      >
                        Clinician
                      </label>
                    </div>
                    <div className="col-md-8 col-sm-9 col-xs-12">
                      <Select
                        value={patient.clinicians}
                        name="clinician"
                        onChange={(clinician_array) =>
                          this.changeClinician(clinician_array)
                        }
                        className="react-select-container"
                        classNamePrefix="react-select"
                        isClearable={true}
                        isMulti
                        placeholder=""
                        options={this.state.org_clinicians}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-md-8 col-sm-8 col-xs-18">
                      <label className="control-label">
                        * Indicates Required Field
                      </label>
                    </div>
                  </div>
                </div>

                {this.state.pronoun && this.state.pronoun.value === "O" && (
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-offset-4 col-md-2 col-sm-3 col-xs-12">
                        <label htmlFor="other" className="control-label">
                          Other
                        </label>
                      </div>
                      <div className="col-md-8 col-sm-9 col-xs-12">
                        <input
                          name="other"
                          id="other"
                          onChange={this.onChange}
                          value={this.getStringValue("other")}
                          type="text"
                          className="form-control normal-input-text"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/*END PADDING CONTAINER*/}
            </div>
            {/*END COLUMN*/}

            <div className="col-md-6">
              <div className="padding-left-right">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-4 col-sm-3 col-xs-12">
                      <label htmlFor="dob" className="control-label">
                        Date of Birth
                      </label>
                    </div>
                    <div className="col-md-8 col-sm-9 col-xs-12">
                      {dob_field}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-md-4 col-sm-3 col-xs-12">
                      <label htmlFor="email" className="control-label">
                        Email
                      </label>
                    </div>
                    <div className="col-md-8 col-sm-9 col-xs-12">
                      <input
                        name="email"
                        id="email"
                        onChange={this.onChange}
                        value={this.getStringValue("email")}
                        type="text"
                        className="form-control normal-input-text"
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-md-4 col-sm-3 col-xs-12">
                      <label htmlFor="phone" className="control-label">
                        Phone
                      </label>
                    </div>
                    <div className="col-md-8 col-sm-9 col-xs-12">
                      <IntlTelInput
                        onlyCountries={countryCodes.iso2}
                        name="phone_number"
                        id="phone_number"
                        value={phone_number}
                        format={true}
                        inputClassName="phone-input"
                        defaultCountry={country_dial_code}
                        onSelectFlag={(phone, c) => {
                          this.onPhoneSelect(phone, c);
                        }}
                        onPhoneNumberChange={(phone_valid, phone, c) => {
                          this.onPhoneChange(phone_valid, phone, c);
                        }}
                      />
                      <ValidationErrorField
                        validations={this.state.validations}
                        field="phone"
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-md-4 col-sm-3 col-xs-12">
                      <label htmlFor="ethnicity" className="control-label">
                        Race/Ethnicity
                      </label>
                    </div>
                    <div className="col-md-8 col-sm-9 col-xs-12">
                      <Select
                        value={ethnicity}
                        name="ethnicity"
                        onChange={(item) => {
                          this.changeEthnicity(item);
                        }}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        isClearable={true}
                        placeholder=""
                        options={settings.ethnicity_options}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-md-4 col-sm-3 col-xs-12">
                      <label
                        htmlFor="maternal_ancestry"
                        className="control-label"
                      >
                        Maternal Ancestry
                      </label>
                    </div>
                    <div className="col-md-8 col-sm-9 col-xs-12 ">
                      <Select
                        value={mother_ancestry}
                        name="maternal_ancestry"
                        onChange={(ancestry_array) =>
                          this.changeAncestry("m", ancestry_array)
                        }
                        className="react-select-container"
                        classNamePrefix="react-select"
                        isClearable={true}
                        isMulti
                        placeholder=""
                        options={ancestry_options}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group selectric-multiple">
                  <div className="row">
                    <div className="col-md-4 col-sm-3 col-xs-12">
                      <label
                        htmlFor="paternal_ancestry"
                        className="control-label"
                      >
                        Paternal Ancestry
                      </label>
                    </div>
                    <div className="col-md-8 col-sm-9 col-xs-12">
                      <Select
                        value={father_ancestry}
                        name="paternal_ancestry"
                        onChange={(ancestry_array) =>
                          this.changeAncestry("p", ancestry_array)
                        }
                        className="react-select-container"
                        classNamePrefix="react-select"
                        isClearable={true}
                        isMulti
                        placeholder=""
                        options={ancestry_options}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-3 col-xs-12">
                      <label htmlFor="jewish" className="control-label">
                        Ashkenazi Jewish?
                      </label>
                    </div>
                    <div className="col-md-8 col-sm-9 col-xs-12">
                      <div className="checkbox">
                        <label className="switch">
                          <input
                            name="ashkenazi"
                            id="ashkenazi"
                            onChange={this.onChange}
                            checked={this.state.ashkenazi}
                            type="checkbox"
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-3 col-xs-12">
                      <label htmlFor="adopted_out" className="control-label">
                        Patient Adopted?
                      </label>
                    </div>
                    <div className="col-md-8 col-sm-9 col-xs-12">
                      <div className="checkbox">
                        <label className="switch">
                          <input
                            name="adopted_out"
                            id="adopted_out"
                            onChange={this.onChange}
                            checked={this.state.adopted_out}
                            type="checkbox"
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-3 col-xs-12">
                      <label htmlFor="father_adopted" className="control-label">
                        Father Adopted?
                      </label>
                    </div>
                    <div className="col-md-8 col-sm-9 col-xs-12">
                      <div className="checkbox">
                        <label className="switch">
                          <input
                            name="father_adopted"
                            id="father_adopted"
                            onChange={this.onChange}
                            checked={father_adopted}
                            type="checkbox"
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-3 col-xs-12">
                      <label htmlFor="mother_adopted" className="control-label">
                        Mother Adopted?
                      </label>
                    </div>
                    <div className="col-md-8 col-sm-9 col-xs-12">
                      <div className="checkbox">
                        <label className="switch">
                          <input
                            name="mother_adopted"
                            id="mother_adopted"
                            onChange={this.onChange}
                            checked={mother_adopted}
                            type="checkbox"
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*END PADDING CONTAINER*/}
            </div>
            {/*END COLUMN*/}
          </div>
          {/*END ROW*/}
        </div>
      </React.Fragment>
    );
  }
}

const redux_state = (state) => ({
  profiles: state.patient.profile,
  patient: state.patient.patient,
  clinician_id: state.session.user.clinician_id,
  dial_code: state.session.user.dial_code,
  org_clinicians: state.session.user.org_clinicians,
  static_list: state.static_list,
});

const redux_actions = (dispatch) => ({
  dispatch: (action) => dispatch(action),
});

export default withRouter(
  connect(redux_state, redux_actions)(PatientPersonalData)
);
