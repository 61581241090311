const setSiteTitle = (title) => {
  document.title = process.env.REACT_APP_SITE_TITLE + ' | ' + title
}

export const delay = (length_in_seconds) => {

  return new Promise(function (resolve, reject) {
    setTimeout(function () { resolve() }, length_in_seconds * 1000)
  })

}

/*
| - - - - - - - - - - - - - - - - - - -
| Validator Helper
|
|   Payload:
|   {
|      artistName: {
|        labelName: 'Artist Name',
|        validations: { required: true }
|      }
|    }
|
|   Response:
|   {
|     isValid: false,
|     summary: [
|        'Artist Name is required',
|      ]
|      fields: {
|        artistName: 'Artist Name is required',
|        fieldName_2: 'Label 2 is required'
|      }
|    }
| - - - - - - - - - - - - - - - - - - -
*/

const validateInputs = (state, options) => {

  var summary = []
  var fields = {}

  if ("first_name" in options && "patient_id" in options) {
    let name = state["first_name"];
    let id = state["patient_id"];

    if ((name === "" || name === null) && (id === "" || id === null)) summary.push("Either First Name or Patient ID is required")

    delete options.first_name;
    delete options.patient_id;
  }

  for (var key in options) {
    let field = options[key]
    let value = state[key]

    if (value && (typeof value === 'string' || value instanceof String)) {
      value = value.trim()
    }

    if ('required' in field.validations && (value === "" || value === null)) {
      summary.push(field.labelName + ' is required ')
      fields[key] = '* required '

    }

    // Only Check custom validation if preceeding validations succeeded
    if (!(key in fields) && 'custom' in field.validations) {

      for (var c of field.validations.custom) {
        if (!c.test()) {
          summary.push(c.error_summary)
          fields[key] = c.error_text
        }
      }

    }

  }

  return {
    isValid: (summary.length === 0),
    summary,
    fields
  }
}

const showPopupError = (message = 'An Error has occured') => {

  let popup = document.querySelector('#error-message-popup')
  if (popup) popup.classList.add('error-message-popup--show')

  let message_text = document.querySelector('#error-message-popup__text')
  if (message_text) message_text.innerHTML = message

}

const hidePopupError = () => {
  let popup = document.querySelector('#error-message-popup')
  if (popup) popup.classList.remove('error-message-popup--show')

}

const showPopupSessionError = () => {
  let current_uri = window.location.pathname;
  if (current_uri !== "/") {
    let popup = document.querySelector('#error-session-popup')
    if (popup) popup.classList.add('error-message-popup--show')
  }
}

/* - - - - - - - - - - - - - - - - - - - */

const logger = (error) => {
  console.log(error)
}

const getSelectedOption = (options, selected_value) => {
  let selected_option = options.find(item => selected_value && selected_value == item.value)
  return (typeof (selected_option) === 'undefined') ? null : selected_option;
}

const canvasToImage = (canvas_id, backgroundColor, type, payload) => {

  var canvas = document.getElementById(canvas_id);
  var context = canvas.getContext("2d");

  //cache height and width
  var w = canvas.width;
  var h = canvas.height;

  var data;

  if (backgroundColor) {
    //get the current ImageData for the canvas.
    data = context.getImageData(0, 0, w, h);

    //store the current globalCompositeOperation
    var compositeOperation = context.globalCompositeOperation;

    //set to draw behind current content
    context.globalCompositeOperation = "destination-over";

    //set background color
    context.fillStyle = backgroundColor;

    //draw background / rect on entire canvas
    context.fillRect(0, 0, w, h);
  }

  //get the image data from the canvas
  var imageData = canvas.toDataURL("image/jpeg", 10);

  if (backgroundColor) {
    //clear the canvas
    context.clearRect(0, 0, w, h);

    //restore it with original / cached ImageData
    context.putImageData(data, 0, 0);

    //reset the globalCompositeOperation to what it was
    context.globalCompositeOperation = compositeOperation;
  }

  //return the Base64 encoded data url string
  return imageData;
}

const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

export default {
  logger,
  setSiteTitle,
  validateInputs,
  delay,
  showPopupError,
  hidePopupError,
  showPopupSessionError,
  getSelectedOption,
  canvasToImage,
  b64toBlob
}
