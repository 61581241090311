import disease_api from "../api/disease-api";


const get_skip_logic_map = async (disease_id) => {
    let keys = {}
    //Returns the skip logics in the database based on disease id
    //creates key based on the short name with value equaling the skip logic code
    try{
        let disease = await loadDiseasesWithSkipLogic()
        let diseaseToMap = disease.diseases.find(disease=>{
            return (disease.id == disease_id || disease.umls_id === disease_id)
        })
        
        diseaseToMap.skip_logistics.forEach(item=>{
             keys[item.short_name] = item.skip_logic_code
         })
    return keys

    }catch(err){
        return err
    }
}

const loadDiseasesWithSkipLogic = async()=>{
    try{
        let data = await disease_api.get_disease()
        return data
    }catch(err){
        return err
    }
}


 export default{ 
     get_skip_logic_map
 }

  