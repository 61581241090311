import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';

class RiskLineChart extends Component {

  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          height: 400,
          type: 'line',
          id: this.props.chartId,
          toolbar: {
            show: true
          }
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          markers: {
            fillColors: []
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        grid: {
          padding: {
            top: 20,
            right: 20,
            left: 20,
            bottom: 20
          }
        },
        // title: {
        //   text: this.props.title,
        //   align: 'left'
        // },
        labels: this.props.labels,
        xaxis: {
          title: {
            text: 'Age',
            style: {fontSize: '14px'}
          }
        },
        yaxis: {
          title: {
            text: 'Cancer Risk %',
            style: {fontSize: '14px'}
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '20%'
          }
        }
      },
      tooltip: {
        marker: {
          fillColors: []
        }
      },
      series: this.props.payload,
    };
  }

  hideToolbarForPrint() {
    this.setState({ options: {chart: {toolbar: {show: false}}} });
  }

  hideLegendForPrint() {
    this.setState({ options: {legend: {show: false}} });
  }

  render() {
    return (
      <ReactApexChart options={this.state.options} series={this.state.series} type={this.props.chartType} height="400" />
    );
  }
}

export default RiskLineChart;
