import { combineReducers } from 'redux';
import types from "./types";
import mock from './mock';

function authenticated(state=false, action) {
  switch(action.type) {
    case types.AUTHENTICATED:
      return action.payload.authenticated;
    default:
      return state;
  }
}

function user(state=mock.user, action) {
  switch(action.type) {
    case types.USER_SAVE:
      return action.payload;
    default:
      return state;
  }
}

export default combineReducers({
  authenticated,
  user
});
