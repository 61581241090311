import React, { Component } from 'react';
import { max } from 'moment';

class TableFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  pageGauge(max_pages) {
    let listItems = [];
    let start = this.props.page;

    // only show 5 pages at a time
    let end = this.props.page + 4;

    if (end > max_pages) {
      end = max_pages;
    }

    if ((end - start) < 5) {
       start = end - 4;
    }

    if (start < 1) {
      start = 1;
    }

    for(let i=start; i <= end; i++) {
      let css = (this.props.page === i) ? "active" : "";
      listItems.push(<li key={i} className={css}><a href='#' onClick={() => {this.props.page_change(i)}}>{i}</a></li>);
    }

    return listItems;
  }

  render() {

    const total_entries = this.props.entries;
    const max_pages = Math.ceil(total_entries / this.props.entries_per_page);
    const current_entries = this.props.entries_per_page * this.props.page;

    const entries_shown_start = this.props.entries_per_page * (this.props.page - 1) + 1;
    const entries_shown_end = (total_entries > current_entries) ? current_entries : total_entries;

    const prev_enabled = (this.props.page === 1) ? false : true;
    const next_enabled = (this.props.page === max_pages) ? false : true;

    return (
      <div className="table-footer">
        <div className="row">
          <div className="col-md-6">Showing {entries_shown_start} to {entries_shown_end} of {total_entries} entries</div>
          <div className="col-md-6">
            <div className="clearfix">
              <div className="pull-right">
                <ul className="pagination pagination-sm">
                  <li>
                    {prev_enabled ?
                    <a href="#" aria-label="Previous" onClick={() => {this.props.page_change(this.props.page-1)}}>
                      <span aria-hidden="true">«</span>
                     </a>
                     :
                     <a href="#" aria-label="Previous">
                      <span aria-hidden="true">«</span>
                     </a>
                    }
                  </li>
                  {this.pageGauge(max_pages)}
                  <li>
                    {next_enabled ?
                    <a  href="#" aria-label="Next" onClick={() => {this.props.page_change(this.props.page+1)}}>
                      <span aria-hidden="true">»</span>
                    </a>
                    :
                    <a  href="#" aria-label="Next">
                      <span aria-hidden="true">»</span>
                    </a>
                    }
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TableFooter;
