import sdk from './sdk';

const post_check_risk = async (proband_id) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/risk/check_risk/';
    let response = await sdk.request('post', url, { proband_id });

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const create_risk_setting = async (risk_setting) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/risk_setting/';
    let response = await sdk.request('post', url, risk_setting);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const save_risk_setting = async (risk_setting) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/risk_setting/' + risk_setting.id + '/';
    let response = await sdk.request('patch', url, risk_setting);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

const reset_hash_input_data = async (payload) => {
  try {

    let url = process.env.REACT_APP_ROOT_API_URL + '/risk/reset_hash_input_data/'
    let response = await sdk.request('post', url, payload);

    let data = response.data;
    return data;

  } catch (err) {
    throw sdk.parse_error_message(err);
  }
}

export default {
  parse_error_message: sdk.parse_error_message,
  post_check_risk,
  create_risk_setting,
  save_risk_setting,
  reset_hash_input_data
}
