import React, { Component } from 'react';

import settings from '../configs/settings';
import Select from 'react-select';

import family_api from '../api/family-api'
import ErrorSummary from '../components/error-summary';
import * as helper_family_tree from '../helpers/helper-family-tree';
import ActivityIndicator from '../components/activity-indicator'

class ModalAdopted extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errorMessages: [],
      profile: null,
      adopted_status: null,
    };
  }

  componentDidMount() {
    let profile = this.props.adopted //profile is passed as adopted props
    let adopted_status = null
    if(profile.adopted_in) {
      adopted_status = settings.adopted_status_options[0].value
    } else if (profile.adopted_out) {
      adopted_status = settings.adopted_status_options[1].value
    }
    let first_name = profile.first_name
    this.setState({adopted_status, profile, first_name});
  }

  onChange(field, value) {
    this.setState({[field]: value});
  }

  async onClickSave() {
    try {
      this.setState({errorMessages: [], loading: true})

      // Build payload and save to API
      let first_name = this.state.first_name
      let adopted_in = false
      let adopted_out = false
      let adopted_status = this.state.adopted_status
      if( adopted_status != null) {
        adopted_in = adopted_status == settings.adopted_status_options[0].value
        adopted_out = adopted_status == settings.adopted_status_options[1].value
      }
      await family_api.patch_member_memberid(this.state.profile.id, {
        first_name, adopted_in, adopted_out
      })

      let profile = Object.assign({}, this.state.profile, {adopted_in, adopted_out, first_name})
      this.props.onSave(profile);
      this.props.onClose();

    } catch (error) {
      this.setState({errorMessages: [error.message]})
    } finally {
      this.setState({loading: false})
    }
  }

  render() {

    let adopted_status = settings.adopted_status_options.find(item => this.state.adopted_status && this.state.adopted_status == item.value)
    adopted_status = (typeof(adopted_status) === 'undefined') ? null : adopted_status;

    return (
      <div role="dialog"
        onClick={() => this.props.onClose()}
        style={{ display: ((this.props.isOpen) ? 'block' : 'none') }}
        className={"modal fade " + ((this.props.isOpen) ? 'in' : '')}>


        <div className="modal-dialog" role="document" onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <button
                onClick={() => this.props.onClose()}
                type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="fa fa-close"></i>
              </button>
              <h4 className="modal-title text-white text-capitalize">Adopted</h4>
            </div>
            <div className="modal-body">

              <ErrorSummary errorMessages={this.state.errorMessages} />

              {this.state.profile &&
                <React.Fragment>

                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <div className="form-group">
                        <label className="font-weight-600 text--capitalize">{this.props.label}</label>
                        <input
                          value={this.state.first_name}
                          onChange={(e) => this.onChange('first_name', e.target.value)}
                          type="text" className="form-control   " placeholder="Name" />
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <label>Status</label>
                      <div className="custom-select">
                        <Select
                          value={adopted_status}
                          onChange={(item) => {
                            let value = (item) ? item.value : null
                            this.onChange('adopted_status', value)
                          }}
                          className='react-select-container'
                          classNamePrefix="react-select"
                          isClearable={true}
                          placeholder=""
                          options={settings.adopted_status_options}
                        />
                      </div>
                    </div>
                  </div>

                </React.Fragment>}

            </div>
            <div className="modal-footer">
              <button
                onClick={() => this.props.onClose()}
                className="btn btn-light-outline no-margin-right">Cancel</button>
              <button
                onClick={() => this.onClickSave()}
                className="btn btn-dark">Save</button>

              <ActivityIndicator
                modal={true}
                loading={this.state.loading}
              />

            </div>
          </div>
        </div>
      </div>

    )
  }

}

export default ModalAdopted;
