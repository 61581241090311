import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { RadioGroup, Radio } from 'react-ui-icheck';
import Select from "react-select";
import account_api from '../../api/user-account-api';

const types = ["trial_org"];

class ModalStaff extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ...this.props.staffMember,
      validUsername: true,
      isSearchable: false,
      loginValue: null,
      loginTypes: {}
    };

    this.onClickSave = this.onClickSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.getLoginTypeOptions = this.getLoginTypeOptions.bind(this);
  }

  async componentDidMount(){
    await this.getLoginTypeOptions();
  }
  
  async getLoginTypeOptions() {

    const org_id = this.props.org?.id;
    const loginTypeOptions = await account_api.get_org_sso(org_id);
    if(this.state.login_type){
      loginTypeOptions.find((option) => {
        if(option.value === this.state.login_type){
          this.setState({loginValue: option})
        }
      })
    }
    this.setState({loginTypes: loginTypeOptions});

  }

  async onClickSave(event) {
    // set an staffKey to identify the staff member
    let staffKey = this.props.staffKey;
    let staffMember = this.state;
    delete staffMember.validUsername;
    let payload = { staffKey, staffMember };

    await this.props.onClickSave(payload);
    this.props.onClose();
  }

  async onChange(event) {
    if (event.target.type === 'checkbox') {
      this.setState({ [event.target.name]: event.target.checked });
    } else {
      this.setState({ [event.target.name]: event.target.value });
      if(event.target.name === 'username') await this.validateUsername(event.target.value);
    }
  }

  onSelect (option) {
    this.setState({loginValue: option, login_type: option.value});
  }

  async validateUsername(username) {
    try {
      await account_api.validate_staff_username({username});
      this.setState({validUsername: true})
    } catch (error) {
      console.log(error);
      this.setState({validUsername: false})
    }
  }

  render() {

    let save_disabled = (!this.state.validUsername && this.props.staffMember.username !== this.state.username) ? " disabled" : "";

    return ReactDOM.createPortal(
      <div onClick={() => this.props.onClose()}
        style={{ display: 'block', paddingLeft: '12px' }}
        className="modal fade in"
        role="dialog"
        aria-labelledby="staff"
        id="staff">

        <div onClick={(e) => e.stopPropagation()} className="modal-dialog" role="document">
          <div className="modal-content">

            <div className="modal-header">
              <button onClick={() => this.props.onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="fa fa-close"></i>
              </button>
              <h4 className="modal-title text-white text-capitalize">{(this.props.addingNewStaff) ? 'Add New Staff' : 'Edit Profile'}</h4>
            </div>

            <div className="modal-body">
              <form className="form-horizontal form-label-left form-custom">
                <div className="row margin-four-bottom">
                  <div className="col-md-5">
                    <h3 className="float-left">{(this.props.addingNewStaff) ? 'Create login details' : 'Edit Staff Details'}</h3>
                  </div>
                </div>
                  <div className="row margin-two-bottom">
                    <div className="col-md-3 col-sm-3 col-xs-12">
                      <label htmlFor="username" className="control-label">Username</label>
                    </div>
                    <div className="col-md-8 col-sm-9 col-xs-12">
                      <input type="text" className="form-control normal-input-text" name="username" id="username" onChange={this.onChange} value={this.state.username} />
                      {!this.state.validUsername && this.props.staffMember.username !== this.state.username && (
                        <div className="validation-inline__error-text validation-warning">
                          <i className='fa fa-times validation-inline__error-text'></i>
                          This username is already taken.
                        </div>
                      )}
                    </div>
                  </div>

                <div className="row margin-two-bottom">
                  <div className="col-md-3 col-sm-3 col-xs-12">
                    <label htmlFor="first_name" className="control-label">First Name</label>
                  </div>
                  <div className="col-md-8 col-sm-9 col-xs-12">
                    <input type="text" className="form-control normal-input-text" name="first_name" id="first_name" onChange={this.onChange} value={this.state.first_name} />
                  </div>
                </div>

                <div className="row margin-two-bottom">
                  <div className="col-md-3 col-sm-3 col-xs-12">
                    <label htmlFor="last_name" className="control-label">Last Name</label>
                  </div>
                  <div className="col-md-8 col-sm-9 col-xs-12">
                    <input type="text" className="form-control normal-input-text" name="last_name" id="last_name" onChange={this.onChange} value={this.state.last_name} />
                  </div>
                </div>

                <div className="row margin-two-bottom">
                  <div className="col-md-3 col-sm-3 col-xs-12">
                    <label htmlFor="email" className="control-label">Email Address</label>
                  </div>
                  <div className="col-md-8 col-sm-9 col-xs-12">
                    <input type="text" className="form-control normal-input-text" name="email" id="email" onChange={this.onChange} value={this.state.email} />
                  </div>
                </div>

                <div className="row margin-four-bottom margin-eight">
                  <div className="col-md-5">
                    <h3>Staff Details</h3>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3 col-sm-3 col-xs-12">
                    <label className="control-label">Access Type</label>
                  </div>
                  <div className="col-md-8 col-sm-9 col-xs-12">
                    <RadioGroup
                      name="admin"
                      radioWrapClassName="form-check"
                      radioWrapTag="div"
                      value={(this.state.admin) ? "admin" : "normal"}
                      onChange={(e, value) => {
                        if(value === "admin") {
                          this.setState({ admin: true });
                        } else {
                          this.setState({ admin: false });
                        }
                      }}
                    >

                      <Radio
                        value="admin"
                        radioClass="iradio_flat-green"
                        increaseArea="30%"
                        label="&nbsp;Admin"
                      />

                      <Radio
                        value="normal"
                        radioClass="iradio_flat-green"
                        increaseArea="30%"
                        label="&nbsp;Normal"
                      />

                    </RadioGroup>
                  </div>
                </div>
                {(this.state.loginTypes  &&  this.state.loginTypes.length > 0)&& (
                  <div className="row margin-two-bottom">
                    <div className="col-md-3 col-sm-3 col-xs-12">
                      <label className="control-label">Login Type</label>
                    </div>
                    <div className="col-md-8 col-sm-9 col-xs-12" >
                      <Select
                      className="react-select-container save-report-dropdown"
                      classNamePrefix="react-select"
                      value={this.state.loginValue}
                      placeholder={'Select login type...'}
                      options={this.state.loginTypes}
                      onChange={this.onSelect}
                      isSearchable={false}
                      />
                    </div>
                  </div>
                )}



                {(types.includes(this.props.org.org_type)) && (
                  <div className="row">
                    <div className="col-md-3 col-sm-3 col-xs-12">
                      <label className="control-label">Trial Expiration</label>
                    </div>
                    <div className="col-md-8 col-sm-9 col-xs-12" >
                    <input name="trial_expiration" id="trial_expiration" onChange={this.onChange}
                    value={this.state.trial_expiration} type="date" className="form-control normal-input-text" />
                    </div>
                  </div>
                )}

                {!this.props.addingNewStaff &&
                  <React.Fragment>

                    <div className="row margin-four-bottom margin-eight">
                      <div className="col-md-5">
                        <h3>Lock/Unlock Account</h3>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3 col-sm-3 col-xs-12">
                        <label className="control-label">Lock</label>
                      </div>
                      <div className="col-md-9">
                        <div className="checkbox">
                          <label className="switch">
                            <input name="is_lock" id="is_lock" type="checkbox" checked={this.state.is_lock} onChange={this.onChange} />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>

                  </React.Fragment>
                }
              </form>
            </div>

            <div className="modal-footer">
              <button
                onClick={() => this.props.onClose()}
                type="button" className="btn btn-light-outline no-margin-right" data-dismiss="modal">Cancel</button>
              <button
                onClick={this.onClickSave}
                type="button" className={"btn btn-dark" + save_disabled}>Save</button>
            </div>
          </div>
        </div>
      </div>,
      document.body
    );
  }

}

export default ModalStaff;
