import React, { Component } from "react";
import { connect } from "react-redux";

class SearchTextBox extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.deleteSearchBar = this.deleteSearchBar.bind(this);
  }

  handleChange(event) {
    this.props.onSearchTextChange(event.target.value);
  }

  handleKeyDown(event) {
    // TODO: handle other inputs we wish the user not to enter
  }

  handleFocus(event) {
    this.props.onSearchBoxFocus();
  }

  onKeyUp(event) {
    if (event.charCode === 13) {
      // ENTER key was pressed
      this.props.onSearchSubmit('old');
    }
  }

  deleteSearchBar(event) {
    this.props.onSearchTextChange("");
    setTimeout(() => {
      this.props.onSearchSubmit('old');
    }, 300);
  }

  render() {
    let classes = `input-home-search ${this.props.classes}`;

    const search_val = this.props.searchFilter.search_text;
    let delete_search = "delete-search";
    if (search_val === "") delete_search = "delete-search delete-search-hidden";

    return (
      <div style={{ display: "flex" }}>
        <input
          type="text"
          value={search_val}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          onFocus={this.handleFocus}
          onKeyPress={this.onKeyUp}
          id="search_text"
          name="search_text"
          className={classes}
          placeholder="Search patient..."
          autoComplete="off"
        />
        <button
          className="  search-button  "
          type="submit"
          onClick={this.props.onSearchSubmit}
        ></button>
        <i
          className="fa fa-search fa-lg"
          style={{
            display: "grid",
            position: "relative",
            alignContent: "center",
            right: "35px",
          }}
        ></i>
        <a className={delete_search} onClick={this.deleteSearchBar}>
          <i className="fa fa-times fa-lg"></i>
        </a>
        {/* <button className="btn btn-light btn-xs" onClick={() => this.props.onSearchSubmit('old')}>Old Query</button>
        <button className="btn btn-light btn-xs" onClick={() => this.props.onSearchSubmit('new')}>New Query</button> */}
      </div>
    );
  }
}

const redux_state = (state) => ({
  searchFilter: state.search.searchFilter,
});

export default connect(redux_state, null)(SearchTextBox);
