import React, { Component } from 'react';

const DELETE_DOM_CONTAINER = "delete-dom-container";

class DeleteDOM extends Component {
  constructor(props) {
    super(props);

    this.state = {
      canDelete: true,
    };

    this.onDelete = this.onDelete.bind(this)
  }

  async onDelete(event) {
    if ('onDeleteCheck' in this.props) {
      const canDeleteMap = await this.props.onDeleteCheck();
      if (canDeleteMap.canDelete && 'onDeleteOpen' in this.props) {
        this.props.onDeleteOpen(canDeleteMap.msg);
      }
    }
  }

  render() {
    const styles = {
      position: 'absolute',
      right: (this.props.hasSubtext) ? '-21px' : '-21px',
      top: '-15px',
      height: '20px',
      width: '20px',
      fontSize: '16px'
    };

    return (
      <div id={DELETE_DOM_CONTAINER} style={styles}>
        <button
          className="btn-link"
          onClick={(e) => this.onDelete(e)}
          onMouseEnter={() => document.body.style.cursor = "pointer"}
          onMouseLeave={() => document.body.style.cursor = "default"}>

          <i className="fa fa-times-circle" />
        </button>
      </div>
    );
  }
}

export default DeleteDOM;
