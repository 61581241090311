import React, { Component } from 'react'

import FamilyFatherMother from './father-mother'

class FamilyTreeProbandFatherMother extends Component {

  render() {
    return <React.Fragment>

      <FamilyFatherMother
        label='Mother'
        for='mother'
        father_mother={this.props.mother}
        patient={this.props.patient}
        dispatch={this.props.dispatch}
        deleteMember={this.props.deleteMember}
      />

      <FamilyFatherMother
        label='Father'
        for='father'
        father_mother={this.props.father}
        patient={this.props.patient}
        dispatch={this.props.dispatch}
        deleteMember={this.props.deleteMember}
      />

    </React.Fragment>

  }
}

export default FamilyTreeProbandFatherMother
