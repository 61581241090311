import React, {Component} from 'react';
import {
  PedigreeStylesheet,
  oneClickAddDropdowns as dropdowns, oneClickAddActionTypes as actions
} from './pedigree-constants';

class AddPartnerDOM extends Component {

  render() {
    const show_dropdown = this.props.showDropdown ? 'open' : '';

    const container_styles = Object.assign({}, PedigreeStylesheet.oneClickDOMContainer, {top: this.props.top, left: this.props.left});
    const partner_btn_style = Object.assign({}, PedigreeStylesheet.oneClickAddBtn, PedigreeStylesheet.oneClickAddBtnLeft);
    const dropdown_btn_style = Object.assign({}, PedigreeStylesheet.oneClickAddBtn, PedigreeStylesheet.oneClickAddBtnRight);
    const svg_symbol_style = PedigreeStylesheet.oneClickAddSvgSymbol;
    const svg_style = PedigreeStylesheet.oneClickAddSvg;

    let svg = (
      <svg style={svg_style}>
        <path
          d={PedigreeStylesheet.oneClickAddSvgSymbolUnknown.d}
          style={svg_symbol_style}
        />
      </svg>
    );

    const gender = this.props.gender;
    if(gender && typeof(gender) === 'string') {
      if(gender.trim().toLowerCase() === 'm' || gender.trim().toLowerCase() === 'male') {
        svg = (
          <svg style={svg_style}>
            <circle
              cx={PedigreeStylesheet.oneClickAddSvgSymbolFemale.cx}
              cy={PedigreeStylesheet.oneClickAddSvgSymbolFemale.cy}
              r={PedigreeStylesheet.oneClickAddSvgSymbolFemale.r}
              style={svg_symbol_style}
            />
          </svg>
        );
      } else if (gender.trim().toLowerCase() === 'f' || gender.trim().toLowerCase() === 'female') {
        svg = (
          <svg style={svg_style}>
            <rect
              x={PedigreeStylesheet.oneClickAddSvgSymbolMale.x}
              y={PedigreeStylesheet.oneClickAddSvgSymbolMale.y}
              width={PedigreeStylesheet.oneClickAddSvgSymbolMale.width}
              height={PedigreeStylesheet.oneClickAddSvgSymbolMale.height}
              style={svg_symbol_style}
            />
          </svg>
        );
      }
    }

    const dropdown_menu_styles = {
      fontFamily: '"Inter", sans-serif',
      fontSize: 12,
      backgroundColor: '#ffffff',
    };
    const partner_dropdown_label = Object.assign({}, dropdown_menu_styles, {fontWeight: 'bold', textAlign: 'justify', padding: '15px 20px', whiteSpace: 'nowrap'});

    const default_num_to_add = 1;
    return (
      <div style={container_styles}>
        <button
          onClick={() => this.props.onClickAdd(actions.MARRIED, default_num_to_add)}
          className='btn-default'
          style={partner_btn_style}>

          {svg}
        </button>

        <div className={"dropdown " + show_dropdown}>
          <button
            className='btn-default'
            data-toggle="dropdown_partner_dom"
            aria-haspopup="true"
            aria-expanded="false"
            style={dropdown_btn_style}
            onClick={() => this.props.onDropdownClicked(dropdowns.ADD_PARTNER)}>

            <i className="fa fa-angle-down"></i>
          </button>

          <ul className="dropdown-menu" aria-labelledby="dropdown_partner_dom">
          <li style={partner_dropdown_label}>Partner</li>
            <li onClick={() => this.props.onClickAdd(actions.SAME_SEX, default_num_to_add)} style={dropdown_menu_styles}><a>Same Sex</a></li>
            <li onClick={() => this.props.onClickAdd(actions.SEPARATED, default_num_to_add)} style={dropdown_menu_styles}><a>Separated</a></li>
            <li onClick={() => this.props.onClickAdd(actions.DIVORCED, default_num_to_add)} style={dropdown_menu_styles}><a>Divorced</a></li>
            <li onClick={() => this.props.onClickAdd(actions.OTHER, default_num_to_add)} style={dropdown_menu_styles}><a>Other</a></li>
            <li onClick={() => this.props.onClickAdd(actions.CONSANGUINEOUS, default_num_to_add)} style={dropdown_menu_styles}><a>Consanguineous</a></li>
          </ul>
        </div>
      </div>
    );

  }
}

export default AddPartnerDOM;
