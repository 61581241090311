import React, { Component } from "react";
import ReactDOM from "react-dom";
import ActivityIndicator from "./activity-indicator";
import ErrorSummary from "./error-summary";

class ModalPopUpBlockerAlert extends Component {
  render() {
    let message = this.props.message;

    return ReactDOM.createPortal(
      <div
        onClick={() => this.props.onCancel()}
        style={{ zIndex: 9999, display: this.props.isOpen ? "block" : "none" }}
        className={"modal fade " + (this.props.isOpen ? "in" : "")}
        role="dialog"
      >
        <div
          onClick={e => e.stopPropagation()}
          className="modal-dialog modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                onClick={() => this.props.onOk()}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fa fa-close" />
              </button>
              <h4 className="modal-title text-white text-capitalize">
                {this.props.title ? this.props.title : ""}
              </h4>
            </div>

            <div className="modal-body">
              <div className="modal-confirm">
                <ErrorSummary errorMessages={this.props.errorMessages} />

                <p>
                  {message}
                </p>

                <ActivityIndicator loading={this.props.loading} />
                <br />
                  <div className="buttons">
                  <button
                    disabled={this.props.loading}
                    onClick={() => this.props.onOk()}
                    className="btn btn-purple btn-sm"
                    aria-label="Close"
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>,
      document.body
    );
  }
}

export default ModalPopUpBlockerAlert;