import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import helpersSkipLogic from '../helpers/helpers-skip-logic';
import skip_logic_map from '../assets/json/diseases-skip-logic-map.json'
class SkipLogicBirthDefectView extends Component {
  constructor(props) {
    super(props)
    this.state = {
     keys: {}
    }
  }
 
  getValue(key) {
    let key_map = skip_logic_map.birth_defects
    if(!(key in key_map)) return null

    let code = key_map[key]
    let data = this.props.skipLogics.skip_logics.find(item => item.skip_logic_code == code)
    if(typeof(data) === 'undefined') return null
    return data.answer
  }
  render(){
  let sl = this.props.skipLogics;
  let description = ''
  if(sl == null) return null
  
  let type = sl.skip_logics.find(element => {
    return element.skip_logic_code == skip_logic_map.birth_defects[element.short_name]
    })
  if (type && type.short_name) description = this.getValue(type.short_name)

  return(

    <React.Fragment >
        <p>
          {description}
        </p>

    </React.Fragment>
  )
}
}

export default SkipLogicBirthDefectView