import React, { Component } from 'react';

import skip_logic_map from '../assets/json/diseases-skip-logic-map.json';
const keys_mapping = skip_logic_map.colorectal_polyps

class SkipLogicColerectalPolypsView extends Component {

  getValue(key) {
    if(!(key in keys_mapping)) return null

    let code = keys_mapping[key]
    let data = this.props.skipLogics.find(item => item.skip_logic_code == code)

    if(typeof(data) === 'undefined') return null
    return data.answer
  }


  render() {
    let sl = this.props.skipLogics;
    if(sl == null) return null;

    let more_than_ten = this.getValue('more_than_ten')
    let hamartamatous = this.getValue('hamartamatous')

    return (
      <React.Fragment >
        <p>
          {more_than_ten == "y" && 'More than 10'}
        </p>

        <p>
          {hamartamatous == "y" && 'Hamartomatous'}
        </p>

      </React.Fragment>
    )
  }

}

export default SkipLogicColerectalPolypsView