import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import helpersSkipLogic from '../helpers/helpers-skip-logic';

class SkipLogicOtherCancerView extends Component {
  constructor(props) {
    super(props)
    this.state = {
     keys: {}
    }
  }
  getValue(key) {
    if(!(key in this.state.keys)) return null

    let code = this.state.keys[key]
    let data = this.props.skipLogics.skip_logics.find(item => item.skip_logic_code == code)

    if(typeof(data) === 'undefined') return null
    return data.answer
  }
  async getSkipLogicsMapping(){
    let mapping = await helpersSkipLogic.get_skip_logic_map(this.props.skipLogics.disease_id)
    this.setState({keys: mapping})
   }

  render(){
  let sl = this.props.skipLogics;
  let otherCancer = ''
  if(isEmpty(this.state.keys)){
    this.getSkipLogicsMapping()
  }

  let type = sl.skip_logics.find(element => {
    return element.skip_logic_code == this.state.keys[element.short_name]
    })
  if (type && type.short_name) otherCancer = this.getValue(type.short_name)

  return(

    <React.Fragment >
        <p>
          {otherCancer}
        </p>

    </React.Fragment>
  )
}
}

export default SkipLogicOtherCancerView
