import React, { Component } from "react";
import Select from 'react-select';

import LabelField from './label-field';
import i18n from 'i18next';

class SelectOneField extends Component {
  constructor(props) {
    super(props);

    this.state = {value: ''};

    this.onChangeSelect = this.onChangeSelect.bind(this);
  }

  componentDidMount() {
    if (this.props.answer) {
      this.setState({ value: this.props.answer.master_question_choice_id });
    }
  }

  onChangeSelect(value) {
    this.setState({ value: value });
    if (value) {
      const payload = {
        field_type: this.props.masterQuestion.type,
        master_question_id: this.props.masterQuestion.id,
        master_question_choice_id: value,
        answer: null
      };

      this.props.saveCompletedSurveyAnswer(payload);
    } else {
      // delete answer
      if (this.props.answer) {
        this.props.deleteCompletedSurveyAnswer(this.props.answer);
      }
    }
  }

  itemExists(item) {
    if(item === undefined || item === null) {return '';}
    return item.value;
  }

  render() {
    let chosen_choice = '';
    const choices = this.props.masterQuestion.master_question_choices.map((choice) => {
      const translation_key = choice.translation_key + ".choice"
      let choice_label_translation = i18n.t(translation_key);
      if (choice_label_translation === translation_key) {
        choice_label_translation = choice.choice;
      }

      const option = { value: choice.id, label: choice_label_translation };

      if(parseInt(choice.id, 10) === parseInt(this.state.value, 10)) {
        chosen_choice =  option;
      }

      return option;
    });

    let column_one = null;
    let column_two = null;
    const field = (
      <Select
        name={this.props.masterQuestion.question_uuid}
        id={this.props.masterQuestion.question_uuid}
        onChange={(item) => this.onChangeSelect(this.itemExists(item))}
        value={chosen_choice}
        className='react-select-container'
        classNamePrefix="react-select"
        isClearable={true}
        placeholder=""
        options={choices}
      />
    );

    if (this.props.masterQuestion.question_label) {

      column_one = (
        <LabelField
          masterQuestion={this.props.masterQuestion}
        />
      );

    }

    column_two = field;

    return (
      <div className="form-group">
        <div className="row">

          <div className="col-md-6">
            {column_one}
          </div>

          <div className="col-md-6">
            {column_two}
          </div>

        </div>
      </div>
    );

  }
}

export default SelectOneField;
