import React, { Component } from 'react';

import helpers from '../helpers/index';
import images from '../configs/images';
import route_helper from '../route-helper';
import api_auth from '../api/authentication';

import * as action_auth from '../store/session/actions';
import { save_risk_settings } from '../store/patient/actions';
import qs from 'qs'
import { connect } from "react-redux";
import ErrorSummary from "../components/error-summary";
import ActivityIndicator from "../components/activity-indicator";
import settings from "../configs/settings";
import Cookie from 'js-cookie'
import {
  save_login_data
} from '../helpers/helper-login';

const EMR_LOGIN = true

class AuthenticateEMRPage extends Component {

    constructor(props) {
      super(props);
      this.state = {
       errorMessages: [],
       params:{}
      };

      this.handleLoginFail = this.handleLoginFail.bind(this);
      this.authenticateEmrUser = this.authenticateEmrUser.bind(this);
      this.authenticateSSO = this.authenticateSSO.bind(this);
      this.storeQueryParams = this.storeQueryParams.bind(this);
    }

    async componentDidMount() {
      helpers.setSiteTitle('EMR Integration');
      this.mounted = true;
      await this.authenticateEmrUser();
    }

    async componentWillUnmount() {
      this.mounted = false;
    }

    async authenticateEmrUser(){
      api_auth.clearTokens();
      try{
        this.setState({loading: true})
        let query = qs.parse(this.props.location.search, {
          ignoreQueryPrefix: true
        })

        sessionStorage.setItem('is_emr_session', true)

      //Unrecognizable unicode characters being passed in to mrn search
      //stringify the mrn and remove outer quotes
      //then find first index of \ character and remove from that character on in the string
      //check index of \, if no \ then index = -1 so skip slicing
        let clean_mrn = JSON.stringify(query.mrn)
        let finalMrn = ''
        let UUID =  query.token
        let encryptedData = query.data
        if(query.mrn){
          clean_mrn = clean_mrn.replace(/['"]+/g, '')
          let indexOfUnwantedCharacter = clean_mrn.indexOf("\\");
  
          if(indexOfUnwantedCharacter >= 0){
            finalMrn = clean_mrn.slice(0,indexOfUnwantedCharacter)
          }else{
            finalMrn = clean_mrn
          }
        }

        let login_type = ''
        if (query.syslogin){
          login_type = await api_auth.login_type_check(query.syslogin, EMR_LOGIN)
        }

        try {
          if (login_type === 'saml') {
            let response = await api_auth.saml_login(query.syslogin);
            this.storeQueryParams(query, finalMrn);
            window.open(response['redirect_url'], "_self");
            return;
          } else if (login_type === "oauth") {
            await this.authenticateSSO(query, finalMrn);
            return;
          }
        } catch (error) {
          console.log(error.stack);
        }
        let payload = await api_auth.authenticateEmrUser(UUID)
        let clone = Object.assign({}, this.props.session.user);
        let is_admin = (payload.account.access_type === 'admin') ? true : false;
        let is_read_only = (payload.account.access_type === 'read_only') ? true : false;
        const auto_logout_time = payload.account.auto_logout_time ? payload.account.auto_logout_time : 15;
        this.props.dispatch(action_auth.user_save({
          clinician_id: payload.organization.clinician_id,
          account_id: payload.account.account_id,
          staff_id: payload.account.staff_id,
          user_id: payload.profile.user_id,
          first_name: payload.profile.first_name,
          last_name: payload.profile.last_name,
          email: payload.profile.email,
          username: payload.profile.username,
          admin: is_admin,
          account_type: payload.organization.org_type,
          organization_id: payload.organization.id,
          risk_model_years: payload.organization.risk_model_years,
          criteria_source_category_settings: payload.criteria_source_category_settings,
          criteria_source_categories: payload.criteria_source_categories,
          dial_code: payload.organization.dial_code,
          read_only: is_read_only,
          emr_always_read_only: payload.organization.emr_always_read_only,
          login_type: payload.account.login_type,
          auto_logout_time: auto_logout_time,
          default_partner_view: payload.organization.default_partner_view,
          max_number_of_pedigrees: payload.organization.max_number_of_pedigrees,
        }));

        this.props.dispatch(action_auth.authenticated(true));
        this.navigateUser(query.fname, query.lname, query.dob, finalMrn, encryptedData, query.signature)
      } catch (error) {
        this.handleLoginFail('Error logging in. Contact your administrator.')
      } finally {
        if(this.mounted) {
          this.setState({ loading: false });
        }
      }
    }

    storeQueryParams(query, finalMrn){  
      if(query.data){
        localStorage.setItem('signature', query.signature)
        localStorage.setItem('encryptedData', query.data)

      }else{
        localStorage.setItem('fname', query.fname)
        localStorage.setItem('lname', query.lname)
        localStorage.setItem('dob', query.dob)
        localStorage.setItem('finalMrn', finalMrn)
      }
    }

    async authenticateSSO(query, finalMrn){
      this.setState({loading: true})
      api_auth.clearTokens();
      this.storeQueryParams(query, finalMrn)
      try{

        let response = await api_auth.get_oauth_redirect(query.syslogin)
        Cookie.set('OAuth', response['state']);
        window.open(response['url'],"_self")//api_auth.verify_oauth(query['code'], query['state'])
      } catch (error) {
          this.handleLoginFail('Error logging in. Contact your administrator.')
      } finally {
        if(this.mounted) {
          this.setState({ loading: false });
        }
      }
      return
  }

  async navigateUser(firstName, lastName, dob, mrn, encryptedData, signature){
    if(encryptedData){
      this.props.history.push(`/emr_patients?signature=${signature}&data=${encryptedData}`);
    }else{
      this.props.history.push(`/emr_patients?fname=${firstName}&lname=${lastName}&dob=${dob}&mrn=${mrn}`);

    }
  }
    handleLoginFail(error) {
      this.setState({errorMessages: [error]});
    }

    render() {
      const copyright_year = new Date().getFullYear();
      return (
        <React.Fragment>
          <div className="login_wrapper">
            <div className="animate form login_form">
              <section className="login_content">
                <ErrorSummary errorMessages={this.state.errorMessages} />
                  <div className="logo">
                      <img src={images.famhisLogo.default} className="img-responsive" alt="FamGenix Logo" />
                  </div>
                  <div className="animate form login_form">
                    <div><p className="text-center text-muted margin-nine-top"><span>&copy; {copyright_year} by FamHis, Inc. All rights reserved.</span></p></div>
                  </div>
              </section>
            </div>
          </div>
        <ActivityIndicator loading={this.state.loading} />

        </React.Fragment>
      );
    }
  }
  const redux_state = state => ({
    session: state.session
  });

  const redux_actions = dispatch => ({
    dispatch: (action) => dispatch(action)
  });

  export default connect(redux_state, redux_actions)(AuthenticateEMRPage);
