import Cookie from 'js-cookie'


export const set_cookie = (key, value, expires_in = 7) => {
  Cookie.set(key, value, {expires: expires_in, sameSite: 'strict'})
}

export const remove_cookie = (key, value, expires_in = 7) => {
  Cookie.remove(key, value, {expires: expires_in, sameSite: 'strict'})
}

export const get_cookie = (key) => {
  return Cookie.get(key)
}
