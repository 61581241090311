import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import uniqid from 'uniqid';
import ValidationErrorSummary from '../../../validation-error-summary';
import helpers from '../../../../helpers/index';
import file_api from '../../../../api/file-api';
import ActivityIndicator from '../../../activity-indicator';

import axios from 'axios';

import mime_types from '../../../../configs/mime_types';

class ModalAddDocument extends Component {

  constructor(props) {
    super(props);
    this.state = {
      addDocumentBtnDisabled: true,
      validations: null,
      uploading: false,

      name: '',
      type: null,
      created_at: null,
      note: '',
      file: null,
      uploadFailed: false
    };
    this.resetState = this.resetState.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSaveDocument = this.onSaveDocument.bind(this);
    this.validate = this.validate.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);

    this.fileInputLabel = React.createRef();
  }

  onChange(event) {
    this.setState({[event.target.name]: event.target.value})
  }

  onFilesAdded(event) {
    const file = event.target.files[0];
    if(file !== null && file !== undefined && file !== '') {
      if (mime_types.VALID_USER_DOC_MIME_TYPES.includes(file.type)) {
        this.fileInputLabel.current.innerText = "File: " + event.target.value.substr(12, event.target.value.length);
        this.setState({ file: file, created_at: new Date(), addDocumentBtnDisabled: false });
      } else {
        // reset state and show an error message
        this.resetState();
        this.setState({ uploadFailed: true });
      }
    }
  }

  resetState() {
    this.setState({
      addDocumentBtnDisabled: true,
      validations: null,
      uploading: false,

      name: '',
      type: null,
      created_at: null,
      note: '',
      file: null,
    });

    this.fileInputLabel.current.innerText = "Please choose a file on your computer."
  }

  validate() {
    this.setState({ validations: null });

    let options = {
      file: {
        labelName: 'File',
        validations: {
          required: true
        }
      },
      name: {
        labelName: 'File Name',
        validations: {
          required: true
        }
      },
      type: {
        labelName: 'Type',
        validations: {
          required: true
        }
      }
    };

    let validations = helpers.validateInputs(this.state, options);
    this.setState({ validations: validations });
    return validations.isValid;
  }

  async onSaveDocument(event) {
    // check and make sure the user has chosen the required fields before letting them save a document
    let isValid = this.validate();
    if (!isValid) return;

    this.uploadFiles();
  }

  async uploadFiles() {

    if(this.props.member_id || this.props.org_id) {
      this.setState({ uploading: true });

      let response = null;

      if(this.props.member_id){
        response = await file_api.upload_user_file({
          original_filename: this.state.file.name,
          custom_filename: this.state.name,
          mime_type: this.state.file.type,
          file_size: this.state.file.size,
          file_note: this.state.note,
          file_category: this.state.type,
          member_id: this.props.member_id,
          org_or_member_file: "member",
        });
      }
      else if(this.props.org_id){
        response = await file_api.upload_user_file({
          original_filename: this.state.file.name,
          custom_filename: this.state.name,
          mime_type: this.state.file.type,
          file_size: this.state.file.size,
          file_note: this.state.note,
          file_category: this.state.type,
          org_id: this.props.org_id,
          org_or_member_file: "org",
        });
      }

      if ('data' in response) {

        const aws_s3 = response.data.s3;
        const formData = new FormData();
        for (const key in aws_s3.fields) {
          formData.append(key, aws_s3.fields[key]);
        }
        // formData.append("key", aws_s3.fields.key);
        // formData.append("x-amz-algorithm", aws_s3.fields['x-amz-algorithm']);
        // formData.append("x-amz-credential", aws_s3.fields['x-amz-credential']);
        // formData.append("x-amz-date", aws_s3.fields['x-amz-date']);
        // formData.append("policy", aws_s3.fields.policy);
        // formData.append("x-amz-signature", aws_s3.fields['x-amz-signature']);
        // formData.append("AWSAccessKeyId", aws_s3.fields.AWSAccessKeyId);
        // formData.append("signature", aws_s3.fields.signature);
        formData.append("file", this.state.file, this.state.file.name);

        axios.post(aws_s3.url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',

            // setting this to blank because somewhere
            // (i think in survey api) the default Authorization header
            // is being set and interferring with this request
            'Authorization': ''
          }
        })
        .then((response) => {
          this.setState({ uploading: false });
          this.props.onClickSave();
          this.props.onClose();
        })
        .catch((error) => {
          // delete the record in s3files table in db because the post to S3 failed
          // we do not want a lingering record not pointing to an actual S3 file
          file_api.delete_user_file_from_db(response.data.file_record.id);
          this.setState({ uploading: false });
          this.props.onClickSave();
          this.props.onClose();
        });
      } else {
        this.setState({ uploading: false });
      }
      if('error' in response){
        this.setState({uploadFailed: true})
        setTimeout( () => {
          this.setState({uploadFailed: false});
        }, 5000);
      }
    }
  }

  render() {

    let doc_types = [];
    if(this.props.org_id){
      doc_types = [
        { value: 'Invoice', label: 'Invoice' },
        { value: 'Quote', label: 'Quote' },
        { value: 'Contract', label: 'Contract' },
        { value: 'Other', label: 'Other' },
      ];
    }
    else if(this.props.member_id){
      doc_types = [
        { value: 'Lab Results', label: 'Lab Results' },
        { value: 'Radiology / Imaging', label: 'Radiology / Imaging' },
        { value: 'Hospital Records', label: 'Hospital Records' },
        { value: 'Other Docs', label: 'Other Docs' },
      ];
    }

    let chosen_doc_type = null;
    for(let item of doc_types) {
      if(this.state.type && this.state.type === item.value) {
        chosen_doc_type = item;
      }
    }

    return ReactDOM.createPortal(
      <React.Fragment>
        <div onClick={() => this.props.onClose()}
          style={{ display: 'block' }}
          className="modal fade in"
          role="dialog"
          aria-labelledby="add-doc"
          id="add-doc">

          <div onClick={(e) => e.stopPropagation()} className="modal-dialog" role="document">
            <div className="modal-content">

              <div className="modal-header">
                <button onClick={() => this.props.onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <i className="fa fa-close"></i>
                </button>
                <h4 className="modal-title text-white text-capitalize">Add Document</h4>
              </div>

              <div className="modal-body">
                <ValidationErrorSummary
                  validations={this.state.validations}
                />
                {this.state.uploadFailed && (
                  <div className="utility-warning-box">
                    File type not supported
                  </div>
                 )}

                <form className="form-horizontal">
                  <div className="margin-two-bottom">
                    <span className="m-b-15-purple">Upload a file</span>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <span className="control-fileupload">
                        <label htmlFor="file" className="text-left" ref={this.fileInputLabel}>Please choose a file on your computer.</label>
                        <input name="file" id="file" type="file" onChange={this.onFilesAdded} />
                      </span>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="form-group">
                    <label htmlFor="name" className="control-label col-md-3 col-sm-3 col-xs-12">File Name</label>
                    <div className="col-md-6 col-sm-9 col-xs-12">
                      <input name="name" id="name" onChange={this.onChange} value={this.state.name} type="text" className="form-control normal-input-text" />
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="type" className="control-label col-md-3 col-sm-3 col-xs-12">Type</label>
                    <div className="col-md-6 col-sm-9 col-xs-12">
                      <Select
                        name="type"
                        id="type"
                        onChange={(item) => {
                          (item) ? this.setState({ type: item.value }) : this.setState({ type: null })
                        }}
                        value={chosen_doc_type}
                        className='react-select-container'
                        classNamePrefix="react-select"
                        isClearable={true}
                        placeholder=""
                        options={doc_types}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="note" className="control-label col-md-3 col-sm-3 col-xs-12">Note</label>
                    <div className="col-md-6 col-sm-9 col-xs-12">
                      <textarea name="note" id="note" onChange={this.onChange} value={this.state.note} rows="2" className="form-control"></textarea>
                    </div>
                  </div>
                </form>
                {/*<Upload />*/}
              </div>

              <div className="modal-footer">
                <button
                  onClick={() => this.props.onClose()}
                  type="button" className="btn btn-light-outline no-margin-right" data-dismiss="modal">Cancel</button>
                <button
                  disabled={this.state.addDocumentBtnDisabled}
                  onClick={this.onSaveDocument}
                  type="button" className="btn btn-dark">Add Document</button>
              </div>
            </div>
          </div>
        </div>
        <ActivityIndicator
            modal={true}
            loading={this.state.uploading}
        />
      </React.Fragment>,
      document.body
    );
  }

}

export default ModalAddDocument;
