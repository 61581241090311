import { createStore, compose } from 'redux'

import reducerRegistry from './reducerRegistry';

const initialState = {}
const enhancers = []

if (process.env.REACT_APP_NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  ...enhancers
)

const store = createStore(
  reducerRegistry,
  initialState,
  composedEnhancers
)

export default store
