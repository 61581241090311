import React, { Component } from 'react';
import ModalDiseasesForm from '../modal-diseases-form';
import ModalConfirmDelete from "../modal-confirm-delete";
import SkipLogicBreastView from '../skip-logic-breast-view';
import SkipLogicColerectalView from '../skip-logic-colorectal-view';
import SkipLogicColerectalPolypsView from '../skip-logic-colorectal-polyps-view';
import SkipLogicMelanomaView from '../skip-logic-melanoma-view';
import SkipLogicHodgkinLymphomaView from '../skip-logic-hodgkin-lymphoma-view';
import SkipLogicDCISView from '../skip-logic-dcis-view';
import SkipLogicPancreaticNeuroendocrineView from '../skip-logic-pancreatic-neuroendocrine-view';
import SkipLogicProstateView from '../skip-logic-prostate-view';
import SkipLogicThyroidView from '../skip-logic-thyroid-view';
import SkipLogicUterineView from '../skip-logic-uterine-view';
import SkipLogicLeukemiaView from '../skip-logic-leukemia-view';
import SkipLogicBirthDefectsView from '../skip-logic-birth-defects-view';
import SkipLogicOtherCancerView from '../skip-logic-other-cancer-view';
import { createUUID } from './utils';

import disease_api from "../../api/disease-api";
import { skipLogicUMLS } from '../../helpers/diseaseUMLSSkipLogic';


class PatientDiseaseView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModalDiseases: false,
      selectedDisease: null,
      modalDiseaseTitle: '',
      errorMessages: [],
      openModalConfirmDelete: false,
      loading_delete: false,
      modalConfirmDeleteHash: null
    };

    this.handleClickAddDisease = this.handleClickAddDisease.bind(this);
    this.getDiseases = this.getDiseases.bind(this);
    this.diseaseGroup = this.diseaseGroup.bind(this);
    this.handleEditDisease = this.handleEditDisease.bind(this);
    this.onModalDiseaseSave = this.onModalDiseaseSave.bind(this);
    this.onModalDiseaseDelete = this.onModalDiseaseDelete.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.onClickOpenModalConfirmDelete = this.onClickOpenModalConfirmDelete.bind(this);
    this.disableAddForReadOnly = this.disableAddForReadOnly.bind(this);
    this.breastSkipCheck = this.breastSkipCheck.bind(this);
    this.colorectalSkipCheck = this.colorectalSkipCheck.bind(this);
    this.colorectalPolypsSkipCheck = this.colorectalPolypsSkipCheck.bind(this);
    this.melanomaSkipCheck = this.melanomaSkipCheck.bind(this);
    this.hodgkinSkipCheck = this.hodgkinSkipCheck.bind(this);
    this.dcisSkipCheck = this.dcisSkipCheck.bind(this);
    this.pancreaticNeuroSkipCheck = this.pancreaticNeuroSkipCheck.bind(this);
    this.prostateSkipCheck = this.prostateSkipCheck.bind(this);
    this.thyroidSkipCheck = this.thyroidSkipCheck.bind(this);
    this.uterineSkipCheck = this.uterineSkipCheck.bind(this);
    this.leukemiaSkipCheck = this.leukemiaSkipCheck.bind(this);
    this.birthdefectSkipCheck = this.birthdefectSkipCheck.bind(this);
  }

  async handleClickAddDisease() {
    if('onBeforeAddDisease' in this.props) {
      var ok = await this.props.onBeforeAddDisease();
      if(ok) {
        this.setState({showModalDiseases: true, selectedDisease: null, modalDiseaseTitle: 'Add Disease'});
      }
    } else {
      this.setState({showModalDiseases: true, selectedDisease: null, modalDiseaseTitle: 'Add Disease'});
    }
  }

  getDiseases() {
    const diseases = this.props.getPedigreeData().getDiseases(this.props.patientRkey);
    for(let i=0; i<diseases.length; i++) if(diseases[i].rkey === null || diseases[i].rkey === undefined) diseases[i].rkey = createUUID();
    return diseases;
  }

  diseaseGroup(id) {
    if(1 <= id <= 43) {
      return 'Cancer';
    } else {
      return 'Other Conditions';
    }
  }

  handleEditDisease(selectedDisease) {
    this.setState({showModalDiseases: true, selectedDisease, modalDiseaseTitle: 'Edit Disease'})
  }

  async onModalDiseaseSave(data) {
    if('onDiseaseUpdate' in this.props){
      await this.props.onDiseaseUpdate(data);
      this.props.updateSubtextMargin()
    }
    this.setState({showModalDiseases: false});
  }

  async onModalDiseaseDelete(data) {
    if('onDiseaseDelete' in this.props){
      await this.props.onDiseaseDelete(data);
      this.props.updateSubtextMargin()
    }
    this.setState({showModalDiseases: false});
    if(this.state.openModalConfirmDelete === true) this.setState({openModalConfirmDelete: false});
  }

  async onClickDelete() {
    try {
      this.setState({ errorMessages: [], loading_delete: true });

      // delete_member_disease_id
      if (this.state.selectedDisease.id !== null) {
        await disease_api.delete_member_disease_id(this.state.selectedDisease.id);
      }

      if('onDiseaseDelete' in this.props) {
        this.onModalDiseaseDelete({
          rkey: this.props.patientRkey,
          disease_rkey: this.state.selectedDisease.rkey
        });
      } else {
        this.setState({openModalConfirmDelete: false});
        if (this.state.showModalDiseases) this.setState({ showModalDiseases: false });
      }

    } catch (error) {
      this.setState({ errorMessages: [error.message] });
    } finally {
      this.setState({ loading_delete: false });
    }
  }

  onClickOpenModalConfirmDelete(selectedDisease) {
    this.setState({
      selectedDisease,
      openModalConfirmDelete: true,
      modalConfirmDeleteHash: new Date().getTime()
    });
  }

  disableAddForReadOnly() {
    if(this.props.read_only && this.props.read_only === true) {
      return false
    }
    return true
  }

  breastSkipCheck(disease_id, disease_code){
    let id = disease_code ? disease_code : disease_id
    return skipLogicUMLS.breastUMLS.includes(id)
  }

  colorectalSkipCheck(disease_id, disease_code){
    let id = disease_code ? disease_code : disease_id
    return skipLogicUMLS.colorectalUMLS.includes(id)
  }

  colorectalPolypsSkipCheck(disease_id, disease_code){
    let id = disease_code ? disease_code : disease_id
    return skipLogicUMLS.colorectalPolypsUMLS.includes(id)
  }

  melanomaSkipCheck(disease_id, disease_code){
    let id = disease_code ? disease_code : disease_id
    return skipLogicUMLS.melanomaUMLS.includes(id)
  }

  hodgkinSkipCheck(disease_id, disease_code){
    let id = disease_code ? disease_code : disease_id
    return skipLogicUMLS.hodgkinlymphomaUMLS.includes(id)
  }

  dcisSkipCheck(disease_id, disease_code){
    let id = disease_code ? disease_code : disease_id
    return skipLogicUMLS.dcisUMLS.includes(id)
  }

  pancreaticNeuroSkipCheck(disease_id, disease_code){
    let id = disease_code ? disease_code : disease_id
    return skipLogicUMLS.pancreaticNeuroendocrineUMLS.includes(id)
  }

  prostateSkipCheck(disease_id, disease_code){
    let id = disease_code ? disease_code : disease_id
    return skipLogicUMLS.prostateUMLS.includes(id)
  }

  thyroidSkipCheck(disease_id, disease_code){
    let id = disease_code ? disease_code : disease_id
    return skipLogicUMLS.thyroidUMLS.includes(id)
  }

  uterineSkipCheck(disease_id, disease_code){
    let id = disease_code ? disease_code : disease_id
    return skipLogicUMLS.uterineUMLS.includes(id)
  }

  leukemiaSkipCheck(disease_id, disease_code){
    let id = disease_code ? disease_code : disease_id
    return skipLogicUMLS.leukemiaUMLS.includes(id)
  }

  birthdefectSkipCheck(disease_id, disease_code){
    let id = disease_code ? disease_code : disease_id
    return skipLogicUMLS.birthdefectsUMLS.includes(id)
  }

  render() {
    return (
      <React.Fragment>
        <div className="row margin-four-bottom">
          <div className="col-md-12 margin-one no-margin-top">
            <div className={(this.props.sideBar ? "form-header-2" : "form-header")}>
              <div className="row vcenter">
                <div className="col-md-6 ">
                  <h3 className={"title " + (this.props.sideBar ? 'text--uppercase' : '')}>Diseases</h3>
                </div>
              {this.disableAddForReadOnly() &&(
                <div className="col-md-6 ">
                    <a className="btn btn-teal btn-xs" onClick={this.handleClickAddDisease}><i className="fa fa-plus"></i> Add</a>
                </div>
              )}
              </div>
            </div>
            <div className="panel-col">

              { this.getDiseases().map(item =>

                <div key={item.rkey} className={"panel-summary " + (this.props.sideBar ? 'width--full' : '')}>
                {this.disableAddForReadOnly() &&(
                  <>
                  <a className="panel-summary-edit" onClick={() => this.handleEditDisease(item)}><i className="fa fa-pencil-square-o"></i></a>
                  <a className="panel-summary-delete" onClick={() => this.onClickOpenModalConfirmDelete(item)}><i className="fa fa-trash"></i></a>
                </>
                )}
                  <h4>{item.disease_name} Dx {item.age_diagnosed_string ? item.age_diagnosed_string : item.age_diagnosed ? item.age_diagnosed : '?'  }</h4>

                  {this.breastSkipCheck(item.disease_id, item.disease_code) &&
                    <SkipLogicBreastView skipLogics={item.skip_logics} />
                  }

                  {this.colorectalSkipCheck(item.disease_id, item.disease_code) &&
                    <SkipLogicColerectalView skipLogics={item.skip_logics} />
                  }

                  {this.colorectalPolypsSkipCheck(item.disease_id, item.disease_code) &&
                    <SkipLogicColerectalPolypsView skipLogics={item.skip_logics} />
                  }

                  {this.melanomaSkipCheck(item.disease_id, item.disease_code) &&
                    <SkipLogicMelanomaView skipLogics={item.skip_logics} />
                  }

                  {this.hodgkinSkipCheck(item.disease_id, item.disease_code) &&
                    <SkipLogicHodgkinLymphomaView skipLogics={item.skip_logics} />
                  }

                  {this.dcisSkipCheck(item.disease_id, item.disease_code) &&
                    <SkipLogicDCISView skipLogics={item.skip_logics} />
                  }

                  {this.pancreaticNeuroSkipCheck(item.disease_id, item.disease_code) &&
                    <SkipLogicPancreaticNeuroendocrineView skipLogics={item.skip_logics} />
                  }

                  {this.prostateSkipCheck(item.disease_id, item.disease_code) &&
                    <SkipLogicProstateView skipLogics={item.skip_logics} />
                  }

                  {this.thyroidSkipCheck(item.disease_id, item.disease_code) &&
                    <SkipLogicThyroidView skipLogics={item.skip_logics} />
                  }

                  {this.uterineSkipCheck(item.disease_id, item.disease_code) &&
                    <SkipLogicUterineView skipLogics={item.skip_logics} />
                  }

                  {this.leukemiaSkipCheck(item.disease_id, item.disease_code) &&
                    <SkipLogicLeukemiaView skipLogics={item}/>
                  }
                  {this.birthdefectSkipCheck(item.disease_id, item.disease_code) &&
                    <SkipLogicBirthDefectsView skipLogics={item} />
                  }
                  {item.disease_code == 'D107' &&
                    <SkipLogicOtherCancerView skipLogics={item} />
                  }
                </div>
              )}

            </div>
          </div>
        </div>

        {this.state.openModalConfirmDelete && (
          <ModalConfirmDelete
            title="Delete Disease"
            message="Do you want to delete this disease?"
            isOpen={this.state.openModalConfirmDelete}
            onCancel={() => this.setState({ openModalConfirmDelete: false })}
            onOk={() => this.onClickDelete()}
            loading={this.state.loading_delete}
            errorMessages={this.state.errorMessages}
          />
        )}


        {this.state.showModalDiseases &&
          <ModalDiseasesForm
            title={this.state.modalDiseaseTitle}
            patientRkey={this.props.patientRkey}
            profile={this.props.profile}
            disease={this.state.selectedDisease}
            onClose={() => this.setState({showModalDiseases: false})}
            onCancel={() => this.setState({showModalDiseases: false})}
            onSave={(data) => this.onModalDiseaseSave(data)}
            onDelete={(data) => this.onModalDiseaseDelete(data)}
            getPedigreeData={this.props.getPedigreeData}
            getMemberInfoAge={() => this.props.getMemberInfoAge()}
          />
        }

      </React.Fragment>
    )
  }
}

export default PatientDiseaseView;
