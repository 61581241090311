import React, { Component } from 'react';

import ActivityIndicator from "../activity-indicator";
import ErrorSummary from "../error-summary";
import api_auth from '../../api/authentication';
import images from '../../configs/images'
import * as action_auth from '../../store/session/actions';

class LinkExpired extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: false,
        expiredLink: false,
        sentLink: false,
        errorMessages: []
      };
      this.resendLink = this.resendLink.bind(this)
    }
async componentDidMount(){
        this.mounted = true
    }

async componentWillUnmount() {
        this.mounted = false;
    }

async resendLink(){
    try{
        await api_auth.resend_password_reset_email(this.props.uid64,  this.props.initialCreation); 
        
    }catch(err){    
        let parsedError = api_auth.parse_error_message(err);
        this.setState({errorMessages: [parsedError.message]});
    }
        this.setState({sentLink: true})
    }

  render() {
    return (
      <React.Fragment>
         {this.state.sentLink == false &&(
            <div className="login_wrapper">
                <div className="animate form login_form">
                    <section className="login_content">
                        <div className="logo">
                            <img src={images.famhisLogo.default} className="img-responsive" alt="FamGenix Logo" />
                        </div>
                        <p>
                            <b>
                            Sorry, this link has expired
                            </b>
                        </p>
                        <div className="form-group">
                            <button type="submit" className="btn btn-teal btn-block btn-lg no-margin-top" onClick={this.resendLink} disabled={this.props.disabled}>Resend Link</button>
                        </div>
                    </section>
                </div>
            </div>
            )}
            {this.state.sentLink == true &&(
                <div className="login_wrapper">
                    <div className="animate form login_form">
                        <section className="login_content">
                            <div className="logo">
                                <img src={images.famhisLogo.default} className="img-responsive" alt="FamGenix Logo" />
                            </div>
                                <p>
                                    <b>
                                    A new link has been sent to your email!
                                    </b>
                                </p>
                        </section>
                    </div>
                </div>
            )}
        <ErrorSummary errorMessages={this.state.errorMessages} />
        <ActivityIndicator loading={this.state.loading} />
    
      </React.Fragment>
      )
    }  
}

    export default LinkExpired;

