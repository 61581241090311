import React, { Component } from 'react';
import InputAndOr from './input-andor';

class InputReferral extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    let item = Object.assign({}, this.props.item, {[e.target.name]: e.target.value})
    this.props.onChange(item)
  }

  render() {
    return (
      <React.Fragment >

        {this.props.itemIndex > 0 &&
          <InputAndOr
            value={this.props.item.andOr}
            onChange={(e) => this.handleChange(e)}
            colWidth='col-md-3'
          />}

        <div className="col-md-6 col-xs-12 no-padding-left">
          <select
            name="referral"
            value={this.props.item.referral}
            onChange={this.handleChange}
            className="form-control input-sm no-padding-top no-padding-bottom">
            <option value="" selected>Referral Criteria</option>
            <option value="any">Any</option>
            <option value="none">None</option>
            <option value='breast/ovarian'>Breast/Ovarian</option>
            <option value='colorectal'>Colorectal</option>
            <option value='other'>Other</option>
          </select>
        </div>
        <div className="col-md-3 col-xs-12 margin-one no-padding-left ">

          {this.props.showDeleteButton &&
            <a onClick={() => this.props.onDeleteItemClick()}
              href="#"><i className="fa fa-lg fa-close"></i></a>
          }

          {this.props.showAddButton &&
            <a href="#" onClick={() => this.props.onAddItemClick()}>
              <i className="fa fa-lg fa-plus"></i>
            </a>
          }

        </div>

      </React.Fragment>
    )
  }
}

export default InputReferral;
