import React, { Component } from "react";
import ReactDOM from "react-dom";
import ActivityIndicator from "./activity-indicator";
import ErrorSummary from "./error-summary";
import refinery_api from '../api/refinery-api'
import { connect } from "react-redux";
import * as user_actions from "../store/session/actions"
import sdk from '../api/sdk'

class ModalCriteriaSource extends Component {
  constructor(props){
    super(props);

    this.state = {};
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  componentDidMount(){
    const criteria_source_category_ids = [];
    for (let i=0; i<this.props.user.criteria_source_category_settings.length; i++) {
      criteria_source_category_ids.push(this.props.user.criteria_source_category_settings[i].criteria_source_category_id);
    }

    const state_payload = {};
    for (let i=0; i<this.props.user.criteria_source_categories.length; i++) {
      const category = this.props.user.criteria_source_categories[i];
      state_payload[category.uuid] = {
        checked: criteria_source_category_ids.includes(category.id),
        ...category
      };
    }

    this.setState(state_payload);
  }

  handleCheckboxChange(e){
    const option = this.state[e.target.name];

    this.setState({
      [option.uuid]: {
        ...option,
        checked: e.target.checked
      }
    });
  }

  async onClickSave(){
    const payload = {};
    for (const [key, value] of Object.entries(this.state)) {
      payload[key] = value.checked;
    }

    // call criteria_setting endpoint to save
    const data = await refinery_api.update_criteria_settings(payload);

    // save to redux store session user the settings
    this.props.dispatch(user_actions.user_save({
      ...this.props.user,
      criteria_source_category_settings: data.settings
    }));

    this.props.onCancel()
  }


  render() {

    const options = [];
    for (const [key, value] of Object.entries(this.state)) {
      options.push(
        (<div key={key} className="checkbox">
            <label style={{ fontSize: 12 }}>
                <input
                  type="checkbox"
                  name={key}
                  onChange={this.handleCheckboxChange}
                  checked={(value.checked) ? true : false}
                />
                {value.name} ({value.abbreviation})
            </label>
          </div>)
      )
    }

    return ReactDOM.createPortal(
      <div
        onClick={() => this.props.onCancel()}
        style={{ zIndex: 9999, display: this.props.isOpen ? "block" : "none" }}
        className={"modal fade " + (this.props.isOpen ? "in" : "")}
        role="dialog"
      >
        <div
          onClick={e => e.stopPropagation()}
          className="modal-dialog modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                onClick={() => this.props.onCancel()}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fa fa-close" />
              </button>
              <h4 className="modal-title text-white text-capitalize">
                {this.props.title ? this.props.title : ""}
              </h4>
            </div>

            <div className="modal-body">
              <div>

                <p style={{textAlign: 'left'}}>
                  Select the criteria source to use:
                </p>

                <ActivityIndicator loading={this.props.loading} />
                <form>
                    {options}
                </form>
                <div style={{textAlign: 'right'}}>
                    <button
                    type="button"
                    onClick={() => this.props.onCancel()}
                    className="btn btn-light-outline "
                    style={{ marginRight: 6 }}
                    data-dismiss="modal"
                    >
                    Cancel
                    </button>
                    <button
                    type="button"
                    onClick={() => this.onClickSave()}
                    className="btn btn-dark "
                    data-toggle="modal"
                    data-dismiss="modal"
                    >
                    Save
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>,
      document.body
    );
  }
}

const redux_state = state => ({
  user: state.session.user
});

const redux_actions = dispatch => ({
  dispatch: action => dispatch(action)
});

export default connect(
  redux_state,
  redux_actions
)(ModalCriteriaSource);
