import React, { Component } from 'react';

import { radix_bases } from '../helpers/helper-number-bases';
import skip_logic_map from '../assets/json/diseases-skip-logic-map.json';
const keys_mapping = skip_logic_map.breast;

class SkipLogicBreastView extends Component {

  plusMinusLabel(label, item, comma=',') {

    if(item == null) return ''

    let text = label
    text += (item == 'p' ? '-positive' : '-negative');
    return text + comma + ' ';
  }

  yesNoLabel(label, age=null) {
    let text = ''
    if(age){
      text += label + ' Dx ' + age;
    } else {
      text += label;
    }

    if(text !== '') text += ', ';
    return text;
  }

  getValue(key) {
    if(!(key in keys_mapping)) return null

    let code = keys_mapping[key]
    let data = this.props.skipLogics.find(item => item.skip_logic_code == code)

    if(typeof(data) === 'undefined') return null
    return data.answer
  }

  render() {
    let sl = this.props.skipLogics;
    if(sl == null) return null;

    let triple_negative = this.getValue('triple_negative');
    let er = this.getValue('er');
    let pr = this.getValue('pr');
    let her2 = this.getValue('her2');
    let ck14 = this.getValue('ck14');
    let ck5_6 = this.getValue('ck5_6');
    let ipsilateral_age = this.getValue('ipsilateral_age');
    let bilateral_age = this.getValue('bilateral_age');
    let lobular = this.getValue('lobular');


    let ipsilateral_label = "";
    if(ipsilateral_age !== null && ipsilateral_age !== 'n') {
      if( !isNaN(parseInt(ipsilateral_age, radix_bases.base10)) && parseInt(ipsilateral_age, radix_bases.base10) === 0) {
        ipsilateral_label = this.yesNoLabel("Ipsilateral", null);
      } else {
        ipsilateral_label = this.yesNoLabel("Ipsilateral", ipsilateral_age);
      }
    }

    let bilateral_label = "";
    if(bilateral_age !== null && bilateral_age !== 'n') {
      if( !isNaN(parseInt(bilateral_age, radix_bases.base10)) && parseInt(bilateral_age, radix_bases.base10) === 0) {
        bilateral_label = this.yesNoLabel("Bilateral", null);
      } else {
        bilateral_label = this.yesNoLabel("Bilateral", bilateral_age);
      }
    }

    return (
      <React.Fragment >
        <p>
          { triple_negative == 'y' && 'Triple-negative (' }

          {this.plusMinusLabel('ER', er)}
          {this.plusMinusLabel('PR', pr)}
          {this.plusMinusLabel('HER2', her2, '')}

          { triple_negative == 'y' && ')' }
        </p>
        <p>
          {this.plusMinusLabel('CK14', ck14)}
          {this.plusMinusLabel('CK5/6', ck5_6)}
        </p>
        <p>
          {ipsilateral_label}
          {bilateral_label}
        </p>
        <p>
          {lobular == 'y' && 'Lobular'}
        </p>
      </React.Fragment>
    )
  }

}

export default SkipLogicBreastView
