import axios from 'axios';
import {set_cookie, remove_cookie, get_cookie} from '../helpers/helper-cookies';
import Cookie from 'js-cookie';
import api_auth from '../api/authentication';
import helper from '../helpers/index';
import lodash, { cloneDeep } from 'lodash';

const set_abort_api = () => {
  if ('famgenix' in window) {
    let famgenix = Object.assign({}, window.famgenix, {abort_api_calls: '1'})
    window.famgenix = famgenix
  }
}

const continue_or_abort_api = () => {
  if ('famgenix' in window) {
    let famgenix = window.famgenix
    if ('abort_api_calls' in famgenix && famgenix.abort_api_calls == '1') {
      throw new Error('All API calls are refused due to invalid tokens')
    }
  }
}

const request = async (method, url, payload = {}, content_type = "", response_type = "", custom_options = {}) => {
  continue_or_abort_api()

  return await doRequest(method, url, payload, content_type, response_type, custom_options);
}

const tokens_to_cookie = (payload) => {
  payload = cloneDeep(payload);

  // parse out the tokens for access to convert and the rest of the tokens can be left as raw text and saved to a new token
  const data_to_convert = {
    token: payload.token,
    // account: payload.account,
    // profile: payload.profile,
    // organization: payload.organization
  };

  delete payload.token;
  // delete payload.account;
  // delete payload.profile;
  // delete payload.organization;

	let tokens = btoa(JSON.stringify(data_to_convert));
  set_cookie('famgenix_app_tokens', tokens);
  sessionStorage.setItem('famgenix_app_tokens', tokens);

  let extra_data = JSON.stringify(payload);
  set_cookie('extra_data', extra_data);
  sessionStorage.setItem('extra_data', extra_data);
};

const tokens_from_cookie = () => {
  let tokens = get_cookie('famgenix_app_tokens');
  let extra_data = get_cookie('extra_data')
  if(tokens === null || typeof (tokens) === 'undefined' || extra_data === null || typeof(extra_data) === 'undefined'){
    tokens = sessionStorage.getItem('famgenix_app_tokens');
    extra_data = sessionStorage.getItem('extra_data');
  }

  if (typeof (tokens) != 'undefined' && tokens != null && typeof(extra_data) !== 'undefined' && extra_data !== null) {
    try {
      let string1 = atob(tokens);
      let decoded = JSON.parse(string1);
      let data = JSON.parse(extra_data);
      data.token = decoded.token;
      // data.account = decoded.account;
      // data.profile = decoded.profile;
      // data.organization = decoded.organization;
      return cloneDeep(data);
    } catch (err) {
      remove_cookie('famgenix_app_tokens');
      remove_cookie('extra_data');
      set_abort_api()
      helper.showPopupSessionError()
      throw new Error('Token is corrupted.')
    }
  }

  throw new Error('Token does not exists.')
};

const get_http_headers = (content_type, custom_options = {}) => {
  let the_headers = {}

  try {

    let decoded = tokens_from_cookie()
    let token = decoded.token.accessToken
    the_headers['Authorization'] = `Bearer ${token}`
    if (content_type != "") the_headers['Content-Type'] = content_type

  } catch (error) {
    console.log(error)
  } finally {
    return the_headers
  }
}

const doUpload = async (url, data, onUploadProgress = null) => {
  let custom_options = {}
  if (onUploadProgress) {
    custom_options['onUploadProgress'] = onUploadProgress
  }
  return await doRequest('post', url, data, "", "", custom_options);
}

const doRequest = async (method, url, payload = {}, content_type = "", response_type = "", custom_options = {}) => {
  var response = null

  let options = {
    // data: qs.stringify(payload)
    method: method,
    url: url,
    validateStatus: function (status) {
      // Make sure 304 status is not treated as error
      if(status == 304) return true

      // The rest should return as default
      return status >= 200 && status < 300; // default
    }
  };

  options['headers'] = get_http_headers(content_type, custom_options)

  if (method === 'get') {
    options['params'] = payload;
  } else {
    options['data'] = payload;
  }

  if (response_type != '') options['responseType'] = response_type;

  if(custom_options && 'signal' in custom_options) {
    options['signal'] = custom_options['signal']
  }

  if(custom_options && 'cancelToken' in custom_options) {
    options['cancelToken'] = custom_options['cancelToken']
  }

  if(custom_options && 'onUploadProgress' in custom_options) {
    options['onUploadProgress'] = (progressEvent) => {
      let percentage = Math.round(( progressEvent.loaded * 100 ) / progressEvent.total )
      custom_options.onUploadProgress(percentage)
    }
  }


  try {
    response = await axios(options);
    return response

  } catch (error) {

    if(error.response && error.response.status === 401){
      api_auth.logoutRemoveSessionStorage();
    }

    if(axios.isCancel(error)) {
      return null
    }

    throw error;

  }
}

const token_error = (error) => {
  // return false;
  try {
    if (error.hasOwnProperty('response')
        && error.response.hasOwnProperty('status')
        && error.response.hasOwnProperty('headers')
        && error.response.headers.hasOwnProperty('www-authenticate')
        && error.response.status === 401) {

        var authenticate_message = error.response.headers['www-authenticate']
        return (authenticate_message.indexOf('The access token has expired.') != -1)
    }

  } catch (error) {
    return false
  }

  return false

}

const parse_error_message = (err) => {
  let message = ""
  let generic_message = 'Oops! Something went wrong. Please contact support.';
  if (err.response && err.response.data) {

    if(err.response.status === 500) {
      message = generic_message
    } else if (err.response.data.message) {
      message = err.response.data.message;
    } else if (err.response.data.description) {
      message =  err.response.data.description;
    } else if (err.response.data.error_description) {
      message =  err.response.data.error_description;
    } else if (err.response.data.detail) {
      message = err.response.data.detail;
    }

    if(err.response.data.non_field_errors) {
      if(err.response.data.non_field_errors[0] === 'The fields member_id, disease_id must make a unique set.') {
        message = "Oops - you cannot add the same disease more than once."
      }
    }

  } else {
    message =  err.message;
  }

  if(message.trim().length == 0) message = generic_message

  if (typeof message !== 'string') return ''
  message = message.toLowerCase()
  message = message.charAt(0).toUpperCase() + message.slice(1)
  message = capitalizeAfterDot(message, true)
  console.dir(message)
  var parsed_error = Error(message)
  parsed_error.api_error = err
  return parsed_error
}

const capitalizeAfterDot = (input, lowercaseBefore) => {
  input = ( input === undefined || input === null ) ? '' : input;
  if (lowercaseBefore) { input = input.toLowerCase(); }
  return input.toString().replace( /(^|\. *)([a-z])/g, function(match, separator, char) {
      return separator + char.toUpperCase();
  });
}

const get_cancel_token_source = async forAPI => {

  var cancelSource = window[forAPI]
  if (typeof(cancelSource) === 'undefined') {
    cancelSource = await axios.CancelToken.source()
  } else {
    await cancelSource.cancel()
    cancelSource = await axios.CancelToken.source()
  }
  window[forAPI] = cancelSource
  return cancelSource
}

export default {
  parse_error_message,
  request,
  tokens_from_cookie,
  tokens_to_cookie,
  get_cancel_token_source,
  doUpload,
}
