import { combineReducers } from 'redux';
import types from "./types";

function diseases(state=[], action) {
  switch(action.type) {
    case types.INITIALIZE_DISEASES:
      return action.payload
    default:
      return state;
  }
}

function umls_diseases(state=[], action) {
  switch(action.type) {
    case types.INITIALIZE_UMLS:
      return action.payload
    default:
      return state;
  }
}
function genetic_testings(state=[], action) {
  switch(action.type) {
    case types.INITIALIZE_GENETIC_TESTINGS:
      return action.payload
    default:
      return state;
  }
}
function genetic_testings_umls(state=[], action) {
  switch(action.type) {
    case types.INITIALIZE_GENETIC_TESTINGS_UMLS:
      return action.payload
    default:
      return state;
  }
}

function ancestry_list(state=[], action) {
  switch(action.type) {
    case types.INITIALIZE_ANCESTRY_LIST:
      return action.payload
    default:
      return state;
  }
}

function skip_logics(state=[], action) {
  switch(action.type) {
    case types.INITIALIZE_SKIP_LOGICS:
      return action.payload
    default:
      return state;
  }
}

function patients_status(state=[], action) {
  switch(action.type) {
    case types.INITIALIZE_PATIENT_STATUS:
      return action.payload
    default:
      return state;
  }
}

function surveys(state=[], action){
  switch(action.type) {
    case types.INITIALIZE_SURVEYS:
      return action.payload
    default:
      return state;
  }
}

function disease_categories(state={}, action){
  switch(action.type) {
    case types.INITIALIZE_DISEASE_CATEGORIES:
      return action.payload
    default:
      return state;
  }
}

export default combineReducers({
  diseases,
  genetic_testings,
  umls_diseases,
  genetic_testings_umls,
  ancestry_list,
  skip_logics,
  patients_status,
  surveys,
  disease_categories,
});

