const top_level_p_member_query_variable = {
    inputSet: {
        variableName: "family",
        fieldName: "members",
        expression_type: "variableField"
    },
    the_Variable: {
        name: "member",
        type: "member",
        expression_type: "variable"
    },
    operator: "Exists",
    expression_type: "expression",
    expressionList: []
}

const p_member_disease_qualifier = {
    operator: "Exists",
    expression_type: "expression",
    the_Variable: {
        name: "d",
        type: "disease",
        expression_type: "variable"
    },
    inputSet: {
        variableName: "member",
        fieldName: "diseases",
        expression_type: "variableField"
    },
    expressionList: []
}

const p_member_gene_qualifier : {operator: string, expression_type: string, the_Variable: {name: string, type: string, expression_type: string}, inputSet: {variableName: string, fieldName: string, expression_type: string}, expressionList: []} = {
    operator: "Exists",
    expression_type: "expression",
    the_Variable: {
        name: "g",
        type: "gene",
        expression_type: "variable"
    },
    inputSet: {
        variableName: "member",
        fieldName: "genes",
        expression_type: "variableField"
    },
    expressionList: []
}

const p_member_referral_qualifier : {operator: string, expression_type: string, the_Variable: {name: string, type: string, expression_type: string}, inputSet: {variableName: string, fieldName: string, expression_type: string}, expressionList: []} = {
    operator: "Exists",
    expression_type: "expression",
    the_Variable: {
        name: "r",
        type: "referralcriteria",
        expression_type: "variable"
    },
    inputSet: {
        variableName: "member",
        fieldName: "referralcriteria",
        expression_type: "variableField"
    },
    expressionList: []
}

const survey_qualifier : {operator: string, expression_type: string, the_Variable: {name: string, type: string, expression_type: string}, inputSet: {variableName: string, fieldName: string, expression_type: string}, expressionList: []} = {
    operator: "Exists",
    expression_type: "expression",
    the_Variable: {
        name: "s",
        type: "survey",
        expression_type: "variable"
    },
    inputSet: {
        variableName: "member",
        fieldName: "surveys",
        expression_type: "variableField"
    },
    expressionList: []
}

const disease_filter_item = [
    {
        operator: "=",
        expression_type: "expression",
        expressionList: [
            {
                variableName: "d",
                fieldName: "",
                expression_type: "variableField"
            },
            {
                value: "",
                expression_type: "value"
            }
        ]
    },
    {
        operator: "", //= >= <= etc
        expression_type: "expression",
        expressionList: [
            {
                variableName: "d",
                fieldName: "age_diagnosed",
                expression_type: "variableField"
            },
            {
                value: 0,
                expression_type: "value"
            }
        ]
    }
]

const gene_filter_item = [
    {
        operator: "=",
        expression_type: "expression",
        expressionList: [
            {
                variableName: "g",
                fieldName: "", // either gene_id or umls_id
                expression_type: "variableField"
            },
            {
                value: "",
                expression_type: "value"
            }
        ]
    },
    {
        operator: "=", //= >= <= etc
        expression_type: "expression",
        expressionList: [
            {
                variableName: "g",
                fieldName: "result",
                expression_type: "variableField"
            },
            {
                value: "",
                expression_type: "value"
            }
        ]
    },
    {
        operator: "=", //= >= <= etc
        expression_type: "expression",
        expressionList: [
            {
                variableName: "g",
                fieldName: "variant",
                expression_type: "variableField"
            },
            {
                value: "",
                expression_type: "value"
            }
        ]
    }
]

const referral_criteria_filter_item = {
    operator: "in",
    expression_type: "expression",
    expressionList: [
        {
            value: "",
            expression_type: "value"
        },
        {
            variableName: "r",
            fieldName: "condition_name",
            expression_type: "variableField"
        }
    ]
}

const survey_filter_item = [
    {
        operator: "Exists",
        expression_type: "expression",
        the_Variable: {
            name: "a",
            type: "answer",
            expression_type: "variable"
        },
        inputSet: {
            variableName: "member",
            fieldName: "survey_answers",
            expression_type: "variableField"
        },
        expressionList: [
            {
                operator: "and",
                expression_type: "expression",
                expressionList: [
                    {
                        operator: "=",
                        expression_type: "expression",
                        expressionList: [
                            {
                                variableName: "a",
                                fieldName: "question_uuid",
                                expression_type: "variableField"
                            },
                            {
                                value: "",
                                expression_type: "value"
                            }
                        ]
                    },
                    {
                        operator: "or",
                        expression_type: "expression",
                        expressionList: [
                            // survey filter input
                        ]
                    }
                ]
            }
        ]
    }
]

const survey_filter_item_input = {
    operator: "=",
    expression_type: "expression",
    expressionList: [
        {
            variableName: "a",
            fieldName: "",
            expression_type: "variableField"
        },
        {
            value: "",
            expression_type: "value"
        }
    ]
}


const filter = {
    operator: "", //and or
    expression_type: "expression",
    expressionList: []
}

let multiple_filter_set_level = {
    operator: "", // and or
    expression_type: "expression",
    expressionList: [
    ]
}

const Count_condition = {
    operator: "",
    expression_type: "expression",
    expressionList: [
        {
            operator: "Count",
            expression_type: "expression",
            expressionList: [
                {
                    variableName: "member",
                    fieldName: "",
                    expression_type: "variableField"
                }
            ]
        },
        {
            value: 0,
            expression_type: "value"
        }
    ]
}

const Count_condition_with_filter = {
    operator: "",
    expression_type: "expression",
    expressionList: [
        {
            operator: "Count",
            expression_type: "expression",
            expressionList: [
                {
                    operator: "Filter",
                    expression_type: "expression",
                    the_Variable: {
                        name: "d",
                        type: "disease",
                        expression_type: "variable"
                    },
                    inputSet: {
                        variableName: "member",
                        fieldName: "diseases",
                        expression_type: "variableField"
                    },
                    expressionList: [
                        {
                            operator: "=",
                            expression_type: "expression",
                            expressionList: [
                                {
                                    variableName: "d",
                                    fieldName: "code",
                                    expression_type: "variableField"
                                },
                                {
                                    value: "",
                                    expression_type: "value"
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            value: 0,
            expression_type: "value"
        }
    ]
}

const Count_condition_subconcept = {
    operator: "",
    expression_type: "expression",
    expressionList: [
        {
            operator: "Count",
            expression_type: "expression",
            expressionList: [
                {
                    operator: "Filter",
                    expression_type: "expression",
                    the_Variable: {
                        name: "d",
                        type: "disease",
                        expression_type: "variable"
                    },
                    inputSet: {
                        variableName: "member",
                        fieldName: "diseases",
                        expression_type: "variableField"
                    },
                    expressionList: [
                        {
                            operator: "SubConcept",
                            expression_type: "expression",
                            expressionList: [
                                {
                                    variableName: "d",
                                    fieldName: "umls_id",
                                    expression_type: "variableField"
                                },
                                {
                                    value: "", // umls id
                                    expression_type: "value"
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            value: 0,
            expression_type: "value"
        }
    ]
}

const Count_condition_or_subconcept = {
    operator: "",
    expression_type: "expression",
    expressionList: [
        {
            operator: "Count",
            expression_type: "expression",
            expressionList: [
                {
                    operator: "Filter",
                    expression_type: "expression",
                    the_Variable: {
                        name: "dise",
                        type: "disease",
                        expression_type: "variable"
                    },
                    inputSet: {
                        variableName: "member",
                        fieldName: "diseases",
                        expression_type: "variableField"
                    },
                    expressionList: [
                        {
                            operator: "or",
                            expression_type: "expression",
                            expressionList: []
                        }
                    ]
                }
            ]
        },
        {
            value: 0,
            expression_type: "value"
        }
    ]
}



const Count_condition_for_family_search = {
    operator: "",
    expression_type: "expression",
    expressionList: [
        {
            operator: "Count",
            expression_type: "expression",
            expressionList: [
                {
                    operator: "Filter",
                    expression_type: "expression",
                    the_Variable: {
                        name: "member",
                        type: "member",
                        expression_type: "variable"
                    },
                    inputSet: {
                        variableName: "family",
                        fieldName: "members",
                        expression_type: "variableField"
                    },
                    expressionList: [
                    ]
                }
            ]
        },
        {
            value: 0,
            expression_type: "value"
        }
    ]
}

const family_relationship_filter = {
    operator: "=",
    expression_type: "expression",
    expressionList: [
        {
            variableName: "member",
            fieldName: "", //is_blood_related_to_proband or side
            expression_type: "variableField"
        },
        {
            value: true,
            expression_type: "value"
        }
    ]
}

const maternal_only_filter = {
    operator: "or",
    expression_type: "expression",
    expressionList: [
        {
            operator: "=",
            expression_type: "expression",
            expressionList: [
                {
                    variableName: "member",
                    fieldName: "side",
                    expression_type: "variableField"
                },
                {
                    value: "maternal",
                    expression_type: "value"
                }
            ]
        },
        {
            operator: "=",
            expression_type: "expression",
            expressionList: [
                {
                    variableName: "member",
                    fieldName: "side",
                    expression_type: "variableField"
                },
                {
                    value: "both",
                    expression_type: "value"
                }
            ]
        }
    ]
}

const paternal_only_filter = {
    operator: "or",
    expression_type: "expression",
    expressionList: [
        {
            operator: "=",
            expression_type: "expression",
            expressionList: [
                {
                    variableName: "member",
                    fieldName: "side",
                    expression_type: "variableField"
                },
                {
                    value: "paternal",
                    expression_type: "value"
                }
            ]
        },
        {
            operator: "=",
            expression_type: "expression",
            expressionList: [
                {
                    variableName: "member",
                    fieldName: "side",
                    expression_type: "variableField"
                },
                {
                    value: "both",
                    expression_type: "value"
                }
            ]
        }
    ]
}

const same_side_of_the_family_filter = {
    operator: "or",
    expression_type: "expression",
    expressionList: [
        {
            operator: "",
            expression_type: "expression",
            expressionList: [
                {
                    operator: "Count",
                    expression_type: "expression",
                    expressionList: [
                        {
                            operator: "Filter",
                            expression_type: "expression",
                            the_Variable: {
                                name: "x",
                                type: "member",
                                expression_type: "variable"
                            },
                            inputSet: {
                                variableName: "family",
                                fieldName: "members",
                                expression_type: "variableField"
                            },
                            expressionList: [
                                {
                                    operator: "and",
                                    expression_type: "expression",
                                    expressionList: [
                                        {
                                            operator: "and",
                                            expression_type: "expression",
                                            expressionList: [
                                                {
                                                    operator: "=",
                                                    expression_type: "expression",
                                                    expressionList: [
                                                        {
                                                            variableName: "x",
                                                            fieldName: "is_blood_related_to_proband",
                                                            expression_type: "variableField"
                                                        },
                                                        {
                                                            value: true,
                                                            expression_type: "value"
                                                        }
                                                    ]
                                                },
                                                {
                                                    operator: "or",
                                                    expression_type: "expression",
                                                    expressionList: [
                                                        {
                                                            operator: "=",
                                                            expression_type: "expression",
                                                            expressionList: [
                                                                {
                                                                    variableName: "x",
                                                                    fieldName: "side",
                                                                    expression_type: "variableField"
                                                                },
                                                                {
                                                                    value: "paternal",
                                                                    expression_type: "value"
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            operator: "=",
                                                            expression_type: "expression",
                                                            expressionList: [
                                                                {
                                                                    variableName: "x",
                                                                    fieldName: "side",
                                                                    expression_type: "variableField"
                                                                },
                                                                {
                                                                    value: "both",
                                                                    expression_type: "value"
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                            ]
                                        },
                                        {
                                            operator: "and",
                                            expression_type: "expression",
                                            expressionList: []
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    value: 0,
                    expression_type: "value"
                }
            ]
        },
        {
            operator: "",
            expression_type: "expression",
            expressionList: [
                {
                    operator: "Count",
                    expression_type: "expression",
                    expressionList: [
                        {
                            operator: "Filter",
                            expression_type: "expression",
                            the_Variable: {
                                name: "y",
                                type: "member",
                                expression_type: "variable"
                            },
                            inputSet: {
                                variableName: "family",
                                fieldName: "members",
                                expression_type: "variableField"
                            },
                            expressionList: [
                                {
                                    operator: "and",
                                    expression_type: "expression",
                                    expressionList: [
                                        {
                                            operator: "and",
                                            expression_type: "expression",
                                            expressionList: [
                                                {
                                                    operator: "=",
                                                    expression_type: "expression",
                                                    expressionList: [
                                                        {
                                                            variableName: "y",
                                                            fieldName: "is_blood_related_to_proband",
                                                            expression_type: "variableField"
                                                        },
                                                        {
                                                            value: true,
                                                            expression_type: "value"
                                                        }
                                                    ]
                                                },
                                                {
                                                    operator: "or",
                                                    expression_type: "expression",
                                                    expressionList: [
                                                        {
                                                            operator: "=",
                                                            expression_type: "expression",
                                                            expressionList: [
                                                                {
                                                                    variableName: "y",
                                                                    fieldName: "side",
                                                                    expression_type: "variableField"
                                                                },
                                                                {
                                                                    value: "maternal",
                                                                    expression_type: "value"
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            operator: "=",
                                                            expression_type: "expression",
                                                            expressionList: [
                                                                {
                                                                    variableName: "y",
                                                                    fieldName: "side",
                                                                    expression_type: "variableField"
                                                                },
                                                                {
                                                                    value: "both",
                                                                    expression_type: "value"
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                            ]
                                        },
                                        {
                                            operator: "and",
                                            expression_type: "expression",
                                            expressionList: []
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    value: 0,
                    expression_type: "value"
                }
            ]
        }
    ]
}


const Subconcept_operator = {
    operator: "SubConcept",
    expression_type: "expression",
    expressionList: [
        {
            variableName: "dise",
            fieldName: "umls_id",
            expression_type: "variableField"
        },
        {
            value: "", // umls id
            expression_type: "value"
        }
    ]
}

const Subconcept_or_code_operator = {
    operator: "or",
    expression_type: "expression",
    expressionList: [
        {
            operator: "=",
            expression_type: "expression",
            expressionList: [
                {
                    variableName: "dise",
                    fieldName: "code",
                    expression_type: "variableField"
                },
                {
                    value: "",
                    expression_type: "value"
                }
            ]
        },
        {
            operator: "SubConcept",
            expression_type: "expression",
            expressionList: [
                {
                    variableName: "dise",
                    fieldName: "umls_id",
                    expression_type: "variableField"
                },
                {
                    value: "", // umls id
                    expression_type: "value"
                }
            ]
        }
    ]
}

const member_name_filter = {
    operator: "or",
    expression_type: "expression",
    expressionList: [
        {
            operator: "in",
            expression_type: "expression",
            expressionList: [
                {
                    value: "",
                    expression_type: "value"
                },
                {
                    variableName: "member",
                    fieldName: "patient_id",
                    expression_type: "variableField"
                }
            ]
        }
    ]
}

let member_dob_filter = {
    operator: "",
    expression_type: "expression",
    expressionList: [
        {
            variableName: "member",
            fieldName: "dob",
            expression_type: "variableField"
        },
        {
            value: "",
            expression_type: "value"
        }
    ]
}

let member_dob_between_filter = {
    operator: "and",
    expression_type: "expression",
    expressionList: [
        {
            operator: ">=",
            expression_type: "expression",
            expressionList: [
                {
                    variableName: "member",
                    fieldName: "dob",
                    expression_type: "variableField"
                },
                {
                    value: "", //age from
                    expression_type: "value"
                }
            ]
        },
        {
            operator: "<=",
            expression_type: "expression",
            expressionList: [
                {
                    variableName: "member",
                    fieldName: "dob",
                    expression_type: "variableField"
                },
                {
                    value: "", //age to
                    expression_type: "value"
                }
            ]
        }
    ]
}

let member_age_filter = {
    operator: "",
    expression_type: "expression",
    expressionList: [
        {
            variableName: "member",
            fieldName: "age",
            expression_type: "variableField"
        },
        {
            value: "",
            expression_type: "value"
        }
    ]
}

let member_age_between_filter = {
    operator: "and",
    expression_type: "expression",
    expressionList: [
        {
            operator: ">=",
            expression_type: "expression",
            expressionList: [
                {
                    variableName: "member",
                    fieldName: "age",
                    expression_type: "variableField"
                },
                {
                    value: "", //age from
                    expression_type: "value"
                }
            ]
        },
        {
            operator: "<=",
            expression_type: "expression",
            expressionList: [
                {
                    variableName: "member",
                    fieldName: "age",
                    expression_type: "variableField"
                },
                {
                    value: "", //age to
                    expression_type: "value"
                }
            ]
        }
    ]
}

let member_gender_filter = {
    operator: "=",
    expression_type: "expression",
    expressionList: [
        {
            variableName: "member",
            fieldName: "gender",
            expression_type: "variableField"
        },
        {
            value: "",
            expression_type: "value"
        }
    ]
}

let clinician_filter = {
    operator: "Exists",
    expression_type: "expression",
    the_Variable: {
        name: "c",
        type: "clinician",
        expression_type: "variable"
    },
    inputSet: {
        variableName: "family",
        fieldName: "clinicians",
        expression_type: "variableField"
    },
    expressionList: [
        {
            operator: "=",
            expression_type: "expression",
            expressionList: [
                {
                    variableName: "c",
                    fieldName: "value",
                    expression_type: "variableField"
                },
                {
                    value: "",
                    expression_type: "value"
                }
            ]
        }
    ]
}

let status_filter = {
    operator: "=",
    expression_type: "expression",
    expressionList: [
        {
            variableName: "family",
            fieldName: "onboarding_status",
            expression_type: "variableField"
        },
        {
            value: "",
            expression_type: "value"
        }
    ]
}

const Not_filter = {
    "operator": "Not",
    "expression_type": "expression",
    "expressionList": [
    ]
}

export default {
    top_level_p_member_query_variable,
    p_member_disease_qualifier,
    p_member_gene_qualifier,
    p_member_referral_qualifier,
    survey_qualifier,
    disease_filter_item,
    gene_filter_item,
    referral_criteria_filter_item,
    survey_filter_item,
    filter,
    multiple_filter_set_level,
    Count_condition,
    Count_condition_for_family_search,
    Count_condition_or_subconcept,
    Count_condition_subconcept,
    Count_condition_with_filter,
    family_relationship_filter,
    maternal_only_filter,
    paternal_only_filter,
    same_side_of_the_family_filter,
    Subconcept_operator,
    Subconcept_or_code_operator,
    member_name_filter,
    member_age_filter,
    member_age_between_filter,
    member_gender_filter,
    member_dob_filter,
    member_dob_between_filter,
    clinician_filter,
    status_filter,
    survey_filter_item_input
}
