import React, { Component } from 'react';
import { connect } from "react-redux";

import helpers from '../helpers/index';
import route_helper from '../route-helper';
import * as helper_family_tree from '../helpers/helper-family-tree';

import NavTop from '../components/nav-top';
// import FamilyTreeProband from '../components/family-tree/proband';
import FamilyTreeProbandSiblings from '../components/family-tree/proband-siblings';
import FamilyTreeProbandUnclesAunts from '../components/family-tree/proband-uncles-aunts';
import ModalAddFamilyMember from '../components/modal-add-family-member';
import ErrorSummary from '../components/error-summary'
import ActivityIndicator from '../components/activity-indicator'

import { cloneDeep } from 'lodash'
import family_api from '../api/family-api'

// import * as patient_actions from '../store/patient/actions';
// import family_api from '../api/family-api'


class FamilyTreePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openModalAddFamilyMember: false,
      modalAddFamilyMemberHash:  new Date().getTime(),
      errorMessages: [],
      loading: false,
    };

    this.deleteMember = this.deleteMember.bind(this);
  }

  async componentDidMount() {

    try {

      helpers.setSiteTitle('Family Members');

      this.setState({loading: true})

      await helper_family_tree.populateProband(this.props.dispatch, this.props.match.params.member_id)

      if(!this.hasMembers()) {
        this.setState({
          openModalAddFamilyMember: true,
          modalAddFamilyMemberHash: new Date().getTime()
        });
      }

    } catch (error) {
      console.log(error)
    } finally {
      this.setState({loading: false})
    }

  }

  hasMembers() {
    let members = this.props.patient;

    // Recent changes (maybe pedigree?) caused parents to count each other
    // as partners, and one grandparent counts the other as a partner.
    // To ensure modal only displays on new patients, we check for >4 partners

    if(Object.keys(members.partners).length > 4) {
      return true;
    } else {
      for(var key in members.partners) {
        if(members.partners[key].length > 1) return true;
      }
    }

    if(members.siblings.length > 0) return true;

    if(members.uncles_aunts.paternal.length > 0) return true;

    if(members.uncles_aunts.maternal.length > 0) return true;

    return false;
  }

  getProbandPartnersCount() {
    if (!('proband' in this.props.patient.partners)) return 0;

    return this.props.patient.partners.proband.length;
  }

  getSiblingsCount(gender='M') {
    return this.props.patient.siblings.filter(i => i.gender === gender).length;
  }

  getUnclesAuntsCount(parent_side='paternal', gender='M') {
    let uncles_aunts = this.props.patient.uncles_aunts[parent_side];
    return uncles_aunts.filter(i => i.gender === gender).length;
  }

  onClickOpenModalAddFamilyMember() {
    this.setState({
      openModalAddFamilyMember: true,
      modalAddFamilyMemberHash: new Date().getTime()
    });
  }

  async onSaveAddFamilyMember(familyMembers) {
    try {
      let proband = this.props.patient.patient

      this.setState({loading: true, errorMessages: []})
      this.setState({familyMembers: familyMembers, openModalAddFamilyMember: false});

      // Siblings
      let options = {
        family_id: proband.family_id,
        father_id: proband.father.id,
        mother_id: proband.mother.id
      };
      await helper_family_tree.populateSiblingsByCount(this.props.dispatch, 'm', familyMembers.brothers, options);
      await helper_family_tree.populateSiblingsByCount(this.props.dispatch, 'f', familyMembers.sisters, options);
      await helper_family_tree.populateSiblingsByCount(this.props.dispatch, 'u', familyMembers.unknown, options);


      // Paternal Uncles and Aunts
      options = {
        family_id: proband.family_id,
        father_id: proband.paternal_grandfather.id,
        mother_id: proband.paternal_grandmother.id
      };
      await helper_family_tree.populateUnclesAuntsByCount(this.props.dispatch, 'paternal', 'm', familyMembers.paternal_uncles, options);
      await helper_family_tree.populateUnclesAuntsByCount(this.props.dispatch, 'paternal', 'f', familyMembers.paternal_aunts, options);

      // Maternal Uncles and Aunts
      options = {
        family_id: proband.family_id,
        father_id: proband.maternal_grandfather.id,
        mother_id: proband.maternal_grandmother.id
      };
      await helper_family_tree.populateUnclesAuntsByCount(this.props.dispatch, 'maternal', 'm', familyMembers.maternal_uncles, options);
      await helper_family_tree.populateUnclesAuntsByCount(this.props.dispatch, 'maternal', 'f', familyMembers.maternal_aunts, options);

      // reload the redux tree so the correct data is in place
      await helper_family_tree.populateProband(this.props.dispatch, this.props.match.params.member_id)
    } catch (error) {
      helpers.logger(error)
      this.setState({ errorMessages: [error.message]})
    } finally {
      this.setState({loading: false})
    }

  }

  onClickSave() {
    let member_id = this.props.match.params.member_id;
    let url = route_helper.family.family_history.replace(':member_id', member_id);
    this.props.history.push(url);
  }

  onClickOpenModalTwin() {
    this.setState({
      openModalTwin: true,
      modalTwinHash: new Date().getTime()
    });
  }

  async deleteMember(profile){
    try {
      console.log(this.props.patient)

      const deletable_members_map = await family_api.can_delete_member(profile.id);
      if (deletable_members_map.member_ids.length === 0 && deletable_members_map.message !== "") {
        const error = {
          message: deletable_members_map.message,
          type: ""
        };
        this.setState({ errorMessages: [error.message] });
      }

      // make a map of the delete ids
      const deletable_member_ids_map = {}
      for (let z=0; z<deletable_members_map.member_ids.length; z++) {
        deletable_member_ids_map[deletable_members_map.member_ids[z]] = deletable_members_map.member_ids[z];
      }

      // loop through and get any children of the memebrs to be deleted and set their mother_id and father_id to null
      let temp_profiles = cloneDeep(Object.values(this.props.patient.profile));
      for (let z=0; z<temp_profiles.length; z++) {
        let temp_profile = temp_profiles[z];
        if (temp_profile.mother_id && temp_profile.father_id) {
          if ( (temp_profile.mother_id in deletable_member_ids_map) ||
              (temp_profile.father_id in deletable_member_ids_map)) {
                temp_profile.mother_id = null;
                temp_profile.mother_id_id = null;
                temp_profile.father_id = null;
                temp_profile.father_id_id = null;
                helper_family_tree.saveProfileToRedux(this.props.dispatch, temp_profile);
              }
        }
      }

      // loop through and remove the person from their partners partner array and relationship_ids array
      // relationship_data should also be reset if it matches the id
      temp_profiles = cloneDeep(Object.values(this.props.patient.profile));
      for (let z=0; z<temp_profiles.length; z++) {
        let changed = false;
        let temp_profile = temp_profiles[z];
        let partners = temp_profile.partners;
        let relationship_ids = temp_profile.relationship_ids;

        if (Array.isArray(partners)) {
          const new_partners = [];
          for (let l=0; l<partners.length; l++) {
            if (partners[l].id in deletable_member_ids_map) {
              continue;
            } else {
              new_partners.push(partners[l]);
            }
          }

          if (new_partners.length !== partners.length) {
            temp_profile.partners = new_partners;
            changed = true;
          }
        }

        if (Array.isArray(relationship_ids)) {
          const new_relationships = [];
          for (let l=0; l<relationship_ids.length; l++) {
            if (relationship_ids[l].mother_id in deletable_member_ids_map || relationship_ids[l].father_id in deletable_member_ids_map) {
              continue;
            } else {
              new_relationships.push(relationship_ids[l]);
            }
          }

          if (new_relationships.length !== relationship_ids.length) {
            temp_profile.relationship_ids = new_relationships;
            changed = true;
          }
        }

        if (temp_profile.relationship_data &&
          (temp_profile.relationship_data.mother_id in deletable_member_ids_map || temp_profile.relationship_data.father_id in deletable_member_ids_map)) {
            delete temp_profile.relationship_data;
        }

        if (changed) {
          helper_family_tree.saveProfileToRedux(this.props.dispatch, temp_profile);
        }
      }

      // hit bulk delete endpoint
      await family_api.bulk_delete_members(profile.id, deletable_members_map.member_ids);

      if (profile.twin_id_id) {
        let people = cloneDeep(Object.values(this.props.patient.profile));
        const twins = [];
        for (let i=0; i<people.length; i++) {
          if (people[i].twin_id_id === profile.twin_id_id) twins.push(people[i]);
        }

        if (twins.length === 1) {
          // delete the twin entry and clear twin_id and twin_set
          // if there is only one twin left after deletion

          /* Note: deletion of twin record is done within member deletion if
          ** only one twin is left, no need to manually do it
          */
          // await this.deleteTwinsEntry(profile);

          twins[0].twin_id_id = null;
          twins[0].twin_id = null;
          twins[0].twin_set = null;
          twins[0].twin_type = null;
          helper_family_tree.saveProfileToRedux(this.props.dispatch, twins[0]);
        }
      }

      this.setState({
        openModalConfirmDelete: false,
        errorMessages: []
      });

    } catch (error) {
      console.log(error);
      this.setState({ errorMessages: [error.message] });
    }
  }

  render() {

    return (
      <React.Fragment>

        <NavTop history={this.props.history} />

        <section className="wow fadeIn section">
          <div className="container">
            <div className="section-header">
              <div className="row vcenter">
                <div className="col-md-6 col-xs-12">
                  <h2>Family Members</h2>
                </div>
                <div className="col-md-4 col-md-offset-2  col-xs-12 justify-right">

                  <button
                    onClick={() => this.onClickOpenModalAddFamilyMember()}
                    disabled={this.state.loading}
                    className={"btn btn-teal btn-sm " + (this.state.loading ? 'disabled' : '')} ><i className="fa fa-plus text-white"></i>Add Family Member</button>

                </div>
              </div>
            </div>

            <ErrorSummary errorMessages={this.state.errorMessages} />

            <div className="row">
              <div className="col-md-12">
                <table className="table table-custom responsive no-wrap margin-one sticky-hdr">
                  <thead>
                      <tr>
                        <th></th>
                        <th>Relationship</th>
                        <th>First Name</th>
                        <th>Children</th>
                        <th>Twin</th>
                        <th>Adopted</th>
                        <th>Half Sibling</th>
                        <th>Remove</th>
                      </tr>
                  </thead>
                  <tbody>

                    <FamilyTreeProbandSiblings
                      patient={this.props.patient}
                      dispatch={this.props.dispatch}
                      proband_siblings={this.props.patient.siblings}
                      deleteMember={this.deleteMember}
                    />

                    <FamilyTreeProbandUnclesAunts
                      parent_side="paternal"
                      uncles_aunts={this.props.patient.uncles_aunts.paternal}
                      patient={this.props.patient}
                      dispatch={this.props.dispatch}
                      deleteMember={this.deleteMember}
                    />

                    <FamilyTreeProbandUnclesAunts
                      parent_side="maternal"
                      uncles_aunts={this.props.patient.uncles_aunts.maternal}
                      patient={this.props.patient}
                      dispatch={this.props.dispatch}
                      deleteMember={this.deleteMember}
                    />
                  </tbody>
                  <tfoot className="hidden">
                    <tr>
                      <th>Relationship</th>
                      <th>First Name</th>
                      <th>Add Partner with Child</th>
                      <th>Twin</th>
                      <th>Adopted</th>
                      <th>Half Sibling</th>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div className="section-footer">
                <hr />

                <button
                  onClick={() => this.onClickSave()}
                  disabled={this.state.loading}
                  className={"btn btn-dark btn-sm " + (this.state.loading ? 'disabled' : '')} ><i className="fa fa-arrow-right text-white"></i>Save and Continue</button>

                <button
                  onClick={() => {
                    let url = route_helper.patient.patient_information.replace(':member_id', this.props.match.params.member_id);
                    this.props.history.push(url)
                  }}
                  className="btn btn-light-outline btn-sm" data-toggle="modal" data-target="#AddFamilyMember">
                      <i className="fa fa-arrow-left text-white"></i>
                      Patient Information
                </button>

                  <ActivityIndicator
                    modal={true}
                    loading={this.state.loading}
                  />

              </div>

            </div>
          </div>
        </section>



        <ModalAddFamilyMember
          key={this.state.modalAddFamilyMemberHash}
          familyMembers={this.state.familyMembers}
          isOpen={this.state.openModalAddFamilyMember}
          onClose={() => this.setState({openModalAddFamilyMember: false})}
          onClickSave={(updatedFamilyMembers) => this.onSaveAddFamilyMember(updatedFamilyMembers)}
        />

        {/* {this.state.openModalTwin && (
          <ModalTwins
            key={this.state.modalTwinHash}
            twinFor="siblings"
            maleLabel="Brother"
            femaleLabel="Sister"
            fatherId={this.props.patient.patient.father.id}
            motherId={this.props.patient.patient.mother.id}
            familyId={this.props.patient.patient.familyId}
            dispatch={this.props.dispatch}
            patient={this.props.patient}
            onClose={() => this.setState({ openModalTwin: false })}
          />
        )} */}


      </React.Fragment>
    );
  }
}

const redux_state = state => ({
  patient: state.patient
});

const redux_actions = dispatch => ({
  dispatch: (action) => dispatch(action)
});

export default connect(redux_state, redux_actions)(FamilyTreePage);
