import React, { Component } from 'react';
import ReactDOM from 'react-dom';

class ModalDeleteDocument extends Component {

  constructor(props) {
    super(props);
    this.onClickDelete = this.onClickDelete.bind(this);
  }

  onClickDelete(event) {
    // set an rkey to identify the document
    let deleteKey = this.props.documentEditing.rkey;
    let payload = { deleteKey };
    this.props.onClickDelete(payload);
    this.props.onClose();
  }

  render() {
    return ReactDOM.createPortal(
      <div onClick={() => this.props.onClose()}
        style={{ display: 'block' }}
        className="modal fade in"
        role="dialog"
        aria-labelledby="delete-doc"
        id="delete-doc">

        <div onClick={(e) => e.stopPropagation()} className="modal-dialog modal-sm" role="document">
          <div className="modal-content">

            <div className="modal-header">
              <button onClick={() => this.props.onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="fa fa-close"></i>
              </button>
              <h4 className="modal-title text-white text-capitalize">Delete Document</h4>
            </div>

            <div className="modal-body">
              <div className="modal-confirm">
                <h3>Are you sure you want to delete this document?</h3>
                <div className="buttons">
                  <button onClick={this.onClickDelete} className="btn btn-purple btn-sm">Yes</button>
                  <button onClick={() => this.props.onClose()} className="btn btn-light btn-sm" data-dismiss="modal" aria-label="Close">No</button>
                </div>
              </div>
            </div>

            <div className="modal-footer">
            </div>
          </div>
        </div>
      </div>,
      document.body
    );
  }

}

export default ModalDeleteDocument;
