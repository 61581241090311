import React, { Component } from "react";

import ModalTwins from "../modal-twins";

import FamilyTreeUncleAunt from "./uncle-aunt";

class FamilyTreeProbandUnclesAunts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalTwin: false,
      modalTwinHash: new Date().getTime(),
      selected_uncle_aunt: null
    };
    this.onClickOpenModalTwin = this.onClickOpenModalTwin.bind(this);
  }

  onClickOpenModalTwin(uncle_aunt_id) {
    this.setState({
      openModalTwin: true,
      modalTwinHash: new Date().getTime(),
      selected_uncle_aunt: uncle_aunt_id
    });
  }

  getFatherId() {
    return this.props.patient.patient[this.props.parent_side + "_grandfather"]
      .id;
  }

  getMotherId() {
    return this.props.patient.patient[this.props.parent_side + "_grandmother"]
      .id;
  }

  render() {

    for(let u_a of this.props.uncles_aunts) {
      u_a.gender = (u_a.gender == null) ? 'u' : u_a.gender
    }

    return (
      <React.Fragment>
        {this.state.openModalTwin && (
          <ModalTwins
            selected_uncle_aunt_id={this.state.selected_uncle_aunt}
            key={this.state.modalTwinHash}
            twinFor="uncles_aunts"
            ownerRkey="proband"
            parent_side={this.props.parent_side}
            maleLabel="Uncle"
            femaleLabel="Aunt"
            otherLabel="Aunt/Uncle"
            dispatch={this.props.dispatch}
            patient={this.props.patient}
            fatherId={this.getFatherId()}
            motherId={this.getMotherId()}
            familyId={this.props.patient.patient.familyId}
            isOpen={this.state.openModalTwin}
            onClose={() => this.setState({ openModalTwin: false })}
          />
        )}

        {this.props.uncles_aunts
          .filter(i => i.gender_identity ? i.gender_identity == 'male' && i.half == true : i.gender.toUpperCase() === "M" && i.half === true)
          .map((uncle_aunt, index) => {
            var label = this.props.parent_side.charAt(0).toUpperCase() + this.props.parent_side.slice(1).toLowerCase() + ' Half-Uncle #' + (index + 1)
            if (uncle_aunt.gender_identity) {
              if (uncle_aunt.gender && uncle_aunt.gender.toUpperCase() == 'F') {
                label = label + ' (AFAB)'
              } else if (uncle_aunt.gender && uncle_aunt.gender.toUpperCase() == 'U') {
                label = label + ' (AUAB)'
              }
            }
            return (
              <FamilyTreeUncleAunt
                key={uncle_aunt.rkey}
                parent_side={this.props.parent_side}
                collapsed={true}
                label={label}
                uncle_aunt={uncle_aunt}
                patient={this.props.patient}
                dispatch={this.props.dispatch}
                onClickTwin={this.onClickOpenModalTwin}
                fatherId={this.getFatherId()}
                motherId={this.getMotherId()}
                profiles={this.props.patient.profile}
                deleteMember={this.props.deleteMember}
              />
            );
          })}

        {this.props.uncles_aunts
          .filter(i => i.gender_identity ? i.gender_identity == 'female' && i.half == true : i.gender.toUpperCase() === "F" && i.half === true)
          .map((uncle_aunt, index) => {
            var label = this.props.parent_side.charAt(0).toUpperCase() + this.props.parent_side.slice(1).toLowerCase() + ' Half-Aunt #' + (index + 1)
            if (uncle_aunt.gender_identity) {
              if (uncle_aunt.gender && uncle_aunt.gender.toUpperCase() == 'M') {
                label = label + ' (AMAB)'
              } else if (uncle_aunt.gender && uncle_aunt.gender.toUpperCase() == 'U') {
                label = label + ' (AUAB)'
              }
            }
            return (
              <FamilyTreeUncleAunt
                key={uncle_aunt.rkey}
                parent_side={this.props.parent_side}
                collapsed={true}
                label={label}
                uncle_aunt={uncle_aunt}
                patient={this.props.patient}
                dispatch={this.props.dispatch}
                onClickTwin={this.onClickOpenModalTwin}
                fatherId={this.getFatherId()}
                motherId={this.getMotherId()}
                profiles={this.props.patient.profile}
                deleteMember={this.props.deleteMember}
              />
            );
          })}

        {this.props.uncles_aunts
          .filter(i => i.gender_identity ? i.gender_identity == 'non-binary' && i.half == true : i.gender.toUpperCase() === 'U' && i.half === true)
          .map((uncle_aunt, index) => {
            var label = this.props.parent_side.charAt(0).toUpperCase() + this.props.parent_side.slice(1).toLowerCase() + ' Half-Aunt/Uncle #' + (index + 1)
            if (uncle_aunt.gender_identity) {
              if (uncle_aunt.gender && uncle_aunt.gender.toUpperCase() == 'M') {
                label = label + ' (AMAB)'
              } else if (uncle_aunt.gender && uncle_aunt.gender.toUpperCase() == 'F') {
                label = label + ' (AFAB)'
              }
            }
            return (
              <FamilyTreeUncleAunt
                key={uncle_aunt.rkey}
                parent_side={this.props.parent_side}
                collapsed={true}
                label={label}
                uncle_aunt={uncle_aunt}
                patient={this.props.patient}
                dispatch={this.props.dispatch}
                onClickTwin={this.onClickOpenModalTwin}
                fatherId={this.getFatherId()}
                motherId={this.getMotherId()}
                profiles={this.props.patient.profile}
                deleteMember={this.props.deleteMember}
              />
            );
          })}

        {this.props.uncles_aunts
          .filter(i => i.gender_identity ? i.gender_identity == 'male' && i.half == false : i.gender.toUpperCase() === "M" && i.half === false)
          .map((uncle_aunt, index) => {
            var label = this.props.parent_side.charAt(0).toUpperCase() + this.props.parent_side.slice(1).toLowerCase() + ' Uncle #' + (index + 1)
            if (uncle_aunt.gender_identity) {
              if (uncle_aunt.gender && uncle_aunt.gender.toUpperCase() == 'U') {
                label = label + ' (AUAB)'
              } else if (uncle_aunt.gender && uncle_aunt.gender.toUpperCase() == 'F') {
                label = label + ' (AFAB)'
              }
            }
            return (
              <FamilyTreeUncleAunt
                key={uncle_aunt.rkey}
                parent_side={this.props.parent_side}
                collapsed={true}
                label={label}
                uncle_aunt={uncle_aunt}
                patient={this.props.patient}
                dispatch={this.props.dispatch}
                onClickTwin={this.onClickOpenModalTwin}
                fatherId={this.getFatherId()}
                motherId={this.getMotherId()}
                profiles={this.props.patient.profile}
                deleteMember={this.props.deleteMember}
              />
            );
          })}

        {this.props.uncles_aunts
          .filter(i => i.gender_identity ? i.gender_identity == 'female' && i.half == false : i.gender.toUpperCase() === "F" && i.half === false)
          .map((uncle_aunt, index) => {
            var label = this.props.parent_side.charAt(0).toUpperCase() + this.props.parent_side.slice(1).toLowerCase() + ' Aunt #' + (index + 1)
            if (uncle_aunt.gender_identity) {
              if (uncle_aunt.gender && uncle_aunt.gender.toUpperCase() == 'U') {
                label = label + ' (AUAB)'
              } else if (uncle_aunt.gender && uncle_aunt.gender.toUpperCase() == 'M') {
                label = label + ' (AMAB)'
              }
            }
            return (
              <FamilyTreeUncleAunt
                key={uncle_aunt.rkey}
                parent_side={this.props.parent_side}
                collapsed={true}
                label={label}
                uncle_aunt={uncle_aunt}
                patient={this.props.patient}
                dispatch={this.props.dispatch}
                onClickTwin={this.onClickOpenModalTwin}
                fatherId={this.getFatherId()}
                motherId={this.getMotherId()}
                profiles={this.props.patient.profile}
                deleteMember={this.props.deleteMember}
              />
            );
          })}

        {this.props.uncles_aunts
          .filter(i => i.gender_identity ? i.gender_identity == 'non-binary' && i.half == false : i.gender.toUpperCase() === 'U' && i.half === false)
          .map((uncle_aunt, index) => {
            var label = this.props.parent_side.charAt(0).toUpperCase() + this.props.parent_side.slice(1).toLowerCase() + ' Aunt/Uncle #' + (index + 1)
            if (uncle_aunt.gender_identity) {
              if (uncle_aunt.gender && uncle_aunt.gender.toUpperCase() == 'F') {
                label = label + ' (AFAB)'
              } else if (uncle_aunt.gender && uncle_aunt.gender.toUpperCase() == 'M') {
                label = label + ' (AMAB)'
              }
            }
            return (
              <FamilyTreeUncleAunt
                key={uncle_aunt.rkey}
                parent_side={this.props.parent_side}
                collapsed={true}
                label={label}
                uncle_aunt={uncle_aunt}
                patient={this.props.patient}
                dispatch={this.props.dispatch}
                onClickTwin={this.onClickOpenModalTwin}
                fatherId={this.getFatherId()}
                motherId={this.getMotherId()}
                profiles={this.props.patient.profile}
                deleteMember={this.props.deleteMember}
              />
            );
          })}

      </React.Fragment>
    );
  }
}

export default FamilyTreeProbandUnclesAunts;
