import React, { Component } from 'react';
import { cloneDeep } from "lodash";
import { connect } from 'react-redux'
import uniqid from 'uniqid';
import pin from '../../../assets/production/images/pin.png'
import * as helper_search from '../../../helpers/helper-search'
import { systemFields } from '../../../helpers/systemFields';
import refinery_api from '../../../api/refinery-api'

class ProgenyArchive extends Component {

  constructor(props) {
    super(props);

    this.state = {
      progeny_member_data: {},
      search_text: this.props.archived_data_search_text,
      currently_hovered_field: '',
      pinned_fields: []
    };


    this.renderArchiveTable = this.renderArchiveTable.bind(this);
    this.renderProgenyFields = this.renderProgenyFields.bind(this);
    this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.resetProgenyMemberData = this.resetProgenyMemberData.bind(this);
    this.triggerSearch = this.triggerSearch.bind(this);
    this.clearSearchText = this.clearSearchText.bind(this);
    this.callSearch = this.callSearch.bind(this);
    this.handlePinClick = this.handlePinClick.bind(this);
  }

  componentDidMount(){
    this.resetProgenyMemberData();
    this.triggerSearch();

    // get progeny field preferences
    let progeny_field_preferences = this.props.getPedigreeData().getProgenyArchivePreferences();

    // filter progeny field preferences with type of sidebar
    progeny_field_preferences = progeny_field_preferences.filter(preference => preference.preference_type === 'sidebar');

    // sort by order
    let sorted_preferences = progeny_field_preferences.sort(function(a, b){return a.order - b.order});

    // push keys to pinned fields array
    let pinned_fields = []
    for(let preference of sorted_preferences){
      let key = preference.progeny_table_id;
      if(preference.progeny_table_id){
        if(preference.progeny_table_id === 'st_data'){
          key = preference.progeny_field_id;
        }
        else{
          key = preference.progeny_table_id;
        }
      }
      pinned_fields.push(key)
    }

    this.setState({ pinned_fields })

  }

  callSearch(search_string, pattern) {
    if (typeof(search_string) === 'string' && typeof(pattern) === 'string') {
      try{
        return search_string.toLowerCase().search(pattern.toLowerCase()) !== -1;
      }
      catch(e){
        return false;
      }
    }
    return false;
  }

  resetProgenyMemberData(){
    const member_uid = this.props.memberUid+"";
    const family = this.props.progenyArchiveData["family"];

    let progeny_globalid = null;
    let progeny_member_data = null;

    if (family) {
      for (const [key, value] of Object.entries(family)) {
        if (value["member_uid"]+"" === member_uid) {
          progeny_globalid = key;
          progeny_member_data = cloneDeep(value);
          break;
        }
      }
    }

    this.setState({ progeny_member_data })
  }

  renderArchiveTable(table_key, member_uid){
    this.props.viewArchiveDataTable(table_key, member_uid)
  }

  renderProgenyFields(progeny_member_data) {
    const progeny_fields = [];
    const data_dictionary = this.props.progenyArchiveData["data_dictionary"];

    if (progeny_member_data && Object.keys(progeny_member_data).length > 0) {
      const member_uid = progeny_member_data["member_uid"];
      const pinned_fields = cloneDeep(this.state.pinned_fields) || [];

      // render the Progeny Flat Fields
      const st_data = progeny_member_data["st_data"];

      let existing_pinned_flat_fields = pinned_fields.filter((pinned_field) => Object.keys(st_data).find((field) => pinned_field === field));
      let existing_pinned_table_fields = pinned_fields.filter((pinned_field) => Object.keys(progeny_member_data).find((field) => pinned_field === field));

      let existing_pinned_fields = existing_pinned_flat_fields.concat(existing_pinned_table_fields)
      existing_pinned_fields = existing_pinned_fields.sort((a, b) => pinned_fields.indexOf(a) - pinned_fields.indexOf(b));

      let progeny_fields_sorted = []

      for (const [key, value] of Object.entries(st_data)) {
        // key is the Progeny flat field key, use to look up in data dictionary
        // value is the Progeny flat field data
        let field_name = key;
        if(systemFields.includes(field_name)) continue
        if (data_dictionary[key]) {
          field_name = data_dictionary[key];
        }

        const is_pinned = pinned_fields.includes(key)

        progeny_fields.push(
          (<div key={key}>
            <div
              onMouseOver={() => this.setState({ currently_hovered_field: key })}
              onMouseLeave={() => this.setState({ currently_hovered_field: '' })}
              className="row margin-three-top"
              style={{ display: 'flex' }}>
              <div className="col-md-6" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <div>
                  {field_name}
                </div>
                <a
                  className='i-tooltip-archive-pin'
                  onClick={async() => await this.handlePinClick(key)}
                  style={{ display: key === this.state.currently_hovered_field ? 'block' : 'none', left: '5px' }}>
                  <img style={{opacity: is_pinned ? 1 : 0.2}} height='18' width='18' src={pin}></img>
                  <span className="i-tooltiptext-archive-pin">
                    {is_pinned ? 'Unpin' : 'Pin to top'}
                  </span>
                </a>
              </div>
              <div className="col-md-6">
                {value}
              </div>
            </div>
            {pinned_fields && pinned_fields.length > 0 && key === existing_pinned_fields[existing_pinned_fields.length - 1] && <hr className="margin-five" />}
          </div>)
        );

        progeny_fields_sorted.push({
          key,
          field_name
        })
      }


      // Render the Progeny Table Fields
      for (const [key, value] of Object.entries(progeny_member_data)) {
        // skip keys ('member_id', 'member_uid', & 'st_data')
        if (key === "st_data" || key === "member_id" || key === "member_uid" || key == 'uid') continue;

        let table_field_name = key;
        if (data_dictionary[key]) {
          table_field_name = data_dictionary[key];
        }

        const table_field_data = value;

        const is_pinned = pinned_fields.includes(key)

        progeny_fields.push(
          (<div key={key}>
            <div
              onMouseOver={() => this.setState({ currently_hovered_field: key })}
              onMouseLeave={() => this.setState({ currently_hovered_field: '' })}
              className="row margin-three-top"
              style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="col-md-6" style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <div>
                  {table_field_name}
                </div>
                <a
                  className='i-tooltip-archive-pin'
                  onClick={async() => await this.handlePinClick(key)}
                  style={{ display: key === this.state.currently_hovered_field ? 'block' : 'none', left: '5px' }}>
                  <img style={{opacity: is_pinned ? 1 : 0.2}} height='18' width='18' src={pin}></img>
                  <span className="i-tooltiptext-archive-pin">
                    {is_pinned ? 'Unpin' : 'Pin to top'}
                  </span>
                </a>
              </div>
              <div className="col-md-6">
              <a onClick={() => this.renderArchiveTable(key, member_uid)}  className="link">View Table</a>
              </div>
            </div>
            {pinned_fields && pinned_fields.length > 0 && key === existing_pinned_fields[existing_pinned_fields.length - 1] && <hr className="margin-five" />}
          </div>)
        );

        progeny_fields_sorted.push({
          key,
          field_name: table_field_name
        })
      }


      if(pinned_fields && pinned_fields.length > 0){
        // get pinned fields
        let pinned_fields_sorted = progeny_fields_sorted.filter(field => pinned_fields.includes(field.key))

        // sort pinned fields by the same order of the pinned fields array
        pinned_fields_sorted.sort((a, b) => pinned_fields.indexOf(a.key) - pinned_fields.indexOf(b.key));

        // separate pinned fields from the unpinned progeny fields
        progeny_fields_sorted = progeny_fields_sorted.filter(field => !pinned_fields.includes(field.key))

        // sort unpinned fields alphabetically
        progeny_fields_sorted.sort((a, b) => a.field_name.localeCompare(b.field_name))

        // move the pinned fields to the front
        progeny_fields_sorted = pinned_fields_sorted.concat(progeny_fields_sorted)

        // need to only get the keys
        let progeny_fields_sorted_keys = []
        for (let field of progeny_fields_sorted) {
          progeny_fields_sorted_keys.push(field.key)
        }

        // sort the actual jsx elements based on the sorted keys
        progeny_fields.sort((a, b) => progeny_fields_sorted_keys.indexOf(a.key) - progeny_fields_sorted_keys.indexOf(b.key));
      }
    }


    return progeny_fields;
  }

  triggerSearch(){
    // search from the original and full set of progeny_member_data
    const member_uid = this.props.memberUid+"";
    const family = this.props.progenyArchiveData["family"];

    let progeny_globalid = null;
    let progeny_member_data = null;

    if (family) {
      for (const [key, value] of Object.entries(family)) {
        if (value["member_uid"]+"" === member_uid) {
          progeny_globalid = key;
          progeny_member_data = cloneDeep(value);
          break;
        }
      }
    }

    if(progeny_member_data && Object.keys(progeny_member_data).length > 0){
      const data_dictionary = this.props.progenyArchiveData["data_dictionary"];

      let matched_progeny_member_data = {
        member_id: progeny_member_data["member_id"],
        member_uid: progeny_member_data["member_uid"],
        st_data: {}
      }

      const st_data = progeny_member_data["st_data"];
    
      if ('folder_name' in this.props.progenyArchiveData){
        const folder_name =  this.props.progenyArchiveData["folder_name"]
        progeny_member_data["st_data"][`Folder Name`] = folder_name
      }

      if ('pedigree_name' in this.props.progenyArchiveData){
        const pedigree_name =  this.props.progenyArchiveData["pedigree_name"]
        progeny_member_data["st_data"][`Pedigree Name`] = pedigree_name
      }
      
      // Query Fields' Names And Values
      for (const [key, value] of Object.entries(st_data)) {
        const field_name = data_dictionary[key];
        const field_value = value || '';

        if(this.callSearch(field_name, this.state.search_text) || this.callSearch(field_value, this.state.search_text) || this.callSearch(key, this.state.search_text)){
          matched_progeny_member_data['st_data'][key] = value
        }
      }


      // Query Table Fields' Names
      for (const [key, value] of Object.entries(progeny_member_data)) {
        let table_field_name = key;
        if (data_dictionary[key]) {
          table_field_name = data_dictionary[key];
        }

        //search fieldnames on the actual table, and their values
        let matched_inside_table = false;
        for(let table_key in value){
          let row = value[table_key];
          for(let row_key in row){
            let table_row_field_name = data_dictionary[row_key] || row_key || ''
            let table_row_value = String(row[row_key]) || ''
            if(this.callSearch(table_row_field_name, this.state.search_text) || this.callSearch(table_row_value, this.state.search_text) || this.callSearch(row_key, this.state.search_text)){
              matched_inside_table = true;
            }
          }
        }

        //search table field name, and see if there's any match found inside the table
        if(this.callSearch(table_field_name, this.state.search_text) || this.callSearch(key, this.state.search_text) || matched_inside_table){
          matched_progeny_member_data[key] = value
        }
      }

      this.setState({ progeny_member_data: matched_progeny_member_data })
    }

  }

  handleSearchTextChange(search_text) {

    // here, we save the search text to redux store

    // also check here if search text has 2 or more characters, then we call triggerSearch()

    helper_search.updateArchivedDataSearchText(this.props.dispatch, search_text)

    this.setState({ search_text }, () => {
      if(search_text.length == 0){
        this.resetProgenyMemberData();
      }
      if(search_text.length >= 2){
        this.triggerSearch();
      }
    })
  }

  handleSearchSubmit(event) {
    event.preventDefault();
    this.triggerSearch();
  }

  clearSearchText(event) {
    event.preventDefault();
    this.setState({ search_text: '' }, () => {
      helper_search.updateArchivedDataSearchText(this.props.dispatch, '');
      this.triggerSearch();
    })
  }

  async handlePinClick(key){

    let progeny_member_data = JSON.parse(JSON.stringify(this.state.progeny_member_data))
    if (progeny_member_data && Object.keys(progeny_member_data).length > 0) {
      delete progeny_member_data["member_id"];
      delete progeny_member_data["member_uid"];
      const pinned_fields = cloneDeep(this.state.pinned_fields) || [];

      const st_data = progeny_member_data["st_data"];

      if(pinned_fields.includes(key)){
        // unpin
        let progeny_archive_preferences = cloneDeep(this.props.getPedigreeData().getProgenyArchivePreferences())

        let progeny_archive_preference_id = progeny_archive_preferences.find(preference => {
          let key_string = preference.progeny_table_id;
          if(preference.progeny_table_id){
            if(preference.progeny_table_id === 'st_data'){
              key_string = preference.progeny_field_id;
            }
            else{
              key_string = preference.progeny_table_id;
            }
          }

          if(key_string == key && preference.preference_type == 'sidebar'){
            return true;
          }
          return false;
        }).id

        // call api endpoint to unpin
        await refinery_api.delete_progeny_archive_preferences(progeny_archive_preference_id)

        // update the field preferences on pedigree data store
        progeny_archive_preferences = progeny_archive_preferences.filter(preference => {
          let key_string = preference.progeny_table_id;
          if(preference.progeny_table_id){
            if(preference.progeny_table_id === 'st_data'){
              key_string = preference.progeny_field_id;
            }
            else{
              key_string = preference.progeny_table_id;
            }
          }

          if(preference.preference_type == 'subtext'){
            return true;
          }

          if(key_string != key){
            return true;
          }
          return false;
        })

        this.props.getPedigreeData().setProgenyArchivePreferences(progeny_archive_preferences)


        // remove the key from the pinned fields state then setstate
        let pinned_fields = cloneDeep(this.state.pinned_fields)
        pinned_fields = pinned_fields.filter(field => field != key)
        this.setState({ pinned_fields })
      }
      else{
        // pin
        let progeny_archive_preferences = cloneDeep(this.props.getPedigreeData().getProgenyArchivePreferences())
        let sidebar_progeny_archive_preferences = progeny_archive_preferences.filter(preference => preference.preference_type == 'sidebar')
        let highest_order = sidebar_progeny_archive_preferences.length > 0 ? Math.max(...sidebar_progeny_archive_preferences.map(preference => preference.order)) : 0

        let payload = {
          preference_type: "sidebar",
          user_id: this.props.session.user.user_id,
          organization_id: null,
          order: highest_order + 1
        }

        if(Object.keys(st_data).includes(key)){
          // flat field
          payload.progeny_table_id = 'st_data'
          payload.progeny_field_id = key
        }
        else if(Object.keys(progeny_member_data).includes(key)){
          // table field
          payload.progeny_table_id = key
          payload.progeny_field_id = null
        }

        // call api endpoint to pin
        let pinned_field = await refinery_api.create_progeny_archive_preferences(payload);

        // update the field preferences on pedigree data store
        progeny_archive_preferences.push(pinned_field)

        this.props.getPedigreeData().setProgenyArchivePreferences(progeny_archive_preferences)

        // push the key to the pinned fields state then setstate
        let pinned_fields = cloneDeep(this.state.pinned_fields)
        pinned_fields.push(key)
        this.setState({ pinned_fields })
      }


    }


  }

  render() {
    let progeny_member_data = JSON.parse(JSON.stringify(this.state.progeny_member_data))

    const progeny_fields = this.renderProgenyFields(progeny_member_data);

    const search_val = this.state.search_text;
    let delete_search = "delete-search";
    if(search_val === "") delete_search = "delete-search delete-search-hidden";


    return (
      <div>
        <div className="flex-box-container">
          <div className="flex-50">
            <div className="form-header-2">
              <h3 className={"title text--uppercase"}>Archived Data</h3>
            </div>
          </div>
          <div className="flex-50">
            <form style={{position: "relative"}} role="search" onSubmit={this.handleSearchSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search data..."
                  autoComplete="off"
                  onChange={e =>
                    this.handleSearchTextChange(e.target.value)
                  }
                  value={this.state.search_text}
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <a
                    className={delete_search}
                    style={{ position: 'absolute',
                      top: '5px',
                      left: '130px' }}
                    onClick={(event) => this.clearSearchText(event)}
                  >
                    <i className="fa fa-times fa-xs"></i>
                  </a>
                  <button
                    id="search_button"
                    className="search-button progeny-archive-search-btn"
                    type="submit"
                    onClick={(event) => this.handleSearchSubmit(event)}
                  >
                    <i id="search_icon" className="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        {progeny_fields}
      </div>
    );
  }
}

const redux_state = state => ({
  archived_data_search_text: state.search.archivedDataSearchText,
  session: state.session
});

const redux_actions = dispatch => ({
  dispatch: action => dispatch(action)
});

export default connect(
  redux_state,
  redux_actions
)(ProgenyArchive);
