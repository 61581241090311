import { combineReducers } from 'redux';
import types from "./types";

function broadcastMessage(state=[], action) {
  switch(action.type) {
    case types.SAVE_BROADCAST_MESSAGE:
      return action.payload;
    default:
      return state;
  }
}

const broadcast_message = combineReducers({
    broadcastMessage
});

export default broadcast_message;
