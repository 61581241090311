import React, { Component } from 'react';

import skip_logic_map from '../assets/json/diseases-skip-logic-map.json';
const keys_mapping = skip_logic_map.melanoma

class SkipLogicMelanomaView extends Component {

  getValue(key) {
    if(!(key in keys_mapping)) return null

    let code = keys_mapping[key]
    let data = this.props.skipLogics.find(item => item.skip_logic_code == code)

    if(typeof(data) === 'undefined') return null
    return data.answer
  }

  render() {
    let sl = this.props.skipLogics;
    if(sl == null) return null;

    let multiple_melanoma = this.getValue('multiple_melanoma');    

    return (
      <React.Fragment >
        <p>
          {multiple_melanoma == "y" && 'Multiple melanomas (3 or more)'}
        </p>
      </React.Fragment>
    )
  }

}

export default SkipLogicMelanomaView