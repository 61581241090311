import React, { Component } from 'react';

interface Props {
  handleClickAddFilters: () => void;
  handleClickAddFiltersOption: (e: React.MouseEvent<HTMLElement>, option: string) => void;
  show_filters_dropdown: string;
  active_ordered_filters: string[];
}

class AddFilter extends Component<Props> {
  // @ts-ignore
  constructor(props: Props) {
    super(props);
  }

  render() {

    return (
      <React.Fragment>
        <div className="popover-query-wrapper">

          <button onClick={() => this.props.handleClickAddFilters()} className="btn btn-xs btn-light-outline">
            Add Filters
            <div className={"dropdown " + this.props.show_filters_dropdown}>
              <ul
                className="dropdown-menu"
              >
                <li>
                  <a onClick={(e) => this.props.handleClickAddFiltersOption(e, 'disease')}>
                  <i className={(this.props.active_ordered_filters.includes('disease')) ? "fa fa-check" : 'filters-dropdown-unchecked'} style={{ color: '#6a358a' }}></i><span style={{textTransform : 'none', fontSize: 13}}>Disease</span></a></li>
                <li>
                  <a onClick={(e) => this.props.handleClickAddFiltersOption(e, 'genetic_testing')}>
                  <i className={(this.props.active_ordered_filters.includes('genetic_testing')) ? "fa fa-check" : 'filters-dropdown-unchecked'} style={{ color: '#6a358a' }}></i><span style={{textTransform : 'none', fontSize: 13}}>Genetic Testing</span></a></li>
                <li>
                  <a onClick={(e) => this.props.handleClickAddFiltersOption(e, 'referral_criteria')}>
                  <i className={(this.props.active_ordered_filters.includes('referral_criteria')) ? "fa fa-check" : 'filters-dropdown-unchecked'} style={{ color: '#6a358a' }}></i><span style={{textTransform : 'none', fontSize: 13}}>Referral Criteria</span></a></li>
                <li>
                  <a onClick={(e) => this.props.handleClickAddFiltersOption(e, 'more')}>
                  <i className={(this.props.active_ordered_filters.includes('more')) ? "fa fa-check" : 'filters-dropdown-unchecked'} style={{ color: '#6a358a' }}></i><span style={{textTransform : 'none', fontSize: 13}}>More Filters</span></a></li>
              </ul>
            </div>
          </button>
        </div>
      </React.Fragment>



    )
  }
}


export default AddFilter
