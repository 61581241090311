import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import user_account_api from "../../api/user-account-api";

interface ModalProps {
    user: any;
    onClose: () => void;
  }


interface State{
    devKey: any;
}

class ModalDevKey extends Component<ModalProps, State> {
    constructor(props: ModalProps) {
        super(props);
        this.state = {
          devKey: ''
        };

      this.componentDidMount = this.componentDidMount.bind(this);
      this.copyToClipBoard = this.copyToClipBoard.bind(this);
    }
  async componentDidMount(){
    try{
      let key = await user_account_api.generate_developer_key(this.props.user.organization_id)
      console.log(key)
      this.setState({devKey: key})
    }catch(err){
      console.dir(err)
    }

  }

  copyToClipBoard(){
    const tempElement = document.createElement("textarea");
    tempElement.value = this.state.devKey;
    document.body.appendChild(tempElement);
    tempElement.select();
    document.execCommand("copy");

    // remove the temporary element from the document
    document.body.removeChild(tempElement);
  }

render() {
    return ReactDOM.createPortal(
      <div onClick={() => this.props.onClose()}
        style={{ display: 'block', paddingLeft: '12px' }}
        className="modal fade in"
        role="dialog"
        aria-labelledby="api-key"
        id="api-key">

        <div onClick={(e) => e.stopPropagation()} className="modal-dialog" role="document">
          <div className="modal-content">

            <div className="modal-header">
              <button onClick={() => this.props.onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="fa fa-close"></i>
              </button>
              <h4 className="modal-title text-white text-capitalize">New Developer Key</h4>
            </div>


            <div className="modal-body">
              <form className="form-horizontal form-label-left form-custom">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-4 col-sm-4 col-xs-12">
                      <label className="control-label">
                        <b>
                          New Key
                        </b>
                      </label>
                      <p>
                      {this.state.devKey}
                    </p>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="modal-footer">

              <button
                onClick={() => this.props.onClose()}
                type="button" className="btn btn-light-outline no-margin-right" data-dismiss="modal">Exit</button>
              <button onClick={this.copyToClipBoard} type="button" className="btn btn-light-outline no-margin-right"> Copy Key</button>
            </div>
          </div>
        </div>
      </div>,
      document.body
        );
    };
}
export default ModalDevKey;
