import React, { memo } from 'react';
import { Handle } from '@xyflow/react';

import Node_Line_Store from './Node_Line_Store';

import RelationshipDropdownMenu from './relationship-dropdown-menu';

import TwinTypeDropDownMenu from './twin-type-dropdown-menu';

export default memo(({ data, xPos, yPos, type, selected, connectable, sourcePosition, targetPosition }) => {

  let watcher = data.getConnectionWatcher();
  watcher.update_node_location(data.id, xPos, yPos);

  data.datastore.addNode(data.id, Node_Line_Store.CONNECTOR, xPos, yPos, selected);
  let clickcount = data.getPedigreeDrawingData().getClickCount(data.id);
  let selected_count = data.getPedigreeDrawingData().getSelectedCount();
  let showRelationshipDropdown = selected && selected_count <= 1 && !data.isNodeDragging && clickcount > 1 && data.isPartnerConnectorNode
  let showTwinTypeDropdown = selected && selected_count <= 1 && clickcount > 1 && data.isTwinConnectorNode

  let node_build = null;

  if (!data.isSiblingCornerDonorConnector) {
    node_build = (
      <>
        <rect x={1} y={1} className='person-connector-node' />
        {showRelationshipDropdown && <RelationshipDropdownMenu showDropdown={showRelationshipDropdown} data={data} />}
        {showTwinTypeDropdown && <TwinTypeDropDownMenu showDropdown={showTwinTypeDropdown} data={data} />}
      </>
    );
  }
  // let node_build = []

  let is_current_biological_parents = false;

  if (data.getPedigreeData().getReassignParentsConnectionActive()) {
    let relationship_rkey = data.id.slice(2).slice(0, -2)

    let people = Object.values(data.getPedigreeData().getAllProfiles());
    let reassign_parents_source = people.find(person => person.is_reassign_parents_source);
    let peopleWithRelationships = people.filter(person => person.relationship_ids.length > 0)

    let relationship_data_of_source_parents = peopleWithRelationships.find(person => person.relationship_ids.find(relationship => (relationship.father_id == reassign_parents_source.father_id && relationship.mother_id == reassign_parents_source.mother_id) || (relationship.mother_id == reassign_parents_source.father_id && relationship.father_id == reassign_parents_source.mother_id)))
    if (relationship_data_of_source_parents) {
      relationship_data_of_source_parents = relationship_data_of_source_parents.relationship_ids.find(relationship => (relationship.father_id == reassign_parents_source.father_id && relationship.mother_id == reassign_parents_source.mother_id) || (relationship.mother_id == reassign_parents_source.father_id && relationship.father_id == reassign_parents_source.mother_id))
      is_current_biological_parents = relationship_data_of_source_parents.rkey == relationship_rkey;
    }
  }

  let is_partner_connector_node = data.isPartnerConnectorNode ? data.isPartnerConnectorNode : false;
  let is_possible_reassign_parents_target = data.getPedigreeData().getReassignParentsConnectionActive() && is_partner_connector_node && !is_current_biological_parents && !data.isSiblingCornerDonorConnector;


  return (
    <>
      <Handle id='re-assign-parents-target' type="target" isConnectable={is_possible_reassign_parents_target} style={{ visibility: is_possible_reassign_parents_target ? 'visible' : 'hidden' }} />
      {node_build}
      <Handle type="source" isConnectable={false} hidden={true} />
    </>
  );
});
