import { combineReducers } from 'redux';
import types from "./types";

function temp_data(state={}, action) {
  switch(action.type) {
    case types.SET_DATA:
      return action.payload
    default:
      return state;
  }
}

export default combineReducers({
  temp_data,
});

