const LEGEND_DOM_CONTAINER_ELEMENT = "legend-dom-container";
const PEDIGREE_NOTES_ELEMENT = "legend-notes";
const PEDIGREE_NOTES_CONTAINER_ELEMENT = "legend-notes-container";
const ARCHIVE_DATA_TABLE = "archive-data-table"

export default {
  LEGEND_DOM_CONTAINER_ELEMENT,
  PEDIGREE_NOTES_ELEMENT,
  PEDIGREE_NOTES_CONTAINER_ELEMENT,
  ARCHIVE_DATA_TABLE
}
