import types from "./types";

export const update_search_text = (payload) => {
  return {
		type: types.UPDATE_SEARCH_TEXT,
		payload: payload
	 }
}

export const save_search_filter = (payload) => {
  return {
		type: types.SAVE_SEARCH_FILTER,
		payload: payload
	 }
}

export const update_search_results = (payload) => {
  return {
		type: types.UPDATE_SEARCH_RESULTS,
		payload: payload
	 }
}

export const update_search_history = (payload) => {
  return {
		type: types.UPDATE_SEARCH_HISTORY,
		payload: payload
	 }
}

export const update_archived_data_search_text = (payload) => {
	return {
		type: types.UPDATE_ARCHIVED_DATA_SEARCH_TEXT,
		payload: payload
	}
}
