import * as model from '../model-states/m-health-history'
import uniqid from 'uniqid'
import * as patient_actions from '../store/patient/actions'
import skip_logic_map from '../assets/json/diseases-skip-logic-map.json';
import { cloneDeep } from 'lodash'

const saveHistoryDetailToRedux = (dispatch, ownerRkey, history_detail) => {
  let payload = {ownerRkey, history_detail}
  dispatch(patient_actions.save_history_detail(payload))
}

const saveHistoryDiseasesToRedux = (dispatch, ownerRkey, history_diseases) => {
  let member_diseases = []

  for(var item of history_diseases) {
    var disease = model.createHistoryDisease()
    disease.id = item.id
    disease.disease_code = item.disease_code
    disease.age_diagnosed = item.age_diagnosed
    disease.age_diagnosed_string = item.age_diagnosed_string
    disease.disease_id = item.disease_id
    disease.disease_name = item.disease_name
    // disease.disease = {value: item.disease_id, label: item.disease_name}
    disease.skip_logics = item.skip_logics
    member_diseases.push(disease)
  }


  let payload = {ownerRkey, history_diseases: member_diseases}
  dispatch(patient_actions.save_history_diseases(payload))
}

const saveHistoryDiseaseDetailToRedux = (dispatch, ownerRkey, history_disease_detail) => {
  let payload = {ownerRkey, history_disease_detail}
  dispatch(patient_actions.save_history_disease_detail(payload))
}

const saveHistoryGenesToRedux = (dispatch, ownerRkey, history_genes) => {

  let member_genes = []
  for(var item of history_genes) {
    var gene = model.createHistoryGene()
    gene.id = item.id;
    gene.variant = item.variant;
    gene.gene_id = item.gene_id;
    gene.result = item.result;
    gene.status = item.status;
    gene.gene = item.gene;
    member_genes.push(gene)
  }

  let payload = {ownerRkey, history_genes: member_genes}
  dispatch(patient_actions.save_history_genes(payload))
}

const saveHistoryGeneDetailToRedux = (dispatch, ownerRkey, history_gene_detail) => {
  let payload = {ownerRkey, history_gene_detail}
  dispatch(patient_actions.save_history_gene_detail(payload))
}

export {
  saveHistoryDetailToRedux,
  saveHistoryDiseasesToRedux,
  saveHistoryDiseaseDetailToRedux,
  saveHistoryGenesToRedux,
  saveHistoryGeneDetailToRedux
}
