export const skipLogicUMLS = {
    
    breastUMLS: ['C0006142', 'D002'],
    colorectalUMLS: ['C0346629', 'D003'],
    colorectalPolypsUMLS: ['C0949059', 'D004'],
    melanomaUMLS: ['C0025202', "D008"] ,
    hodgkinlymphomaUMLS: ['C0019829', "D030"],
    dcisUMLS: ['C0007124', "D005"],
    pancreaticNeuroendocrineUMLS: ['C0242363', "D033"],
    prostateUMLS: ['C0376358', "D011"],
    thyroidUMLS: ['C0007115', "D015"],
    uterineUMLS: ['C0153574', "D017"],
    leukemiaUMLS: ['C0023418', "D007"],
    birthdefectsUMLS: ['C0000768',"D079"],

}