import React, { Component } from 'react';
import ModalGeneTestForm from '../modal-gene-test-form';
import genetic_testing_api from '../../api/genetic-testing-api';
import geneResults from '../../assets/json/gene-result.json';
import geneStatus from '../../assets/json/gene-status.json';
import ModalConfirmDelete from '../modal-confirm-delete';
import { createUUID } from './utils';

class PatientGeneTestView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalGeneTest: false,
      selectedGeneDetail: null,
      modalGeneTestTitle: '',
      openModalConfirmDelete: false,
      modalConfirmDeleteHash: null,
      loading_delete: false,
      errorMessages: []
    };

    this.handleClickAddGeneTest = this.handleClickAddGeneTest.bind(this);
    this.getGeneTests = this.getGeneTests.bind(this);
    this.diseaseGroup = this.diseaseGroup.bind(this);
    this.handleEditGeneTest = this.handleEditGeneTest.bind(this);
    this.getLabelResult = this.getLabelResult.bind(this);
    this.getLabelStatus = this.getLabelStatus.bind(this);
    this.onModalGeneTestSave = this.onModalGeneTestSave.bind(this);
    this.onModalGeneTestDelete = this.onModalGeneTestDelete.bind(this);
    this.onClickOpenModalConfirmDelete = this.onClickOpenModalConfirmDelete.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.disableAddForReadOnly = this.disableAddForReadOnly.bind(this);
  }

  async handleClickAddGeneTest() {
    if('onBeforeAddGene' in this.props) {
      var ok = await this.props.onBeforeAddGene();
      if(ok) {
        this.setState({showModalGeneTest: true, selectedGeneDetail: null, modalGeneTestTitle: 'Add Gene'});
      }
    } else {
      this.setState({showModalGeneTest: true, selectedGeneDetail: null, modalGeneTestTitle: 'Add Gene'});
    }
  }

  getGeneTests() {
    const genes = this.props.getPedigreeData().getGenes(this.props.patientRkey);
    for(let i=0; i<genes.length; i++) if(genes[i].rkey === null || genes[i].rkey === undefined) genes[i].rkey = createUUID();
    return genes;
  }

  diseaseGroup(id) {
    if(1 <= id <= 43) {
      return 'Cancer';
    } else {
      return 'Other Conditions';
    }
  }

  handleEditGeneTest(selectedGeneDetail) {
    this.setState({showModalGeneTest: true, selectedGeneDetail, modalGeneTestTitle: 'Edit Gene'});
  }

  getLabelResult(id) {
    if(geneResults.length == 0) return '';
    var result = geneResults.find(item => item.value == id || item.label == id)

    if(typeof(result) === 'undefined') return ''
    return result.label + ', ';
  }

  getLabelStatus(id) {
    if(geneStatus.length == 0) return '';
    var status = geneStatus.find(item => item.value == id)

    if(typeof(status) === 'undefined') return ''
    return status.label;
  }

  async onModalGeneTestSave(data) {
    if('onGeneTestUpdate' in this.props) {
      await this.props.onGeneTestUpdate(data);
      this.props.updateSubtextMargin()
    }
    this.setState({showModalGeneTest: false});
  }

  async onModalGeneTestDelete(data) {
    if('onGeneTestDelete' in this.props){
      await this.props.onGeneTestDelete(data);
      this.props.updateSubtextMargin()
    }
    this.setState({showModalGeneTest: false});
    if(this.state.openModalConfirmDelete === true) this.setState({openModalConfirmDelete: false})
  }

  onClickOpenModalConfirmDelete(selectedGeneDetail) {
    this.setState({
      selectedGeneDetail,
      openModalConfirmDelete: true,
      modalConfirmDeleteHash: new Date().getTime()
    });
  }

  async onClickDelete() {
    try {
      this.setState({ errorMessages: [], loading_delete: true })

      // delete genetic testing
      if(this.state.selectedGeneDetail.id !== null) {
        await genetic_testing_api.delete_member_genetic_testing_id(this.state.selectedGeneDetail.id)
      }

      if('onGeneTestDelete' in this.props) {
        this.onModalGeneTestDelete({
          rkey: this.props.patientRkey,
          gene_rkey: this.state.selectedGeneDetail.rkey
        });
      } else {
        this.setState({showModalGeneTest: false})
      }

    } catch (error) {
      this.setState({ errorMessages: [error.message] })
    } finally {
      this.setState({ loading_delete: false })
    }
    this.setState({openModalConfirmDelete: false})
  }

  disableAddForReadOnly(){
    if(this.props.read_only && this.props.read_only === true){
      return false
    }
    return true
  }

  render() {
    return (
      <React.Fragment>
        <div className="row margin-four-bottom">
          <div className="col-md-12 margin-one no-margin-top">
            <div className={(this.props.sideBar ? "form-header-2" : "form-header")}>
              <div className="row vcenter">
                <div className="col-md-6 ">
                <h3 className={"title " + (this.props.sideBar ? 'text--uppercase' : '')}>Genetic Testing</h3>
                </div>
              {this.disableAddForReadOnly() && (
                <div className="col-md-6 ">

                  {this.props.sideBar ?
                    <a className="btn btn-teal btn-xs" onClick={this.handleClickAddGeneTest}><i className="fa fa-plus"></i> Add</a>
                  :
                    <a className="btn btn-teal btn-xs" onClick={this.handleClickAddGeneTest}><i className="fa fa-plus"></i> Add</a>
                  }

                </div>
              )}
              </div>
            </div>
            <div className="panel-col-gene">

              { this.getGeneTests().map(item =>

                <div key={item.rkey} className={"panel-summary " + (this.props.sideBar ? 'width--full' : '')}>
                {this.disableAddForReadOnly() && (
                  <>
                  <a className="panel-summary-edit" onClick={() => this.handleEditGeneTest(item)}><i className="fa fa-pencil-square-o"></i></a>
                  <a className="panel-summary-delete" onClick={() => this.onClickOpenModalConfirmDelete(item)}><i className="fa fa-trash"></i></a>
                  </>
                )}
                  <h4>{item.gene}</h4>
                  <p>
                    {this.getLabelResult(item.result)}
                    {item.variant && item.variant + ','}
                    {this.getLabelStatus(item.status ? item.status : null)}
                  </p>
                </div>
              )}

            </div>
          </div>
        </div>

        {this.state.openModalConfirmDelete &&
          <ModalConfirmDelete
            title="Delete Gene"
            message="Do you want to delete this gene?"
            isOpen={this.state.openModalConfirmDelete}
            onCancel={() => this.setState({ openModalConfirmDelete: false })}
            onOk={() => this.onClickDelete()}
            loading={this.state.loading_delete}
            errorMessages={this.state.errorMessages}
          />
        }

        {this.state.showModalGeneTest &&
          <ModalGeneTestForm
            title={this.state.modalGeneTestTitle}
            patientRkey={this.props.patientRkey}
            profile={this.props.profile}
            geneDetail={this.state.selectedGeneDetail}
            onClose={() => this.setState({showModalGeneTest: false})}
            onCancel={() => this.setState({showModalGeneTest: false})}
            onSave={(data) => this.onModalGeneTestSave(data)}
            onDelete={(data) => this.onModalGeneTestDelete(data)}
            getPedigreeData={this.props.getPedigreeData}
          />
        }



      </React.Fragment>
    )
  }
}

export default PatientGeneTestView;
