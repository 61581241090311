import React, { Component } from 'react';

class ValidationSummary extends Component {

  getErrorText() {
    if (this.props.validations == null){
      return ''
    }

    if (!(this.props.field in this.props.validations.fields)) {
      return ''
    }
    return this.props.validations.fields[this.props.field]
  }

  render() {

    if(this.getErrorText() === '') return <React.Fragment />

    return (
      <label className="validation-inline__error-text">{this.getErrorText()}</label>
    )

  }
}

export default ValidationSummary
