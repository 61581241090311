import React, { Component } from "react";
import moment from 'moment';
import { acceptOrDenyNotification, notificationsGroupedByName } from "../../helpers/helper-notifications";
import ModalError from "../modal-error";
import helper from "../../helpers";
import * as helper_error from "../../helpers/helper-error";
import route_helper from "../../route-helper";
import { withRouter } from "react-router";
import notifications_api from '../../api/notifications-api'
import settings from "../../configs/settings";
import userAccountApi from "../../api/user-account-api";
import familyApi from "../../api/family-api";
import Select from 'react-select';
import notificationsApi from "../../api/notifications-api";
import { cloneDeep } from "lodash";
import { createUUID } from '../react-flow-pedigree/utils';

class NotificationPatientUpdates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active_tab: "unread",
      active_notification_id: undefined,
      show_modal_error: false,
      items_per_batch: 10,
      notifications: [],
      clinicians: [],
      selectedClinicians: [],
      familyCliniciansMap: {},
      unread_notifications: {},
      read_notifications: {}
    };

    this.fetchNotifications = this.fetchNotifications.bind(this);
    this.openPatientUpdates = this.openPatientUpdates.bind(this);
    this.fetchClinicians = this.fetchClinicians.bind(this);
    this.filterNotifications = this.filterNotifications.bind(this);
    this.handleSelectClinician = this.handleSelectClinician.bind(this);
  }

  async componentDidMount() {
    await this.fetchNotifications();
    await this.fetchClinicians();
  }

  groupNotifications(notifications) {
    let unread_notifications = {};
    let read_notifications = {};
    let read = true;
    let unread = false;
    if (notifications && Object.keys(notifications).length === 0) return;

    unread_notifications = notificationsGroupedByName(notifications, unread);
    read_notifications = notificationsGroupedByName(notifications, read);
    this.setState({ unread_notifications, read_notifications });
  }

  async fetchClinicians() {
    try {
      let org_clinicians = await userAccountApi.get_clinician_list();
      let clinicians = [];
      for (let clinician of org_clinicians) {
        clinicians.push({ value: clinician.user_id, label: clinician.first_name + " " + clinician.last_name });
      }

      let unread_notifications = notificationsGroupedByName(this.state.notifications, false);
      let read_notifications = notificationsGroupedByName(this.state.notifications, true);

      let toFamilyIds = new Set();
      for (let key in unread_notifications) {
        toFamilyIds.add(unread_notifications[key].to_family_id);
      }
      for (let key in read_notifications) {
        toFamilyIds.add(read_notifications[key].to_family_id);
      }
      let payload = { to_family_ids: Array.from(toFamilyIds) };
      let family_clinicians = await familyApi.get_family_clinicians(payload);

      this.setState({ clinicians, familyCliniciansMap: family_clinicians['family_clinicians']});
    } catch (error) {
      console.log(error);
    }
  }

  async fetchNotifications() {
    let includeUnopenedFamilies = false

    try {
      let notifications = await notifications_api.get_clinician_notifications(
        this.props.clinician_id,
        ["pending"],
        settings.clinician_notification_types,
        includeUnopenedFamilies
      );
      this.groupNotifications(notifications);

      this.setState({ notifications: notifications });
    } catch (error) {
      console.log(error);
    }
  }

  async dismiss(notification) {
    try {
      const updates = notification.updates;
      for (let update of updates) {
        await notificationsApi.post_notification_notificationid_read(update.id, this.props.clinician_id);
      }
    }
    catch(error) {
      console.log(error);
    }

    let unread_notifs = cloneDeep(this.state.unread_notifications);
    let notif = unread_notifs[notification.from_family_id];
    notif.is_read = true;
    delete unread_notifs[notification.from_family_id];

    let read_notifs = cloneDeep(this.state.read_notifications);
    if (!read_notifs[notification.from_family_id]) {
      read_notifs[notification.from_family_id] = notif;
    } else {
      read_notifs[notification.from_family_id] = { ...read_notifs[notification.from_family_id], ...notif };
    }
    this.setState({ unread_notifications: unread_notifs, read_notifications: read_notifs });
    // Skip temporarily
    return;
    try {

      let { clinician_id, user_id } = this.props;
      this.setState({ active_notification_id: notification.id });
      await acceptOrDenyNotification(notification.id, clinician_id, user_id, "deny");

      await this.fetchNotifications();

    } catch (error) {
      this.setState({ show_modal_error: true });
      helper_error.log_error(error);
    } finally {
      await helper.delay(1);
      this.setState({ loading: false, active_notification_id: undefined });
    }
  }

  handleShowMoreClick() {
    let { items_per_batch } = this.state;
    items_per_batch += 10;
    this.setState({items_per_batch});
  }

  selectDistinct(notifications, is_read = false) {
    let distinct = [];
    let distinct_family_id = [];
    let filtered_notifications = notifications.filter(n => n.is_read == is_read);
    for(let item of filtered_notifications) {
      if(distinct_family_id.includes(item.from_family_id)) continue;
      distinct_family_id.push(item.from_family_id);
      distinct.push(item);
    }
    return distinct;
  }

  async openPatientUpdates(notification, receiver_proband) {
    let patient_id = receiver_proband.id;
    let url = route_helper.pedigree.pedigree_root.replace(":member_id", patient_id) + "/#patient-updates";

    this.props.history.push('/empty');
    await helper.delay(0.1);
    this.props.history.replace(url);
  }

  async dismissNotifications(unread_notifications){
    for (let notification of unread_notifications) {
      await this.dismiss(notification);
    }
    return;
    // Skip temporarily
    let { loading } = this.state
    let { errorMessages } = this.state
    try{

      loading = true
      this.setState({ loading });
      let clinician_id = this.props.clinician_id;
      await notifications_api.post_notification_notification_dismiss_patient_updates({clinician_id});

      await this.fetchNotifications();

    } catch (error) {
      errorMessages = [error.message]
      this.setState({ errorMessages });
      helper_error.log_error(this.constructor.name, error);
    } finally {
      this.setState({ loading: false });
    }
  }

  filterNotifications = () => {
    const { selectedClinicians, familyCliniciansMap } = this.state;

    let unread_notifs = notificationsGroupedByName(this.state.notifications, false);
    let read_notifs = notificationsGroupedByName(this.state.notifications, true);

    if (!selectedClinicians || selectedClinicians.length === 0) {
      this.setState({
        unread_notifications: unread_notifs,
        read_notifications: read_notifs
      });
    } else {
      const clinicianIds = selectedClinicians.map(clinician => clinician.value);

      const filteredUnreadNotifications = Object.values(unread_notifs).filter(notification => {
        const familyClinicians = familyCliniciansMap[notification.to_family_id] || [];
        return clinicianIds.some(clinician => familyClinicians.includes(clinician));
      });

      const filteredReadNotifications = Object.values(read_notifs).filter(notification => {
        const familyClinicians = familyCliniciansMap[notification.to_family_id] || [];
        return clinicianIds.some(clinician => familyClinicians.includes(clinician));
      });

      this.setState({
        unread_notifications: filteredUnreadNotifications,
        read_notifications: filteredReadNotifications
      });
    }
  }

  toggleTab(tab) {
    this.setState({ active_tab: tab });
  }

  handleSelectClinician = (selectedClinicians) => {
    this.setState({ selectedClinicians }, () => {
        this.filterNotifications();
    });
}

  render() {
    let { active_tab } = this.state;
    let { unread_notifications, read_notifications } = this.state;
    let { active_notification_id, items_per_batch } = this.state;

    if(this.state.notifications.length == 0) return null;
    let visible_notifications = Object.values(read_notifications).sort((a,b) => moment(b.most_recent_created_at).diff(moment(a.most_recent_created_at))).slice(0, items_per_batch);

    return (
      <div className={"tab-pane fade in active"}>
        <p className="subtitle">
          The following patients have pending updates to their data.
        </p>

        <div className="select-container">
          <label for="select-clinician">Clinician</label>
          <Select
          id="select-clinician"
          value={this.state.selectedClinicians}
          name="clinician"
          onChange={this.handleSelectClinician}
          className="react-select-container"
          classNamePrefix="react-select"
          isClearable={true}
          isMulti={true}
          placeholder=""
          options={this.state.clinicians}
          />
        </div>

        <ul className="nav nav-tabs" id="patient-updates-tabs">
          <li
            className={
              "nav-tabs--notifications " + (active_tab == "unread" ? "active" : "")
            }
          >
            <a data-toggle="tab" onClick={() => this.toggleTab("unread")}>
              Pending ({Object.values(unread_notifications).length})
            </a>
          </li>
          <li
            className={
              "nav-tabs--notifications " + (active_tab == "read" ? "active" : "")
            }
          >
            <a data-toggle="tab" onClick={() => this.toggleTab("read")}>
              Dismissed ({Object.values(read_notifications).length})
            </a>
          </li>
          <li
            className={
              "nav-tabs--notifications " + (active_tab == "all" ? "active" : "")
            }
          >
            <a data-toggle="tab" onClick={() => this.toggleTab("all")}>
              All ({Object.keys(unread_notifications).length + Object.keys(read_notifications).length})
            </a>
          </li>
        </ul>

        {(active_tab === "unread" || active_tab == "all") && Object.values(unread_notifications).sort((a,b) => moment(b.most_recent_created_at).diff(moment(a.most_recent_created_at))).map((notification, index) => {
          return <NotificationItem
            key={createUUID()}
            notification={notification}
            onDismissClick={() => this.dismiss(notification)}
            onPatientClick={this.openPatientUpdates}
            showDismissButton={(notification.is_read === false)}
            activeNotificationId={active_notification_id}
            clinician_id={this.props.clinician_id}
          />
        })}

        {(active_tab === "unread" || active_tab == "all") && Object.values(unread_notifications).length > 0 &&
          <div className=" margin-four-bottom">
            <a href="#" className="link" onClick={()=> this.dismissNotifications(unread_notifications)}>
              Dismiss all
            </a>
          </div>
        }

        {(active_tab === "read" || active_tab == "all") && visible_notifications.map((notification, index) => {
          return <NotificationItem
            key={createUUID()}
            notification={notification}
            onDismissClick={() => this.dismiss(notification)}
            onPatientClick={this.openPatientUpdates}
            showDismissButton={(notification.is_read === false)}
            activeNotificationId={active_notification_id}
            clinician_id={this.props.clinician_id}
          />
        })}

        {(active_tab === "read" || active_tab == "all") && Object.values(read_notifications).length > visible_notifications.length &&
          <div className=" margin-four-bottom">
            <a className="link" onClick={() => this.handleShowMoreClick()}>
              Show More <i className="fa fa-angle-down"></i>
            </a>
          </div>
        }

        {this.state.show_modal_error && (
          <ModalError
            onClose={() => this.setState({ show_modal_error: false })}
          />
        )}

      </div>
    );
  }
}

class NotificationItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      receiver_proband: null
    };

    this.fetchReceiverProband = this.fetchReceiverProband.bind(this);
    this.checkClickEvent = this.checkClickEvent.bind(this);
    this.setLocalStorage = this.setLocalStorage.bind(this);
  }

  async componentDidMount() {
    await this.fetchReceiverProband();
  }

  async fetchReceiverProband() {
    try {

      let proband = await notifications_api.get_receiver_proband_for_notifications(
        this.props.clinician_id,
        this.props.notification.id
      );

      this.setState( { receiver_proband: proband });
    } catch (error) {
      console.log(error);
    }
  }

  setLocalStorage() {
    const tokens = sessionStorage.getItem('famgenix_app_tokens');
    const extra_data = sessionStorage.getItem('extra_data');
    localStorage.setItem('famgenix_app_tokens', tokens);
    localStorage.setItem('extra_data', extra_data);
  }

  checkClickEvent(event, pedigree_url, notification, onPatientClick) {
    var isRightMB;

    const url = process.env.REACT_APP_SITE_URL+pedigree_url+"#patient-updates";

    if ("which" in event.nativeEvent) { // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
      isRightMB = event.nativeEvent.which === 3;
    } else if ("button" in event.nativeEvent) {  // IE, Opera
      isRightMB = event.nativeEvent.button === 2;
    }

    if (isRightMB || event.ctrlKey || event.metaKey) {
      // temp set the sessionStorage to locaStorage
      this.setLocalStorage();

      window.open(url, '_blank', 'noopener,noreferrer');
    } else {
      window.open(url, '_self');
      onPatientClick(notification, this.state.receiver_proband);
    }
  }

  render() {
    let { notification, onDismissClick, onPatientClick, showDismissButton, activeNotificationId } = this.props;
    let { created_at, updates, most_recent_created_at } = notification;
    let options = {year: 'numeric', month: 'long', day: 'numeric' };
    let name = this.state.receiver_proband
      ? this.state.receiver_proband.first_name +
        " " +
        this.state.receiver_proband.last_name
      : "";

    let created_date = new Date(notification.created_at).toLocaleDateString(undefined, options);
    let created_time = moment(most_recent_created_at).format("H:mm A");

    // Use this to disable dimiss button when clicked
    let isCurrentNotification = activeNotificationId === notification.id;

    let pedigree_url = null;
    if (this.state.receiver_proband) {
      pedigree_url = "/pedigree/" + this.state.receiver_proband.id;
    }

    return (
      <div  className="notif-item">
        { showDismissButton &&
          <a href="#" className="del" onClick={() => onDismissClick()}>
            <i className="fa fa-trash"></i>
          </a>
        }

        <p>
          <a onClick={(e) => this.checkClickEvent(e, pedigree_url, notification, onPatientClick)} style={{color: '#626262'}}>
            <strong>{name}</strong>
            &nbsp;{updates.length > 1 && '(' + updates.length + ')'}
          </a>
        </p>

        <p>
          ID: {this.state.receiver_proband ? this.state.receiver_proband.id : ""}
        </p>

        <p>
          <span className="date">{created_date}</span>{" "}
          <span className="margin-two-right time">{created_time}</span>
        </p>

        {/* { active_notification_id === notification.id &&
          <div style={{ position: "absolute", right: 10, top: 20 }}>
            <ActivityIndicator loading={true} />
          </div>
        } */}
      </div>
    );
  }
}

export default withRouter(NotificationPatientUpdates);
