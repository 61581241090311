import React, { Component } from "react";



class DropDownHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
          showHeader: true,
        }
        this.toggle = this.toggle.bind(this)
      }
    toggle(){
        this.setState({showHeader: !this.state.showHeader})
        this.props.handleToggle()
    }

    render(){
        let title = this.state.showHeader ? "fa fa-chevron-down" : "fa fa-chevron-right"  
        return(
            <React.Fragment>
            <tr className="custom-row-header">
                <td className="custom">
                    <a onClick={this.toggle}>
                        <i class={title}> </i>
                    </a>
                </td>
                <td colspan="8">
                    {this.props.title}
                </td>
        </tr>
        </React.Fragment>
        )
    }

}

export default DropDownHeader