import { combineReducers } from 'redux';
import types from "./types";

function notification_items(state=[], action) {
  switch(action.type) {
    case types.NOTIFICATIONS_RECEIVED:
      return action.payload
    default:
      return state;
  }
}

export default combineReducers({
  notification_items,
});

