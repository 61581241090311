import React, { Component } from 'react';

import {
  verifyAuthenticationOptionsWebAuthn2FactorFlow
} from '../api/webauthn-api';

import {
  preformatGetAssertReq,
  publicKeyCredentialToJSON
} from '../helpers/authenticator-helper';
import route_helper from '../route-helper';

type ClassProps = {
  location: any
};

type ClassState = {};

class AuthenticatorAuthenticatePage extends Component<ClassProps, ClassState> {

  constructor(props: ClassProps) {
    super(props);
    this.state = {};

    this.handleAuthenticate = this.handleAuthenticate.bind(this);
  }

  async componentDidMount() {
    const authenticator_data = this.props.location.state.detail;
    await this.handleAuthenticate(authenticator_data);
  }

  async handleAuthenticate(authenticator_data: object) {
    try {

      const publicKey = preformatGetAssertReq(authenticator_data);
			const creds = await navigator.credentials.get({ publicKey });
  		const makeCredResponse = publicKeyCredentialToJSON(creds);
  		const response = await verifyAuthenticationOptionsWebAuthn2FactorFlow(makeCredResponse);

      window.location.replace(route_helper.query.query_root);

    } catch (exception: any) {
      if (exception instanceof DOMException && "message" in exception) {
        this.setState({errorMessages: [`${exception.name}: ${exception.message}`]});
      }
    }
	}

  render() {
    const copyright_year: number = new Date().getFullYear();

    return (
      <div className="login_wrapper">

        <div className="panel panel-default">
          <div className="panel-heading">
            <h3>Authenticate with Authenticator</h3>
          </div>
          <div className="panel-body">
            Authenticate
          </div>
        </div>

        <div><p className="text-center text-muted margin-nine-top"><span>&copy; {copyright_year} by FamHis, Inc. All rights reserved.</span></p></div>
      </div>
    );
  }

}

export default AuthenticatorAuthenticatePage;
