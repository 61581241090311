import React, { Component } from "react";
import ErrorSummary from "../error-summary";
import ActivityIndicator from "../activity-indicator";
import * as helper_error from "../../helpers/helper-error";
import notifications_api from "../../api/notifications-api";
import moment from "moment";

class NotificationDisconnected extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessages: [],
      notifications: [],
      disconnectedDisplayCount: 5,
      loading: false,
    };

    this.dismissAll = this.dismissAll.bind(this);
    this.dismissItem = this.dismissItem.bind(this);
    this.handleShowMoreClick = this.handleShowMoreClick.bind(this);

    this.fetchNotifications = this.fetchNotifications.bind(this);
  }

  async componentDidMount() {
    await this.fetchNotifications();
  }

  async fetchNotifications() {
    let includeUnopenedFamilies = true
    try {
      let notifications = await notifications_api.get_clinician_notifications(
        this.props.clinician_id,
        ["pending"],
        ["proband_disconnected"],
        includeUnopenedFamilies
      );

      this.setState({ notifications: notifications });
    } catch (error) {
      console.log(error);
    }
  }

  async dismissAll() {
    if(this.state.loading) return;
    try {

      this.setState({ loading: true });
      let clinician_id = this.props.clinician_id;
      await notifications_api.post_notification_notificationid_dismiss_all({clinician_id});
      await this.fetchNotifications();
      await this.props.fetchNotificationCounts();

    } catch (error) {
      this.setState({ errorMessages: [error.message] });
      helper_error.log_error(this.constructor.name, error);
    } finally {
      this.setState({ loading: false });
    }
  }

  async dismissItem(notification_id) {
    try {

      this.setState({ loading: true });
      let clinician_id = this.props.clinician_id;
      let payload = { clinician_id };
      await notifications_api.post_notification_notificationid_dismiss(notification_id, payload);
      await this.fetchNotifications();
      await this.props.fetchNotificationCounts();

    } catch (error) {
      this.setState({ errorMessages: [error.message] });
      helper_error.log_error(this.constructor.name, error);
    } finally {
      this.setState({ loading: false });
    }
  }

  handleShowMoreClick() {
    let disconnectedDisplayCount = this.state.disconnectedDisplayCount;
    disconnectedDisplayCount += 10;
    this.setState({disconnectedDisplayCount})
  }

  render() {
    // disconnected.sort((a,b) => moment(b.modified_at).diff(moment(a.modified_at)));

    let options = {year: 'numeric', month: 'long', day: 'numeric' };
    let disconnected = this.state.notifications.map(notification => {
      return (
        <div className="notif-item">
          <a href="#" className="del" id={notification.id} onClick={(e) => this.dismissItem(e.target.id)}>
            <i className="fa fa-trash" id={notification.id}></i>
          </a>
          <p>
            <strong>{notification.message_body}</strong>
          </p>
          <p>
            <span className="date">{new Date(notification.created_at).toLocaleDateString(undefined, options)}</span>
          </p>
        </div>
      )
    });
    return (
      <div className={"tab-pane fade in active"}>

        <ErrorSummary errorMessages={this.state.errorMessages} />

        <ActivityIndicator loading={this.state.loading} />

        <p className="subtitle">
          The following patients have removed your organization from receiving
          updates.
        </p>

        { disconnected.length > 0 && (
        <div className=" margin-four-bottom">
          <a href="#" className="link" onClick={() => this.dismissAll()}>
            Dismiss all
          </a>
        </div>
        )}

        {disconnected.slice(0, this.state.disconnectedDisplayCount)}

        { disconnected.length > this.state.disconnectedDisplayCount &&
          <div className=" margin-four-bottom">
            <a onClick={this.handleShowMoreClick} className="link">Show More<i className="fa fa-angle-down"></i></a>
          </div>
        }
      </div>
    );
  }
}

export default NotificationDisconnected;
