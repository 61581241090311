import React, { Component } from 'react';
import { connect } from "react-redux";
import lodash from 'lodash';
import route_helper from '../../route-helper';


class RecentSearchList extends Component {
  constructor(props) {
    super(props);
    this.handleItemClicked = this.handleItemClicked.bind(this);
    this.clearLastSearch = this.clearLastSearch.bind(this);
  }

  handleItemClicked(event) {
    let search_text = event.target.innerText.trim();
    this.props.onSearchFilterClicked(search_text);
  }

  clearLastSearch(event) {
    let search_history = lodash.cloneDeep(this.props.searchHistory);
    let deleteValue = search_history.find((search) => {
      return search.search_text === event.target.parentNode.parentNode.innerText
    });
    search_history.splice(search_history.indexOf(deleteValue), 1);
    this.props.onDeleteSearchEntry(search_history);
  }

  render() {
    let class_name = 'search-suggest search-suggest-hidden';
    if(this.props.showRecentSearchList) {
      class_name = 'search-suggest search-suggest-show';
    }

    // sanatize the recent search history of search consisting of a blank search term
    let search_values = [];
    let search_history = lodash.cloneDeep(this.props.searchHistory);
    search_history.reverse().forEach((search) => {
      search_values.push(search.search_text);
    });

    const compacted_search_list = lodash.compact(search_values);

    const search_list = lodash.uniq(compacted_search_list);

    let key_count = 0;
    const listItems = search_list.map((search) => {
        key_count += 1;
         return (
         <li className={'search-suggest-item'} key={key_count} onClick={this.handleItemClicked}>
          {search}
          <a onClick={this.clearLastSearch}><i className="search-suggest-show fa fa-times"></i></a>
        </li>);
      }
    );

    let el = null;
    let sty = null;
    try {
      el = document.getElementById("search_text");
      sty = {
        position: "absolute",
        top: `${el.getBoundingClientRect().y - 15}px`,
      };
    } catch (e) {}
    return (
      <ul style={sty} className={class_name}>{listItems}</ul>
    );
  }
}

const redux_state = state => ({
    searchHistory: state.search.searchHistory
});

export default connect(redux_state, null)(RecentSearchList);
