import React from "react";

import { createUUID } from "../../react-flow-pedigree/utils";
import {
  PendingNotificationItem,
  CompletedNotificationItem
} from "./notification-items";

const TabAll = props => {
  let {active, notifications, activeNotificationId, onAccept, onDecline, onAcceptAll, pending_length} = props
  let active_class = active ? ' in active' : '';
  return (
    <div className={"tab-pane fade " + active_class}>
      <div className="row patient-notif-accept-all">
        {pending_length >= 1 && (
          <a
            href="#"
            className="btn btn-light-outline"
            onClick={() => onAcceptAll()}
          >
            Accept All <i className="fa fa-check-square"></i>
          </a>
        )}

        <span className='i-tooltip'>
          <i className="fa fa-info-circle"></i>
          <span className="i-tooltiptext">
            If you accept, your record will be updated. If you decline, The patient's record will still 
            reflect the change but yours will not. The patient will not be notified of your choice.
          </span>
        </span>
      </div>

      {notifications.map((n, index) => {

        if(n.status === 'pending') {
          return <PendingNotificationItem
            key={n.id}
            activeNotificationId={activeNotificationId}
            notification={n}
            onAccept={onAccept}
            onDecline={onDecline}
          />
        } else {
          return <CompletedNotificationItem key={index} notification={n} />
        }

      })}
    </div>
  );
};

export default TabAll;
