import React, { Component } from "react";
import i18n from 'i18next';

class LabelField extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const translation_key = this.props.masterQuestion.translation_key + ".label";
    let question_label_translation = i18n.t(translation_key);
    if (question_label_translation === translation_key) {
      question_label_translation = this.props.masterQuestion.question_label;
    }

    return (
      <div className="row">

        <div className="col-md-12">
          <label className="clinician-survey-label">
            {question_label_translation}
          </label>
        </div>

      </div>
    );

  }
}

export default LabelField;
