import React, { Component } from 'react';
import ReactDOM from 'react-dom';

class ModalResetPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      current_password: '',
      new_password: '',
      confirm_password: ''
    };

    this.onClickSave = this.onClickSave.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onClickSave(event) {
    this.props.onClickSave(this.state);
    this.props.onClose();
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const error = this.props.errorMessage;
    const helpText = this.props.helpText;

    return ReactDOM.createPortal(
      <div onClick={() => this.props.onClose()}
        style={{ display: 'block', paddingLeft: '12px' }}
        className="modal fade in"
        role="dialog"
        aria-labelledby="reset-password"
        id="reset-password">

        <div onClick={(e) => e.stopPropagation()} className="modal-dialog" role="document">
          <div className="modal-content">

            <div className="modal-header">
              <button onClick={() => this.props.onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="fa fa-close"></i>
              </button>
              <h4 className="modal-title text-white text-capitalize">Reset Password</h4>
            </div>

            {error && (
              <div className="alert alert-danger">
                {error}
              </div>)
            }

            {helpText && (
              <div className="alert alert-warning">
                {helpText}
              </div>)
            }

            <div className="modal-body">
              <form className="form-horizontal form-label-left form-custom">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-4 col-sm-4 col-xs-12">
                      <label htmlFor="current_password" className="control-label">Current Password</label>
                    </div>
                    <div className="col-md-7 col-sm-9 col-xs-12">
                      <input type="password" className="form-control normal-input-text" name="current_password" id="current_password" onChange={this.onChange} value={this.state.current_password} />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-4 col-sm-4 col-xs-12">
                      <label htmlFor="new_password" className="control-label">New Password</label>
                    </div>
                    <div className="col-md-7 col-sm-9 col-xs-12">
                      <input type="password" className="form-control normal-input-text" name="new_password" id="new_password" onChange={this.onChange} value={this.state.new_password} />
                    </div>
                  </div>
                </div>

                <div className="form-group ">
                  <div className="row">
                    <div className="col-md-4 col-sm-4 col-xs-12">
                      <label htmlFor="confirm_password" className="control-label">Confirm New Password</label>
                    </div>
                    <div className="col-md-7 col-sm-9 col-xs-12">
                      <input type="password" className="form-control normal-input-text" name="confirm_password" id="confirm_password" onChange={this.onChange} value={this.state.confirm_password} />
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="modal-footer">
              <button
                onClick={() => this.props.onClose()}
                type="button" className="btn btn-light-outline no-margin-right" data-dismiss="modal">Cancel</button>
              <button
                onClick={this.onClickSave}
                type="button" className="btn btn-dark">Update</button>
            </div>
          </div>
        </div>
      </div>,
      document.body
    );
  }

}

export default ModalResetPassword;
