import React, { Component } from 'react'
import { debounce } from 'lodash'
import { log_error } from '../../helpers/helper-error'


import * as helper_family_tree from '../../helpers/helper-family-tree'
import HealthInputs from './health-inputs';

import family_api from '../../api/family-api';
import helpers from '../../helpers';
import ErrorSummary from '../error-summary';

class FamilyFatherMother extends Component {

  constructor(props) {
    super(props)
    this.state = {
      first_name: null
    }
    this.updateFirstName = debounce(this.updateFirstName, 250)
  }

  componentDidUpdate(prevPros) {
    let first_name = this.getFirstName();
    if(first_name == null) return
    if(this.state.first_name != null) return
    this.setState({first_name})
  }

  async onChangeName(first_name) {
    try {
      this.setState({first_name})

      let profile = { rkey: this.props.father_mother.rkey, first_name }
      helper_family_tree.saveProfileToRedux(this.props.dispatch, profile)
      this.updateFirstName(first_name)

    } catch (error) {
      log_error(this.constructor.name, error)
    }
  }

  async updateFirstName(first_name) {
    try {
      this.setState({errorMessages: []})

      let member = this.props.father_mother
      await family_api.patch_member_memberid(member.id, {
        first_name
      })
    } catch (error) {
      this.setState({errorMessages: [error.message] })
    }
  }

  getFirstName() {

    let profile = this.getProfile();
    if(profile == null) return null;

    return profile.first_name;
  }

  getProfile() {
    return helper_family_tree.getProfileFromRedux(this.props.patient, this.props.father_mother.rkey)
  }


  render() {

    return <React.Fragment>

      <tr>
        <td>

        </td>
        <td className="td--nowrap">

          <span className='text--capitalize'>
          { this.props.label }
          </span>

        </td>
        <td>
          <input
            onChange={(e) => this.onChangeName(e.target.value)}
            value={ this.getFirstName() }
            type="text" className="inputreg form-control normal-input-text"/>
        </td>

        <HealthInputs
          for="father_mother"
          ownerRkey={this.props.father_mother.rkey}
          collapsible={false}
          collapsed={false}
          onCollapse={() => null }
          canDelete={false}
          profile={this.getProfile()}
          patient={this.props.patient}
          dispatch={this.props.dispatch}
        />

      </tr>

    </React.Fragment>

  }
}

export default FamilyFatherMother;
