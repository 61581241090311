import React, { Component } from 'react'
import uniqid from 'uniqid'
import Select from 'react-select'
import { fromJS, updateIn, update } from 'immutable'

// import FamilyPartnersTwinModal from './family-partners-twin-modal'
// import FamilyPartnersAdoptedModal from './family-partners-adopted-modal'


import FamilyTreeUncleAunt from './uncle-aunt'

class FamilyTreeProbandUnclesAunts extends Component {

  render() {

    let depth_label = " ";

    if(this.props.level) {
      if(this.props.level === 2) {
        depth_label = " Grand "
      } else if (this.props.level === 3) {
        depth_label = " Great Grand ";
      } else if (this.props.level > 3) {
        depth_label = " ";
        for(let i = 3; i < this.props.level + 1; i++) depth_label += "Gr "
        depth_label += "Grand "
      }
    }

    for(let u_a of this.props.uncles_aunts) {
      u_a.gender = (u_a.gender == null) ? 'u' : u_a.gender
    }

    return <React.Fragment>

      {
        this.props.uncles_aunts.filter(i => i.gender_identity ? i.gender_identity == 'male' && i.half == true : i.gender.toLowerCase() == 'm' && i.half == true)
        .map((uncle_aunt, index) => {
          var label = this.props.parent_side + depth_label + 'Half-Uncle #' + (index + 1)
          if (uncle_aunt.gender_identity) {
            if (uncle_aunt.gender && uncle_aunt.gender.toLowerCase() == 'f') {
              label = label + ' (AFAB)'
            } else if (uncle_aunt.gender && uncle_aunt.gender.toLowerCase() == 'u') {
              label = label + ' (AUAB)'
            }
          }
          return <FamilyTreeUncleAunt
            key={uncle_aunt.rkey}
            parent_side={this.props.parent_side}
            collapsed={true}
            label={label}
            level={this.props.level}
            uncle_aunt={uncle_aunt}
            patient={this.props.patient}
            dispatch={this.props.dispatch}
            deleteMember={this.props.deleteMember}
          />
        })
      }

      {
        this.props.uncles_aunts.filter(i => i.gender_identity ? i.gender_identity == 'female' && i.half == true : i.gender.toLowerCase() == 'f' && i.half == true)
        .map((uncle_aunt, index) => {
          var label = this.props.parent_side + depth_label + 'Half-Aunt #' + (index + 1)
          if (uncle_aunt.gender_identity) {
            if (uncle_aunt.gender && uncle_aunt.gender.toLowerCase() == 'm') {
              label = label + ' (AMAB)'
            } else if (uncle_aunt.gender && uncle_aunt.gender.toLowerCase() == 'u') {
              label = label + ' (AUAB)'
            }
          }
          return <FamilyTreeUncleAunt
            key={uncle_aunt.rkey}
            parent_side={this.props.parent_side}
            collapsed={true}
            label={label}
            level={this.props.level}
            uncle_aunt={uncle_aunt}
            patient={this.props.patient}
            dispatch={this.props.dispatch}
            deleteMember={this.props.deleteMember}
          />
        })
      }

      {
        this.props.uncles_aunts.filter(i => i.gender_identity ? i.gender_identity == 'non-binary' && i.half == true : i.gender.toLowerCase() == 'u' && i.half == true)
        .map((uncle_aunt, index) => {
          var label = this.props.parent_side + depth_label + 'Half-Aunt/Uncle #' + (index + 1)
          if (uncle_aunt.gender_identity) {
            if (uncle_aunt.gender && uncle_aunt.gender.toLowerCase() == 'm') {
              label = label + ' (AMAB)'
            } else if (uncle_aunt.gender && uncle_aunt.gender.toLowerCase() == 'f') {
              label = label + ' (AFAB)'
            }
          }
          return <FamilyTreeUncleAunt
            key={uncle_aunt.rkey}
            parent_side={this.props.parent_side}
            collapsed={true}
            label={label}
            level={this.props.level}
            uncle_aunt={uncle_aunt}
            patient={this.props.patient}
            dispatch={this.props.dispatch}
            deleteMember={this.props.deleteMember}
          />
        })
      }

      {
        this.props.uncles_aunts.filter(i => i.gender_identity ? i.gender_identity == 'male' && i.half == false : i.gender.toLowerCase() == 'm' && i.half == false)
        .map((uncle_aunt, index) => {
          var label = this.props.parent_side + depth_label + 'Uncle #' + (index + 1)
          if (uncle_aunt.gender_identity) {
            if (uncle_aunt.gender && uncle_aunt.gender.toLowerCase() == 'u') {
              label = label + ' (AUAB)'
            } else if (uncle_aunt.gender && uncle_aunt.gender.toLowerCase() == 'f') {
              label = label + ' (AFAB)'
            }
          }
          return <FamilyTreeUncleAunt
            key={uncle_aunt.rkey}
            parent_side = {this.props.parent_side}
            collapsed={true}
            label={label}
            level={this.props.level}
            uncle_aunt={uncle_aunt}
            patient={this.props.patient}
            dispatch={this.props.dispatch}
            deleteMember={this.props.deleteMember}
          />
        })
      }

      {
        this.props.uncles_aunts.filter(i => i.gender_identity ? i.gender_identity == 'female' && i.half == false : i.gender.toLowerCase() == 'f' && i.half == false)
        .map((uncle_aunt, index) => {
          var label = this.props.parent_side + depth_label + 'Aunt #' + (index + 1)
          if (uncle_aunt.gender_identity) {
            if (uncle_aunt.gender && uncle_aunt.gender.toLowerCase() == 'u') {
              label = label + ' (AUAB)'
            } else if (uncle_aunt.gender && uncle_aunt.gender.toLowerCase() == 'm') {
              label = label + ' (AMAB)'
            }
          }
          return <FamilyTreeUncleAunt
            key={uncle_aunt.rkey}
            parent_side = {this.props.parent_side}
            collapsed={true}
            label={label}
            level={this.props.level}
            uncle_aunt={uncle_aunt}
            patient={this.props.patient}
            dispatch={this.props.dispatch}
            deleteMember={this.props.deleteMember}
          />
        })
      }

      {
        this.props.uncles_aunts.filter(i => i.gender_identity ? i.gender_identity == 'non-binary' && i.half == false : i.gender.toLowerCase() == 'u' && i.half == false)
        .map((uncle_aunt, index) => {
          var label = this.props.parent_side + depth_label + 'Aunt/Uncle #' + (index + 1)
          if (uncle_aunt.gender_identity) {
            if (uncle_aunt.gender && uncle_aunt.gender.toLowerCase() == 'f') {
              label = label + ' (AFAB)'
            } else if (uncle_aunt.gender && uncle_aunt.gender.toLowerCase() == 'm') {
              label = label + ' (AMAB)'
            }
          }
          return <FamilyTreeUncleAunt
            key={uncle_aunt.rkey}
            parent_side = {this.props.parent_side}
            collapsed={true}
            label={label}
            level={this.props.level}
            uncle_aunt={uncle_aunt}
            patient={this.props.patient}
            dispatch={this.props.dispatch}
            deleteMember={this.props.deleteMember}
          />
        })
      }

    </React.Fragment>

  }
}


export default FamilyTreeProbandUnclesAunts
