import React, { Component } from 'react';

class NccnCriteria extends Component {
  constructor(props) {
    super(props);

    this.state = { showCriteria: false };

    this.toggleShowCriteria  = this.toggleShowCriteria.bind(this);
  }

  toggleShowCriteria(show_criteria) {
    this.setState({ showCriteria: !this.state.showCriteria });
  }

  render() {
    const carat = (this.state.showCriteria) ? <i className="fa fa-chevron-down"></i> : <i className="fa fa-chevron-right"></i>
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-10 col-sm-10 col-xs-12">
            <h4>{this.props.header} - <span>Meets Criteria</span></h4>
          </div>

          <div className="col-md-2 col-sm-2 col-xs-12">
            <a onClick={this.toggleShowCriteria}>{carat}</a>
          </div>
        </div>
        {this.state.showCriteria && (
          <ul className="flat-list text-med">{this.props.criteria}</ul>
        )}
      </React.Fragment>
    );
  }
}

export default NccnCriteria;
