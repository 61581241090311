import React, { Component } from "react";

class NotificationMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedItem: 'connection-request'
    }
  }

  onMenuClick(item) {
    this.setState({selectedItem: item});
    this.props.onMenuClick(item)
  }

  isActive(item) {
    return this.state.selectedItem == item ? 'active' : ''
  }

  render() {
    let { notification_counts } = this.props;
    return (
      <div className="tabs">
        <ul
          className="nav nav-pills nav-stacked nav-pills-unstyled"
        >
          <li className={this.isActive('connection-request')}>
            <a onClick={() => this.onMenuClick('connection-request')}>
              Connection Request&nbsp;({notification_counts["clinician_requests"]})
            </a>
          </li>
          <li className={this.isActive('patient-updates')}>
            <a onClick={() => this.onMenuClick('patient-updates')}>
              Patient Updates ({notification_counts["patient_updates"]})
            </a>
          </li>
          <li className={this.isActive('disconnected')}>
            <a onClick={() => this.onMenuClick('disconnected')}>
              Disconnected ({notification_counts["disconnected_requests"]})
            </a>
          </li>
          <li className={this.isActive('other')}>
            <a onClick={() => this.onMenuClick('other')}>
              Other (0)
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default NotificationMenu;
