import * as patient_actions from '../store/patient/actions';
import risk_api from '../api/risk-api';
import { cloneDeep } from 'lodash';

import RiskAPI from "../api/risk-algorithm-api";
const riskAPI = new RiskAPI();

const unKnownGenderWarningMessage = ['Unknown sex is not supported. Individuals with unknown sex and their descendants were excluded from analysis. This could impact results.']
const BDAY_REGEX = /^[1-2]{1}[0-9]{3}[-][0-1]{1}[0-9]{1}[-][0-3]{1}[0-9]{1}$/g;

const isValidString = (value) => {
  if (value === null || value === undefined || typeof (value) !== 'string' || value.trim() === '') { return false; }
  return true;
}

const isValidNumber = (value) => {
  if (isNaN(parseInt(value, 10)) || value < 0) { return false; }
  return true;
}

const catchIdenticalTwinsError = (getPedigreeData) => {
    let people = Object.values(getPedigreeData().getAllProfiles());
    let peopleWithTwins = people.filter(person => person.twin_id)
    let twin_sets = getTwinSets(peopleWithTwins)
    twin_sets = Object.values(twin_sets)
    for(let set of twin_sets){
      let members = cloneDeep(set['members'])
      if(members[0].twin_type == 'identical'){
        let members_yob = Number(members[0].yob)
        let members_with_same_yob = members.filter(member => Number(member.yob) === Number(members_yob))
        let identical_twins_ages_are_equal = members_with_same_yob.length == set['members'].length
        if(!identical_twins_ages_are_equal){
          let error = "Identical twins cannot have different years of birth."
          return error;
        }
        else{
          return null;
        }
      }
    }
}

const getTwinSets = (nodes) => {
  let sets = {}
  // Arrage twin into sets
  for(var node of nodes) {
    if(node.twin_set === null) continue;

    if(node.twin_set in sets) {
      sets[node.twin_set].members.push(node)
    } else {
      sets[node.twin_set] = {members: [node]}
    }
  }

  return sets;
}

const runBoadicea = async (dispatch, proband_id, getPedigreeData, timeStamp, callback) => {

  let runTimeErrors = {
    errors: [],
    warnings: []
  }

  riskAPI.runBoadicea({ proband_id, timeStamp })
  .then((response) => {

    let risk_results = response.data;

    // interpret risk results
    if(risk_results.pedigree_result !== null &&
        risk_results.pedigree_result !== undefined &&
        Array.isArray(risk_results.pedigree_result)) {

      let data = {
        breast: {fiveYear: 'N/A', lifetime: 'N/A'},
        ovarian: {fiveYear: 'N/A', lifetime: 'N/A'},
        cancer_risks: [],
        population_cancer_risks: [],
        mutation_probabilities: [
          {"no mutation": {decimal: "", percent: "N/A"}},
          {BRCA1: {decimal: "", percent: "N/A"}},
          {BRCA2: {decimal: "", percent: "N/A"}},
          {PALB2: {decimal: "", percent: "N/A"}},
          {CHEK2: {decimal: "", percent: "N/A"}},
          {ATM: {decimal: "", percent: "N/A"}}
        ]
      };
      let pedigree_result = risk_results.pedigree_result;
      let proband_result  = pedigree_result[0];

     if(risk_results.unknown_gender){
      runTimeErrors.warnings = unKnownGenderWarningMessage
     }
      if(proband_result.baseline_cancer_risks !== null &&
          proband_result.baseline_cancer_risks !== undefined &&
          Array.isArray(proband_result.baseline_cancer_risks)) {

        data.population_cancer_risks = proband_result.baseline_cancer_risks;
      }

      if(proband_result.cancer_risks !== null &&
          proband_result.cancer_risks !== undefined &&
          Array.isArray(proband_result.cancer_risks)) {


        let cancer_risks = proband_result.cancer_risks;
        data.cancer_risks = cancer_risks;

        if (cancer_risks.length >= 5) {
          let five_yr_cancer_risk = cancer_risks[4];
          data.breast.fiveYear = five_yr_cancer_risk['breast cancer risk'].percent;
          data.ovarian.fiveYear = five_yr_cancer_risk['ovarian cancer risk'].percent;
        }

        let lifetime_cancer_risk = cancer_risks[cancer_risks.length-1];
        data.breast.lifetime = lifetime_cancer_risk['breast cancer risk'].percent;
        data.ovarian.lifetime = lifetime_cancer_risk['ovarian cancer risk'].percent;
      }

      if(proband_result.mutation_probabilties !== null &&
          proband_result.mutation_probabilties !== undefined &&
          Array.isArray(proband_result.mutation_probabilties)) {

        data.mutation_probabilities = proband_result.mutation_probabilties; // they misspelled probabilities at boadicea
      }

      let payload = {};
      payload.dataKey = 'boadicea';
      payload.data = data;
      dispatch(patient_actions.save_risk_results(payload));
      if(getPedigreeData !== undefined && getPedigreeData !== null){
        let deep_clone = cloneDeep(getPedigreeData().risk_results);
        deep_clone['boadicea'] = data;
        getPedigreeData().setRiskResults(deep_clone);
      }

      callback(runTimeErrors);
    } else {
      // An error has been returned
      // risk_results["Cancer Error"] !== null && risk_results["Cancer Error"] !== undefined)
      // risk_results["Pedigree Error"] !== null && risk_results["Pedigree Error"] !== undefined
      // risk_results["Model Error"] !== null && risk_results["Model Error"] !== undefined
      // risk_results["Person Error"] !== null && risk_results["Person Error"] !== undefined)
      // risk_results["Genetic Test Error"] !== null && risk_results["Genetic Test Error"] !== undefined)
      // risk_results["Pathology Error"] !== null && risk_results["Pathology Error"] !== undefined)
      let errors = risk_results ? Object.values(risk_results) : []
      errors = errors.filter(error => typeof error == 'string')

      for(let i = 0; i < errors.length; i++){
        if(errors[i].includes('MZ twins must have the same year of birth')){
          errors[i] = "Identical twins cannot have different years of birth."
        }
      }

      runTimeErrors.errors = errors;
      callback(runTimeErrors);
    }
  }).catch((error) => {
    //check here if it's caused by twins not having the same year of birth
    let errors = [];

    let twinError = catchIdenticalTwinsError(getPedigreeData);
    if(twinError){
      errors.push(twinError);
    }

    if (error) {
      // iterate through error dictionary and add messages to error array
      for (let key in error) {
        errors.push(error[key])
      }
    } else {
      if(errors.length === 0){
        errors.push("Error: Could not run");
      }
    }

    runTimeErrors.errors = errors;
    callback(runTimeErrors);
  });
}

const runClaus = async (dispatch, proband_id, getPedigreeData, timeStamp, callback) => {

  riskAPI.runClaus({ proband_id, timeStamp })
  .then((response) => {

    let risk_results = response.data;
    if(risk_results.life_time_risk !== null &&
        risk_results.life_time_risk !== undefined) {

      let data = {
        breast: {fiveYear: 'N/A', lifetime: 'N/A'},
        cancer_risks: []
      };

      if (risk_results.five_year_risk !== null &&
          risk_results.five_year_risk !== undefined) {

          data.breast.fiveYear = risk_results.five_year_risk;
      }

      data.breast.lifetime = risk_results.life_time_risk;
      data.cancer_risks = risk_results.cancer_risks;

      let payload = {};
      payload.dataKey = 'claus';
      payload.data = data;
      //just like in here that the results are saved on redux, when on DOM, save it on pedigree data store
      dispatch(patient_actions.save_risk_results(payload));
      if(getPedigreeData !== undefined && getPedigreeData !== null){
        let deep_clone = cloneDeep(getPedigreeData().risk_results);
        deep_clone['claus'] = data;
        getPedigreeData().setRiskResults(deep_clone)
      }
      callback([]); // return no errors
    } else {
      callback([risk_results]); // return error array
    }

  }).catch((error) => {
     callback([error]);
  });
}

const runGail = async (dispatch, proband_id, getPedigreeData, timeStamp, callback) => {

  riskAPI.runGail({ proband_id, timeStamp })
  .then((response) => {

    let risk_results = response.data;
    if(risk_results.abs_five_year_risk !== null &&
        risk_results.abs_five_year_risk !== undefined &&
        risk_results.abs_life_time_risk !== null &&
        risk_results.abs_life_time_risk !== undefined &&
        risk_results.avg_five_year_risk !== null &&
        risk_results.avg_five_year_risk !== undefined &&
        risk_results.avg_life_time_risk !== null &&
        risk_results.avg_life_time_risk !== undefined) {

      let data = {
        breast: {fiveYear: 'N/A', lifetime: 'N/A'},
        cancer_risks: []
      };

      data.breast.fiveYear = risk_results.abs_five_year_risk;
      data.breast.lifetime = risk_results.abs_life_time_risk;
      data.cancer_risks = risk_results.n_year_risk;

      let payload = {};
      payload.dataKey = 'gail';
      payload.data = data;
      dispatch(patient_actions.save_risk_results(payload));
      if(getPedigreeData !== undefined && getPedigreeData !== null){
        let deep_clone = cloneDeep(getPedigreeData().risk_results);
        deep_clone['gail'] = data;
        getPedigreeData().setRiskResults(deep_clone)
      }
      callback([]); // return no errors
    } else {
      callback([risk_results]); // return error array
    }

  }).catch((error) => {
     let runtimeError = error.length > 0 ? error : "Could not run"
     callback([runtimeError]);
  });
}

const runTyrerCuzick = async (dispatch, proband_id, competing_mortality, getPedigreeData, timeStamp, callback) => {

  riskAPI.runTyrerCuzick({ proband_id, competing_mortality, timeStamp })
  .then((response) => {

    let risk_results = response.data;
    if(risk_results.cancer_risks.length > 0) {
      let data = {
        breast: {fiveYear: 'N/A', lifetime: 'N/A'},
        cancer_risks: [],
        mutation_probabilities: [
          {"no mutation": {decimal: "", percent: "N/A"}},
          {BRCA1: {decimal: "", percent: "N/A"}},
          {BRCA2: {decimal: "", percent: "N/A"}}
        ]
      };

      data.breast.fiveYear = (100.0 * parseFloat(risk_results.cancer_risks[0].breast.decimal)).toFixed(2);
      data.breast.lifetime = (100.0 * parseFloat(risk_results.cancer_risks[risk_results.cancer_risks.length-1].breast.decimal)).toFixed(2);
      data.cancer_risks = risk_results.cancer_risks;

      let brca1 = parseFloat(risk_results.mutationRiskBRCA1);
      let brca2 = parseFloat(risk_results.mutationRiskBRCA2);
      data.mutation_probabilities[1].BRCA1.percent = (isNaN(brca1)) ? "N/A" : (100.0 * brca1).toFixed(2);
      data.mutation_probabilities[2].BRCA2.percent = (isNaN(brca2)) ? "N/A" : (100.0 * brca2).toFixed(2);

      let payload = {};
      payload.dataKey = 'tyrer_cuzick';
      payload.data = data;
      dispatch(patient_actions.save_risk_results(payload));
      if(getPedigreeData !== undefined && getPedigreeData !== null){
        let deep_clone = cloneDeep(getPedigreeData().risk_results);
        deep_clone['tyrer_cuzick'] = data;
        getPedigreeData().setRiskResults(deep_clone)
      }
      callback([]); // return no errors
    } else {
      callback(["No risk results for Tyrer-Cuzick"]); // return error array
    }

  }).catch((error) => {
    const proband = getPedigreeData !== undefined && getPedigreeData !== null ? getPedigreeData().proband : {};

    const bday = proband.dob;
    const age = proband.age;

    if (!isValidString(bday) || bday.trim().match(BDAY_REGEX).length === 0) {
      if (!isValidNumber(age)) {
        callback(["Could not run.  Missing proband age."]);
        return
      }
    }
    callback(["Could not run"]);
  });
}

const runBayesMendelBrcaPro = async (dispatch, proband_id, getPedigreeData, timeStamp, callback) => {
  let runTimeErrors = {
    errors: [],
    warnings: []
  }

  riskAPI.runBRCAPRO({ proband_id, timeStamp })
  .then((response) => {

    let risk_results = response.data;
    if(risk_results.cancer_risks.length > 0 || risk_results.probabilities.length > 0) {
      let data = {
        breast: {fiveYear: 'N/A', lifetime: 'N/A'},
        ovarian: {fiveYear: 'N/A', lifetime: 'N/A'},
        cancer_risks: [],
        mutation_probabilities: [
          {"no mutation": {decimal: "", percent: "N/A"}},
          {BRCA1: {decimal: "", percent: "N/A"}},
          {BRCA2: {decimal: "", percent: "N/A"}}
        ],
      };

      if(risk_results.unknown_gender){
        runTimeErrors.warnings = unKnownGenderWarningMessage
      }
      const five_year_age = risk_results.proband_age + 5;
      const breast_five_year_risk = risk_results.cancer_risks.find(r => r.age === five_year_age);
      if (breast_five_year_risk) {
        data.breast.fiveYear = parseFloat(breast_five_year_risk.breast.percent).toString().substring(0, 4);
      }

      const breast_lifetime_risk = risk_results.cancer_risks[risk_results.cancer_risks.length-1];
      if (breast_lifetime_risk) {
        data.breast.lifetime = parseFloat(breast_lifetime_risk.breast.percent).toString().substring(0, 4);
      }

      const ovarian_five_year_risk = risk_results.cancer_risks.find(r => r.age === five_year_age);
      if (ovarian_five_year_risk) {
        data.ovarian.fiveYear = parseFloat(ovarian_five_year_risk.ovarian.percent).toString().substring(0, 4);
      }

      const ovarian_lifetime_risk = risk_results.cancer_risks[risk_results.cancer_risks.length-1];
      if (ovarian_lifetime_risk) {
        data.ovarian.lifetime = parseFloat(ovarian_lifetime_risk.ovarian.percent).toString().substring(0, 4);
      }

      data.cancer_risks = risk_results.cancer_risks;

      let brca1 = risk_results.probabilities[0];
      if (brca1) {
        data.mutation_probabilities[1].BRCA1.percent = parseFloat(brca1["BRCA1"].percent).toString().substring(0, 4);
      }

      let brca2 = risk_results.probabilities[0];
      if (brca2) {
        data.mutation_probabilities[2].BRCA2.percent = parseFloat(brca2["BRCA2"].percent).toString().substring(0, 4);
      }

      let payload = {};
      payload.dataKey = 'brcapro';
      payload.data = data;
      dispatch(patient_actions.save_risk_results(payload));
      if(getPedigreeData !== undefined && getPedigreeData !== null){
        let deep_clone = cloneDeep(getPedigreeData().risk_results);
        deep_clone['brcapro'] = data;
        getPedigreeData().setRiskResults(deep_clone)
      }
      callback(runTimeErrors); // return no errors
    } else {
      runTimeErrors.errors = ["No risk results for BRCAPRO"];
      callback(runTimeErrors); // return error array
    }

  }).catch((error) => {
    runTimeErrors.errors = ["Could not run"];

    callback(runTimeErrors);
  });
}

const runBayesMendelMmrPro = async (dispatch, proband_id, getPedigreeData, timeStamp, callback) => {
  let runTimeErrors = {
    errors: [],
    warnings: []
  }

  riskAPI.runMMRPRO({ proband_id, timeStamp })
  .then((response) => {

    let risk_results = response.data;
    if(risk_results.cancer_risks.length > 0 || risk_results.probabilities.length > 0 )  {
      let data = {
        colon: {fiveYear: 'N/A', lifetime: 'N/A'},
        uterine_endometrial: {fiveYear: 'N/A', lifetime: 'N/A'},
        cancer_risks: [],
        mutation_probabilities: [
          {"no mutation": {decimal: "", percent: "N/A"}},
          {MLH1: {decimal: "", percent: "N/A"}},
          {MSH2: {decimal: "", percent: "N/A"}},
          {MSH6: {decimal: "", percent: "N/A"}}
        ]
      };

      if(risk_results.unknown_gender){
        runTimeErrors.warnings = unKnownGenderWarningMessage
      }

      const five_year_age = risk_results.proband_age + 5;
      const colon_five_year_risk = risk_results.cancer_risks.find(r => r.age === five_year_age);
      if (colon_five_year_risk) {
        data.colon.fiveYear = parseFloat(colon_five_year_risk.colon.percent).toString().substring(0, 4);
      }

      const colon_lifetime_risk = risk_results.cancer_risks[risk_results.cancer_risks.length-1];
      if (colon_lifetime_risk) {
        data.colon.lifetime = parseFloat(colon_lifetime_risk.colon.percent).toString().substring(0, 4);
      }

      const uterine_endometrial_five_year_risk = risk_results.cancer_risks.find(r => r.age === five_year_age);
      if (uterine_endometrial_five_year_risk) {
        data.uterine_endometrial.fiveYear = parseFloat(uterine_endometrial_five_year_risk.uterine_endometrial.percent).toString().substring(0, 4);
      }

      const uterine_endometrial_lifetime_risk = risk_results.cancer_risks[risk_results.cancer_risks.length-1];
      if (uterine_endometrial_lifetime_risk) {
        data.uterine_endometrial.lifetime = parseFloat(uterine_endometrial_lifetime_risk.uterine_endometrial.percent).toString().substring(0, 4);
      }

      data.cancer_risks = risk_results.cancer_risks;

      let mlh1 = risk_results.probabilities[0];
      if (mlh1) {
        data.mutation_probabilities[1].MLH1.percent = parseFloat(mlh1["MLH1"].percent).toString().substring(0, 4);
      }

      let msh2 = risk_results.probabilities[0];
      if (msh2) {
        data.mutation_probabilities[2].MSH2.percent = parseFloat(msh2["MSH2"].percent).toString().substring(0, 4);
      }

      let msh6 = risk_results.probabilities[0];
      if (msh6) {
        data.mutation_probabilities[3].MSH6.percent = parseFloat(msh6["MSH6"].percent).toString().substring(0, 4);
      }

      let payload = {};
      payload.dataKey = 'mmrpro';
      payload.data = data;
      dispatch(patient_actions.save_risk_results(payload));
      if(getPedigreeData !== undefined && getPedigreeData !== null){
        let deep_clone = cloneDeep(getPedigreeData().risk_results);
        deep_clone['mmrpro'] = data;
        getPedigreeData().setRiskResults(deep_clone)
      }
       callback(runTimeErrors); // return no errors
    } else {
      runTimeErrors.errors = ["No risk results for MMRPRO"];
      callback(runTimeErrors); // return error array
    }

  }).catch((error) => {
    runTimeErrors.errors = ["Could not run"];

    callback(runTimeErrors);
  });
}

const runBayesMendelPancPro = async (dispatch, proband_id, getPedigreeData, timeStamp, callback) => {
  let runTimeErrors = {
    errors: [],
    warnings: []
  }

  riskAPI.runPANCPRO({ proband_id, timeStamp })
  .then((response) => {

    let risk_results = response.data;
    if(risk_results.cancer_risks.length > 0 || risk_results.probabilities.length > 0) {
      let data = {
        pancreatic: {fiveYear: 'N/A', lifetime: 'N/A'},
        cancer_risks: [],
        mutation_probabilities: [
          {"no mutation": {decimal: "", percent: "N/A"}},
          {pancreaticGene: {decimal: "", percent: "N/A"}}
        ]
      };


      if(risk_results.unknown_gender){
        runTimeErrors.warnings = unKnownGenderWarningMessage
      }
      const five_year_age = risk_results.proband_age + 5;
      const pancreatic_five_year_risk = risk_results.cancer_risks.find(r => r.age === five_year_age);
      if (pancreatic_five_year_risk) {
        data.pancreatic.fiveYear = parseFloat(pancreatic_five_year_risk.pancreatic.percent).toString().substring(0, 4);
      }

      const pancreatic_lifetime_risk = risk_results.cancer_risks[risk_results.cancer_risks.length-1];
      if (pancreatic_lifetime_risk) {
        data.pancreatic.lifetime = parseFloat(pancreatic_lifetime_risk.pancreatic.percent).toString().substring(0, 4);
      }

      data.cancer_risks = risk_results.cancer_risks;

      let pancreatic_gene = risk_results.probabilities[0];
      if (pancreatic_gene) {
        data.mutation_probabilities[1].pancreaticGene.percent = parseFloat(pancreatic_gene["pancreaticGene"].percent).toString().substring(0, 4);
      }

      let payload = {};
      payload.dataKey = 'pancpro';
      payload.data = data;
      dispatch(patient_actions.save_risk_results(payload));
      if(getPedigreeData !== undefined && getPedigreeData !== null){
        let deep_clone = cloneDeep(getPedigreeData().risk_results);
        deep_clone['pancpro'] = data;
        getPedigreeData().setRiskResults(deep_clone)
      }
      callback(runTimeErrors); // return no errors
    } else {
      runTimeErrors.errors = ["No risk results for PANCPRO"]
      callback(runTimeErrors); // return error array
    }

  }).catch((error) => {
    runTimeErrors.errors = ["Could not run"];

    callback(runTimeErrors);
  });
}

const runBayesMendelMelaPro = async (dispatch, proband_id, getPedigreeData, timeStamp, callback) => {
  let runTimeErrors = {
    errors: [],
    warnings: []
  }

  riskAPI.runMELAPRO({ proband_id, timeStamp })
  .then((response) => {

    let risk_results = response.data;
    if(risk_results.cancer_risks.length > 0 || risk_results.probabilities.length > 0) {
      let data = {
        melanoma: {fiveYear: 'N/A', lifetime: 'N/A'},
        cancer_risks: [],
        mutation_probabilities: [
          {"no mutation": {decimal: "", percent: "N/A"}},
          {P16: {decimal: "", percent: "N/A"}}
        ]
      };

      if(risk_results.unknown_gender){
        runTimeErrors.warnings = unKnownGenderWarningMessage
      }
      const five_year_age = risk_results.proband_age + 5;
      const melanoma_five_year_risk = risk_results.cancer_risks.find(r => r.age === five_year_age);
      if (melanoma_five_year_risk) {
        data.melanoma.fiveYear = parseFloat(melanoma_five_year_risk.melanoma.percent).toString().substring(0, 4);
      }

      const melanoma_lifetime_risk = risk_results.cancer_risks[risk_results.cancer_risks.length-1];
      if (melanoma_lifetime_risk) {
        data.melanoma.lifetime = parseFloat(melanoma_lifetime_risk.melanoma.percent).toString().substring(0, 4);
      }

      data.cancer_risks = risk_results.cancer_risks;

      let p16 = risk_results.probabilities[0];
      if (p16) {
        data.mutation_probabilities[1].P16.percent = parseFloat(p16["P16"].percent).toString().substring(0, 4);
      }

      let payload = {};
      payload.dataKey = 'melapro';
      payload.data = data;
      dispatch(patient_actions.save_risk_results(payload));
      if(getPedigreeData !== undefined && getPedigreeData !== null){
        let deep_clone = cloneDeep(getPedigreeData().risk_results);
        deep_clone['melapro'] = data;
        getPedigreeData().setRiskResults(deep_clone)
      }
      callback(runTimeErrors); // return no errors
    } else {
      runTimeErrors.errors = ["No risk results for MELAPRO"];
      callback(runTimeErrors); // return error array
    }

  }).catch((error) => {
    runTimeErrors.errors = ["Could not run"];

    callback(runTimeErrors);
  });
}


export default {
  runBoadicea,
  runClaus,
  runGail,
  runTyrerCuzick,
  runBayesMendelBrcaPro,
  runBayesMendelMmrPro,
  runBayesMendelPancPro,
  runBayesMendelMelaPro,
}
