import React, { Component } from 'react';

import helpers from '../helpers/index';
import images from '../configs/images';
import route_helper from '../route-helper';
import PasswordResetForm from "../components/login/password-reset-form";
import api_auth from '../api/authentication';
import LinkExpired from '../components/login/link-expired'
import ActivityIndicator from '../components/activity-indicator'
class PasswordResetPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      expiredLink: false
    }

    this.handleChangePasswordSuccess = this.handleChangePasswordSuccess.bind(this);
    this.handleChangePasswordFail    = this.handleChangePasswordFail.bind(this);
  }

  async componentDidMount() {
    helpers.setSiteTitle('Password Reset');
    this.setState({loading: true})
    try{
      let expired =  await api_auth.check_password_link_expire(this.props.match.params.uid64, this.props.match.params.token, false)
      this.setState({expiredLink: expired})
      this.setState({loading: false})
      }catch(error){
        let parsedError = api_auth.parse_error_message(error);
        this.setState({errorMessages: [parsedError.message]});
      }
      this.setState({loading: false})
    }

  handleChangePasswordSuccess() {
    // send the user back to the login page on success
    this.props.history.replace(route_helper.root, {password_success_message: 'Changed Password Success'});
  }

  handleChangePasswordFail() {

  }

  render() {
    const copyright_year = new Date().getFullYear();

    // let path = window.location.pathname;
    //
    // let uid64 = '';
    // let token = '';
    //
    // let regex = /^[/]password-reset[/](.+)[/](.+)$/;
    // let matches = path.match(regex); // returns an array or null if no matches
    //
    // if(matches !== null && Array.isArray(matches) && matches.length === 3) {
    //   /*
    //     the first index is the complete regular expression match
    //     the second match will be the uid64
    //     the third match will be the token
    //   */
    //   uid64 = matches[1];
    //   token = matches[2];
    // }

    let uid64 = '';
    let token = '';
    uid64 = this.props.match.params.uid64;
    token = this.props.match.params.token;

    return (
      <React.Fragment>
      {!this.state.expiredLink && !this.state.loading &&(
        <div className="login_wrapper">
          <div className="animate form login_form">
            <section className="login_content">

              <div className="logo">
                <img src={images.famhisLogo.default} className="img-responsive" alt="FamGenix Logo" />
              </div>

              <PasswordResetForm
                initialCreation={false}
                onChangePasswordSuccess={this.handleChangePasswordSuccess}
                onChangePasswordFail={this.handleChangePasswordFail}
                uid64={uid64}
                token={token}
                // org type is not needed since we don't need the agreement checkbox field
              />

            </section>

            <div><p className="text-center text-muted margin-nine"><span>&copy; {copyright_year} by FamGenix, Inc. All rights reserved.</span></p></div>
          </div>
        </div>
      )}
      {(this.state.expiredLink && !this.state.loading) &&(
        <LinkExpired
        token={this.props.match.params.token}
        uid64={this.props.match.params.uid64}
        initialCreation={false}
        >
        </LinkExpired>
      )}
      <ActivityIndicator loading={this.state.loading} />
      </React.Fragment>
    );
  }
}

export default PasswordResetPage;
