import React from 'react';

const InputAndOr = (props) => {
  let colWidth = 'col-md-2';
  if('colWidth' in props)  colWidth = props.colWidth;

  return (
    <div className={ colWidth + " col-xs-12 no-padding-right"} style={{zIndex: 0}}>
      <div className="btn-group btn-group2 btn-toggle" data-toggle="buttons">

          <label className={"btn btn-xs btn-light btn-light-outline2 " + (props.value=='and' ? 'active' : '')}>
            <input
              name="andOr"
              onChange={(e) => props.onChange(e)}
              type="radio" value="and" checked={props.value=='and'} />
            AND
          </label>
          <label className={"btn btn-xs btn-light btn-light-outline2 " + (props.value=='or' ? 'active' : '')}>
            <input
              name="andOr"
              onChange={(e) => props.onChange(e)}
              type="radio" value="or" checked={props.value=='or'} />
            OR
          </label>

      </div>
    </div>
  );
}

export default InputAndOr
