import axios from 'axios';
import sdk from './sdk';

const BOADICEA_ENDPOINT = "/boadicea/calculate_risk/"
const CLAUS_ENDPOINT = "/claus/calculate_risk/"
const GAIL_ENDPOINT = "/gail/calculate_risk/"
const TYRER_CUZICK_ENDPOINT = "/tyrer_cuzick/calculate_risk/"
const BRCAPRO_ENDPOINT = "/bayes_mendel/calculate_brcapro/"
const MMRPRO_ENDPOINT = "/bayes_mendel/calculate_mmrpro/"
const MELAPRO_ENDPOINT = "/bayes_mendel/calculate_melapro/"
const PANCPRO_ENDPOINT = "/bayes_mendel/calculate_pancpro/"

class RiskAPI {

  constructor() {
    this.api_handler = axios.create({
      baseURL: process.env.REACT_APP_ROOT_API_URL
    });
  }

  setAuthHeader() {
    try {
      let decoded = sdk.tokens_from_cookie();
      let token = decoded.token.accessToken;
      this.api_handler.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } catch (error) {
      // console.log(error)
    } finally {
      return {};
    }
  }

  runBoadicea(payload) {
    this.setAuthHeader();

    return new Promise((resolve, reject) => {
      this.api_handler.post(BOADICEA_ENDPOINT, payload)
        .then((response) => {
          // anything status 2xx
          return resolve(response);
        })
        .catch((error) => {
          // anything not status 2xx
          if (error.response && error.response.data) {
            if(error.response.status === 422) {
              return reject(error.response.data);
            }
          }
          return reject(sdk.parse_error_message(error));
        });
    });
  }

  runClaus(payload) {
    this.setAuthHeader();

    return new Promise((resolve, reject) => {
      this.api_handler.post(CLAUS_ENDPOINT, payload)
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            return reject(error.response.data.message);
          }
          return reject("");
        });
    });
  }

  runGail(payload) {
    this.setAuthHeader();

    return new Promise((resolve, reject) => {
      this.api_handler.post(GAIL_ENDPOINT, payload)
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            return reject(error.response.data.message);
          }
          return reject("");
        });
    });
  }

  runTyrerCuzick(payload) {
    this.setAuthHeader();

    return new Promise((resolve, reject) => {
      this.api_handler.post(TYRER_CUZICK_ENDPOINT, payload)
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            return reject(error.response.data.message);
          }
          return reject("");
        });
    });
  }

  runBRCAPRO(payload) {
    this.setAuthHeader();

    return new Promise((resolve, reject) => {
      this.api_handler.post(BRCAPRO_ENDPOINT, payload)
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            return reject(error.response.data.message);
          }
          return reject("");
        });
    });
  }

  runMMRPRO(payload) {
    this.setAuthHeader();

    return new Promise((resolve, reject) => {
      this.api_handler.post(MMRPRO_ENDPOINT, payload)
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            return reject(error.response.data.message);
          }
          return reject("");
        });
    });
  }

  runMELAPRO(payload) {
    this.setAuthHeader();

    return new Promise((resolve, reject) => {
      this.api_handler.post(MELAPRO_ENDPOINT, payload)
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            return reject(error.response.data.message);
          }
          return reject("");
        });
    });
  }

  runPANCPRO(payload) {
    this.setAuthHeader();

    return new Promise((resolve, reject) => {
      this.api_handler.post(PANCPRO_ENDPOINT, payload)
        .then((response) => {
          return resolve(response);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            return reject(error.response.data.message);
          }
          return reject("");
        });
    });
  }
}

export default RiskAPI;
