import React, { Component } from 'react';
import { Link } from "react-router-dom";
import route_helper from "../../route-helper";
import Select, { components } from "react-select";

class TableFilter extends Component {
  constructor(props) {
    super(props);
    this.selectCompRef = React.createRef();
    this.state = {
      search: '',
      readyToDelete: false,
      currentActiveReport: {
        value: null,
        label: null,
      },
    };

    this.filterValues = this.filterValues.bind(this);
    this.handleChange  = this.handleChange.bind(this);
    this.handleSubmit  = this.handleSubmit.bind(this);
    this.downloadCSV  = this.downloadCSV.bind(this);
    this.readyToDeleteOff = this.readyToDeleteOff.bind(this);
    this.readyToDeleteOn = this.readyToDeleteOn.bind(this);
  }

  filterValues(event) {
    this.props.filterValues(event.target.value)
  }

  handleChange(event) {
    this.setState({search: event.target.value});
    if('onChangeSearchText' in this.props) this.props.onChangeSearchText(event.target.value);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.onSearchSubmit(this.state.search);
  }

  downloadCSV(event) {
    this.props.downloadCSV();
  }


  readyToDeleteOn() {
    this.setState({ readyToDelete: true });
  }

  readyToDeleteOff() {
    this.setState({ readyToDelete: false });
  }

  async deleteReport(report, clearValue) {
    await this.props.openDeleteReportModal(report);
    if (
      this.state.readyToDelete &&
      this.state.currentActiveReport.value !== report.value
    ) {
      this.selectCompRef.select.setValue(this.state.currentActiveReport);
    }
    if (this.state.currentActiveReport.value === null) {
      await this.selectCompRef.select.clearValue();
    }
    await this.readyToDeleteOff();
  }

  selectReport(report) {
    if (!this.state.readyToDelete && report) {
      this.setState({
        currentActiveReport: { value: report.value, label: report.label },
      });
      this.props.loadReport(report.value);
    } else if (!this.state.readyToDelete && report === null) {
      this.setState({ currentActiveReport: { value: null, label: null } });
      this.props.loadReport(null);
    }
  }

  render() {

    let report_options = [];
    if (this.props.reports) {
      report_options = this.props.reports.map((report) => {
        return { value: report.id, label: report.title };
      });
    }

    return (

      <div className="table-filter">
          {
            this.props.tableType === 'staff' || this.props.tableType === 'sub-accounts' ?
              <>
              <div className="col-md-12 search-criteria-area">
                <div className="no-margin-left query-search-button-input" style={{ display: 'flex', width: '100%' }}>
                  <form onSubmit={this.handleSubmit} style={{ width: '100%'}}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <div style={{position:
                      'relative'}}>
                      <input type="text"
                        value={this.state.search}
                        onChange={this.handleChange}
                        id="search_text"
                        name="search_text"
                        className='input-home-search search-results-searchBar'
                        placeholder={this.props.tableType=='staff' ? "Search staff..." : "Search sub-accounts..."}
                        autoComplete="off"
                      ></input>
                      <i
                        onClick={this.handleSubmit}
                        onMouseOver={() => document.body.style.cursor = "pointer"}
                        onMouseLeave={() => document.body.style.cursor = "default"}
                        className="fa fa-search fa-lg"
                        style={{
                          display: "grid",
                          position: "absolute",
                          alignContent: "center",
                          right: "25px",
                          top: '15px'
                        }}
                      ></i>
                      </div>
                      <div>
                        <span className="col-md-1" style={{width:'auto'}}>
                          <select name="per_page" className="form-control input-sm" onChange={this.filterValues} style={{marginTop:'5px', display: 'inline'}}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </span>
                        <span className="btn-group btn-group-xs" role="group" aria-label="CSV button">
                          <button type="button" className="btn btn-default" onClick={this.downloadCSV} style={{marginTop:'7px'}}>CSV</button>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              </>
            :
            //reconstruct to look like query-toolbar.js
            <>
              <div className="query-search-button-input" style={{ display: 'grid' }}>
                <form onSubmit={this.handleSubmit}>
                  <span style={{ display: 'flex' }}>
                    <input type="text"
                      value={this.state.search}
                      onChange={this.handleChange}
                      id="search_text"
                      name="search_text"
                      className='input-home-search search-results-searchBar'
                      placeholder="Search accounts..."
                      autoComplete="off"
                    />
                    <i
                      onClick={this.handleSubmit}
                      onMouseOver={() => document.body.style.cursor = "pointer"}
                      onMouseLeave={() => document.body.style.cursor = "default"}
                      className="fa fa-search fa-lg"
                      style={{
                        display: "grid",
                        position: "relative",
                        alignContent: "center",
                        right: "35px",

                      }}
                    ></i>
                  </span>
                </form>
                <div className="new-patient-button margin-one-top col-lg-2 col-md-2 col-sm-5 col-xs-6" style={{ left: 40 }}>
                  <Link
                    to={route_helper.administrative.fh_new_account}
                    className="btn btn-teal btn-md"
                  >
                    <i className="fa fa-plus text-white" />
                    <span>Add New Account</span>
                  </Link>
                </div>
              </div>
              <div className="query-search-button-input" style={{marginTop: '15px'}}>
                <div className="flex-right" style={{justifyContent:'left'}} >
                  <Select
                    ref={(ref) => {
                      this.selectCompRef = ref;
                    }}
                    value={report_options.value}
                    name="report"
                    onChange={(item) => {
                      this.selectReport(item);
                    }}
                    className="react-select-container save-report-dropdown"
                    classNamePrefix="react-select"
                    isClearable={true}
                    placeholder=""
                    deleteReport={(report, clearValue) =>
                      this.deleteReport(report, clearValue)
                    }
                    readyToDeleteOn={() => this.readyToDeleteOn()}
                    readyToDeleteOff={() => this.readyToDeleteOff()}
                    options={report_options}
                    components={{ Option: IconOption }}
                  />
                </div>
                <div
                  className="flex-right"
                  style={{ justifyContent: "flex-start" }}
                >
                  <button
                    style={{ marginLeft: "17px" }}
                    onClick={() => this.props.setShowSaveReportModal(true)}
                    className="btn btn-teal btn-xs save-report-button"
                    data-toggle="modal"
                    data-target="#SaveReport"
                  >
                    <i style={{ paddingRight: "7px" }} className="fa fa-save"></i>Save
                    Report
                  </button>
                </div>
              </div>
            </>
          }
      </div>
    );
  }
}

export default TableFilter;

const IconOption = (props) => {
  const displayTrash = props.isFocused ? "block" : "none";
  return (
    <components.Option {...props}>
      {props.data.label}
      <a
        onClick={() =>
          props.selectProps.deleteReport(props.data, props.clearValue)
        }
      >
        <i
          onMouseEnter={() => props.selectProps.readyToDeleteOn()}
          onMouseLeave={() => props.selectProps.readyToDeleteOff()}
          style={{
            display: displayTrash,
            cursor: "pointer",
            float: "right",
            marginTop: "4px",
          }}
          className="fa fa-trash"
        ></i>
      </a>
    </components.Option>
  );
};
