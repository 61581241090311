import base64url from './base64url-arraybuffer';

function publicKeyCredentialToJSON(pubKeyCred: any) {
	if (pubKeyCred instanceof Array) {
		let arr: Array<any> = [];
		for (let i of pubKeyCred) arr.push(publicKeyCredentialToJSON(i));

		return arr;
	}

	else if (pubKeyCred instanceof ArrayBuffer) {
		return base64url.encode(pubKeyCred);
	}

	else if (pubKeyCred instanceof Object) {
		let obj: any = {};

		for (let key in pubKeyCred) {
			obj[key] = publicKeyCredentialToJSON(pubKeyCred[key]);
		}

		return obj;
	}

	return pubKeyCred;
}

let preformatMakeCredReq = (makeCredReq: any) => {
	makeCredReq.challenge = base64url.decode(makeCredReq.challenge);
	makeCredReq.user.id = base64url.decode(makeCredReq.user.id);
  for( let i=0; i<makeCredReq.excludeCredentials.length; i++) {
    makeCredReq.excludeCredentials[i].id = base64url.decode(makeCredReq.excludeCredentials[i].id);
  }

	return makeCredReq;
};

let preformatGetAssertReq = (getAssert: any) => {
	getAssert.challenge = base64url.decode(getAssert.challenge);

	for(let allowCred of getAssert.allowCredentials) {
		allowCred.id = base64url.decode(allowCred.id);
	}

	return getAssert;
};

export { publicKeyCredentialToJSON, preformatGetAssertReq, preformatMakeCredReq };
