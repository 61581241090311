import React, { Component } from 'react';

import Modal from '../modal/modal';
import helpers from '../../helpers/index';
import ValidationErrorSummary from '../validation-error-summary';
import ErrorSummary from "../error-summary";
import FocusLock from 'react-focus-lock';

class ModalResetPasswordLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      validations: null,
      errorMessages: []
    };

    this.handleChange  = this.handleChange.bind(this);
    this.handleSubmit  = this.handleSubmit.bind(this);
    this.validate      = this.validate.bind(this);
    this.handleClose   = this.handleClose.bind(this);
    this.handleOnkeyDown = this.handleOnkeyDown.bind(this);
  }

  handleChange(event) {
    this.setState({username: event.target.value});
  }

    
  handleClose() {
    this.props.onClose();
  }

  handleOnkeyDown(event) {
    // handle the esc key being pressed down and close the modal for accessibility
    if(event.keyCode === 27) this.handleClose()
  }

  handleSubmit(event) {
    event.preventDefault();
    let isValid = this.validate();
    if (!isValid) return;
    if (!window.navigator.onLine) {
      this.setState({errorMessages: ["No internet connection, please check you wifi or VPN"]});
      return;
    }
    this.setState({ errorMessages: [] });
    this.props.onClickResetPassword(this.state.username);
  }

  validate() {
    this.setState({validations: null});
    let options = {
      username: {
        labelName: 'Username',
        validations: { required: true }
      }
    };

    let validations = helpers.validateInputs(this.state, options);
    this.setState({validations: validations});
    return validations.isValid;
  }

  render() {

    return (
      <div
        role="dialog"
        onClick={this.props.onClose}
        onKeyDown={this.handleOnkeyDown}
        style={{ display: "block" }}
        className="modal fade in"
      >
        <div
          className="modal-dialog"
          role="document"
          onClick={e => e.stopPropagation()}
        >
          <div className='modal-content'>
            <div className='modal-header'>
              <button
                onClick={this.props.onClose}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
              <i className="fa fa-close" />
              </button>
              <h4 className="modal-title text-white text-capitalize">Reset Password</h4>
            </div>
            <div className='modal-body modal-reset-password'>
            <FocusLock>


              <ValidationErrorSummary
                    validations={this.state.validations}
                  />
              <p>Enter the username associated with your account, and we’ll email you a link to reset your password.</p>
              <ErrorSummary 
                loginError={false} 
                errorMessages={this.state.errorMessages} 
              />
              <div className="form-group">
                <label htmlFor="username" className="control-label">Username</label>
                  <input type="text"
                     value={this.state.username}
                     onChange={this.handleChange}
                     id="username"
                     name="username"
                     className="form-control normal-input-text"
                     autoComplete="off" />
              </div>
            </FocusLock>
            </div>
            <div className='modal-footer'>
              <button type="button" className="btn btn-light-outline no-margin-right" onClick={this.props.onClose}>Cancel</button>
              <button type="submit" className="btn btn-dark" onClick={this.handleSubmit}>Send Reset Link</button>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default ModalResetPasswordLink;
