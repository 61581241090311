import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
const SecurityInfo = () => {
  
const [securityData, setSecurityData] = useState('');
const [header, setHeader] = useState('');
  useEffect(() => {
    let header = 'Security Information';
    let securityContact = 'Contact: millermj@famgenix.com';
    setHeader(header);
    setSecurityData(securityContact);

  }, []);

  return ReactDOM.createPortal(
    <div style={{ padding: '20px', background: '#f9f9f9', borderRadius: '8px' }}>
      <h3>
        {header}
      </h3>
        <p>
          {securityData}
        </p>
    </div>,
    document.body
  );
};

export default SecurityInfo;
