import React, { Component } from 'react';
import RadioYesNo from './control-radio-yes-no'
import Dropdown from 'react-dropdown'
import ErrorSummary from './error-summary'
import { cloneDeep } from 'lodash'
import { radix_bases } from '../helpers/helper-number-bases';

import skip_logic_map from '../assets/json/diseases-skip-logic-map.json';

// const keys_mapping = {
//   more_primary: 'SL017',
//   tumor_location: 'SL018',
//   msi: 'SL019',
//   mlh1_ihc: 'SL020',
//   msh2_ihc: 'SL021',
//   msh6_ihc: 'SL022',
//   pms2_ihc: 'SL023',
//   mlh1_hypermethylation: 'SL024',
//   braf_v600e: 'SL025'
// }

const keys_mapping = skip_logic_map.colorectal;

class SkipLogicColorectalForm extends Component {

  constructor(props) {
    super(props)

    let inputs = cloneDeep(keys_mapping);
    for(var key in inputs) {
      inputs[key] = null
    }

    this.state = {
      ...inputs,
      errorMessages: []
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.skip_logics.length != this.props.skip_logics.length) {

      for (var key in keys_mapping) {

        var val = this.getValue(key);
        if (val === null) continue;

        if (key.includes("age")) {
          if (!isNaN(parseInt(val, radix_bases.base10))) {
            await this.setState({ [key]: val, [key.replace("_age", "")]: "y" });
          }
        } else {
          await this.setState({ [key]: val });
        }
      }

      // Reconstruct payload
      var skipLogicPayload = this.buildSkipLogicPayload();
      this.props.onUpdate(this.state, skipLogicPayload, true);

    }
  }

  getValue(key) {
    if(!(key in keys_mapping)) return null

    let code = keys_mapping[key]
    let data = this.props.skip_logics.find(item => item.skip_logic_code == code)

    if(typeof(data) === 'undefined') return null
    return data.answer
  }

  /*
  [
    {skip_logic_id, answer}
  ]
  */
  buildSkipLogicPayload() {
    let inputs = this.state;
    let mapping = keys_mapping;
    let skipLogicPayloads = []
    for(var key in mapping) {
      var static_skip_logic_item = this.props.static_skip_logic.find(item => item.skip_logic_code == mapping[key])
      if(typeof(static_skip_logic_item) === 'undefined') {
        throw new Error('Skip Logic code not found');
      }

      var input = inputs[key]
      var answer = input !== null && typeof(input) === 'object' ? input.value : input
      if(answer !== null && answer.trim() !== '') {
        skipLogicPayloads.push({
          skip_logic_code: static_skip_logic_item.skip_logic_code,
          skip_logic_id: static_skip_logic_item.id,
          short_name: static_skip_logic_item.short_name,
          answer
        })
      }
    }

    return skipLogicPayloads;
  }

  async onChange(data){
    try {
      this.setState({errorMessages: []})
      await this.setState({...this.state, ...data});

      var skipLogicPayload = this.buildSkipLogicPayload();
      this.props.onUpdate(this.state, skipLogicPayload);
    } catch (error) {
      this.setState({errorMessages: [error.message]})
    }
  }

  render() {

    let normal_options = [
      {value: '', label: ' ' },
      {value: 'unknown', label: 'Unknown'},
      {value: 'normal', label: 'Normal'},
      {value: 'abnormal', label: 'Abnormal'}
    ];

    return (
      <div className="panel-form-2 sl-content colorectal">
        <ErrorSummary errorMessages={this.state.errorMessages} />
        <div className="form-horizontal margin-two-bottom">
          <div className="form-group">
            <label className="control-label col-md-3 col-sm-4 col-xs-12 no-margin-right  ">More than one primary?</label>
            <div className="col-md-3 col-sm-9 col-xs-12">

                  <RadioYesNo
                    name="ipsilateral"
                    value={this.state.more_primary}
                    onChange={(more_primary) => this.onChange({ more_primary })}
                  />

            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label>Tumor Location</label>
              <div className="custom-select">

                <Dropdown
                  options={[
                    {value: '', label: ' ' },
                    {value: 'unknown', label: 'Unknown'},
                    {value: 'proximal colon', label: 'Proximal Colon'},
                    {value: 'distal colon', label: 'Distal Colon'},
                    {value: 'rectum', label: 'Rectum'}
                  ]}
                  onChange={(tumor_location) => this.onChange({tumor_location})}
                  value={this.state.tumor_location}
                  placeholder="&nbsp;"
                  />

              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>MSI</label>
              <div className="custom-select">

                <Dropdown
                  options={[
                    {value: '', label: ' ' },
                    {value: 'unknown', label: 'Unknown'},
                    {value: 'msi-high', label: 'MSI-High'},
                    {value: 'msi-low', label: 'MSI-Low'},
                    {value: 'msi-mss', label: 'MSI-MSS'}
                  ]}
                  onChange={(msi) => this.onChange({msi})}
                  value={this.state.msi}
                  placeholder="&nbsp;"
                />

              </div>
            </div>
          </div>
        </div>
        <div className="row">

          <div className="col-md-3">
            <div className="form-group">
              <label>MLH1 IHC</label>
              <div className="custom-select">

                <Dropdown
                  options={normal_options}
                  onChange={(mlh1_ihc) => this.onChange({mlh1_ihc})}
                  value={this.state.mlh1_ihc}
                  placeholder="&nbsp;"
                />

              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>MSH2 IHC</label>
              <div className="custom-select">

                <Dropdown
                  options={normal_options}
                  onChange={(msh2_ihc) => this.onChange({msh2_ihc})}
                  value={this.state.msh2_ihc}
                  placeholder="&nbsp;"
                />

              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label>MSH6 IHC</label>
              <div className="custom-select">

                <Dropdown
                  options={normal_options}
                  onChange={(msh6_ihc) => this.onChange({msh6_ihc})}
                  value={this.state.msh6_ihc}
                  placeholder="&nbsp;"
                />

              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>PMS2 IHC</label>
              <div className="custom-select">

                <Dropdown
                  options={normal_options}
                  onChange={(pms2_ihc) => this.onChange({pms2_ihc})}
                  value={this.state.pms2_ihc}
                  placeholder="&nbsp;"
                />

              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <label>MLH1 Hypermethylation</label>
              <div className="custom-select">

                <Dropdown
                  options={[
                    {value: '', label: ' ' },
                    {value: 'unknown', label: 'Unknown'},
                    {value: 'normal', label: 'Normal'},
                    {value: 'hypermethylated', label: 'Hypermethylated'}
                  ]}
                  onChange={(mlh1_hypermethylation) => this.onChange({mlh1_hypermethylation})}
                  value={this.state.mlh1_hypermethylation}
                  placeholder="&nbsp;"
                />

              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>BRAF v600E</label>
              <div className="custom-select">

                <Dropdown
                  options={[
                    {value: '', label: ' ' },
                    {value: 'unknown', label: 'Unknown'},
                    {value: 'p', label: 'Positive'},
                    {value: 'n', label: 'Negative'}
                  ]}
                  onChange={(braf_v600e) => this.onChange({braf_v600e})}
                  value={this.state.braf_v600e}
                  placeholder="&nbsp;"
                />

              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default SkipLogicColorectalForm