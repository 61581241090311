import React, { Component } from 'react'
// import { connect } from "react-redux";
import { isEqual, debounce } from 'lodash';
import { log_error } from '../../helpers/helper-error';

import ModalPartnerDetails from '../modal-partner-details';

import ModalAdopted from '../modal-adopted';
import ModalHalfSiblings from '../modal-half-siblings';
import ModalHalfSiblingsTwinsCheck from '../modal-half-siblings-twins-check'
import {cloneDeep} from 'lodash'

import * as helper_family_tree from '../../helpers/helper-family-tree';
import FamilyTreePartner from './partner';

import ButtonAddEdit from '../button-add-edit';

import ModalConfirmDelete from '../modal-confirm-delete';
import helpers from '../../helpers';
import family_api from '../../api/family-api'
import ErrorSummary from '../error-summary';
import * as model from '../../model-states/m-family-tree';
import * as patient_actions from '../../store/patient/actions';

class FamilyTreeSibling extends Component {

  constructor(props) {
    super(props);
    this.state = {
      errorMessages: [],
      loading: false,

      collapsed: true,
      partners: null,
      siblingRkey: null,
      openModalPartnerCount: false,
      modalPartnerCountHash: new Date().getTime(),

      openModalAdopted: false,
      modalAdoptedHash: new Date().getTime(),

      openModalHalfSiblings: false,
      modalHalfSiblingsHash: new Date().getTime(),

      openModalPartnerDetail: false,
      modalPartnerDetailHash: new Date().getTime(),

      openModalConfirmDelete: false,
      modalConfirmDeleteHash: new Date().getTime(),

      openModalHalfSiblingsTwinsCheck: false,
      modalHalfSiblingsTwinsCheckHash: new Date().getTime(),
    };

    this.updateFirstName = debounce(this.updateFirstName, 250)
  }

  async componentDidUpdate(prevProps) {
    this.loadPartnersFromRedux();
  }

  loadPartnersFromRedux() {
    if (this.state.siblingRkey === null) return;

    if (!(this.state.siblingRkey in this.props.patient.partners)) return;

    let partners = this.props.patient.partners[this.state.siblingRkey];
    for(var partner of partners) {
      let relKey = partner.rkey
      if(relKey.includes('-fake')) partners.pop(partner)
    }
    if (!isEqual(this.state.partners, partners)) {
      this.setState({ partners });
    }
  }

  async componentDidMount() {

    if ('collapsed' in this.props) this.setState({ collapsed: this.props.collapsed });
    this.setState({ siblingRkey: this.props.sibling.rkey });

  }

  // async populatePartnersFromAPI() {
  //   try {
  //     let spouse_id = this.props.sibling.id
  //     if(spouse_id == null) return

  //     // Clear redux before repopulating
  //     this.props.dispatch(patient_actions.delete_partner({ownerRkey: this.props.sibling.rkey}))

  //     // Fetch Parter from API
  //     let partners = await family_api.get_member_memberid_partners(spouse_id)
  //     for(var partner of partners) {
  //       var partner_from_model = model.createPartner()
  //       var partner_data = Object.assign({}, partner_from_model, partner)

  //       // Save to Redux
  //       // partner_data.rkey = 'member_' + partner_data.id
  //       helper_family_tree.savePartnerToRedux(this.props.dispatch, this.props.sibling.rkey, partner_data)
  //       helper_family_tree.saveProfileToRedux(this.props.dispatch, partner_data)
  //     }
  //   } catch (error) {
  //     helpers.logger(error)
  //   }
  // }

  onCollapseClick(e) {
    this.setState({ collapsed: !this.state.collapsed });
    this.clearSelection();
  }

  clearSelection() {
    if (window.getSelection) { window.getSelection().removeAllRanges(); }
    else if (document.selection) { document.selection.empty(); }
  }

  async onChangeName(first_name) {
    try {
      let profile = { rkey: this.props.sibling.rkey, first_name }
      helper_family_tree.saveProfileToRedux(this.props.dispatch, profile)
      helper_family_tree.saveSiblingDetailToRedux(this.props.dispatch, profile)
      this.updateFirstName(first_name);
    } catch (error) {
      log_error(this.constructor.name, error);
    }
  }

  async updateFirstName(first_name) {
    try {
      this.setState({errorMessages: []})

      let member = this.props.sibling
      await family_api.patch_member_memberid(member.id, {
        first_name
      })
    } catch (error) {
      this.setState({errorMessages: [error.message]})
    }
  }

  onClickOpenAdopted() {
    this.setState({
      openModalAdopted: true,
      modalAdoptedHash: new Date().getTime()
    });
  }

  onClickOpenHalfSibling() {
    this.setState({
      openModalHalfSiblings: true,
      modalHalfSiblingsHash: new Date().getTime(),
    });
  }

  onClickOpenModalConfirmDelete() {
    this.setState({
      openModalConfirmDelete: true,
      modalConfirmDeleteHash: new Date().getTime()
    });
  }

  onClickOpenModalHalfSiblingsTwinsCheck() {
    this.setState({
      openModalHalfSiblingsTwinsCheck: true,
      modalHalfSiblingsTwinsCheckHash: new Date().getTime()
    });
  }

  async handleFixTwins() {

    let twins = await family_api.get_twin(
      this.props.fatherId,
      this.props.motherId
    );

    let selected_person = this.getProfile();
    let data = await family_api.delete_twin_set(selected_person.twin_id);

    for(let set of twins) {
      if(set.twin_id === selected_person.twin_id) {
        for(let twin of set.members) {
          let profile = this.getProfileById(twin.id);
          if(profile) {
            await this.clearTwinStatus(profile);
          }
        }
      }
    }
    this.setState({ openModalHalfSiblingsTwinsCheck: false });
  }

  async clearTwinStatus(profile) {
    profile = Object.assign({}, profile, { twin_id: null, twin_set: null, twin_type: null });
    helper_family_tree.saveProfileToRedux(this.props.dispatch, profile);
  }

  getProfileById(member_id) {
    let rkey = `apimem-${member_id}`;
    return cloneDeep(this.props.patient.profile[rkey]);
  }

  async onClickDelete() {
    try {

      this.setState({errorMessages: [], loading: true})

      let leftover_id = null

      // Delete twins from API
      if(this.props.sibling.twin_id) {
        let {mother, father} = await family_api.get_member_memberid_parents(this.props.sibling.id);
        let twin_set = await family_api.get_twin(father.id, mother.id);
        for(let set of twin_set) {
          if(set.twin_id === this.props.sibling.twin_id) {
            let twin_length = set.members.length;
            if(twin_length === 2) {
              await family_api.delete_twin_set(set.twin_id);
              for(let member of set.members) {
                if(member.id !== this.props.sibling.id) leftover_id = member.id;
              }
            }
          }
        }
      }

      // Handle leftover twins from Redux and Local State
      if(leftover_id) {
        let twin_fix = {twin_id: null, twin_set: null, twin_type: null};
        if(this.props.patient.patient.id === leftover_id) {
          // fix proband, save to redux
          let proband = Object.assign({}, cloneDeep(this.props.patient.patient), twin_fix)
          helper_family_tree.savePatientToRedux(this.props.dispatch, proband)
          helper_family_tree.saveProfileToRedux(this.props.dispatch, proband)
        } else {
          for(let sibling of this.props.patient.siblings) {
            if(sibling.id === leftover_id) {
              // fix sibling, save to redux
              let patient = Object.assign({}, cloneDeep(sibling), twin_fix)
              helper_family_tree.saveSiblingDetailToRedux(this.props.dispatch, patient)
              helper_family_tree.saveProfileToRedux(this.props.dispatch, patient)
            }
          }
        }
      }

      await this.props.deleteMember(this.props.sibling)

      helper_family_tree.deleteSibling(this.props.dispatch, this.props.patient, this.props.sibling);
      this.setState({ openModalConfirmDelete: false })

    } catch (error) {
      helpers.logger(error)
      this.setState({errorMessages: [error.message]})
    } finally {
      this.setState({loading: false})
    }
  }

  hasPartners() {
    try {
      return this.state.partners.length > 0;
    } catch (err) {
      return false;
    }
  }

  hasSiblings() {
    return this.props.patient.siblings.length > 0;
  }

  isTwin() {
    let profile = this.getProfile();
    if(profile == null) return false;
    return (profile.twin_id !== null );
  }

  onClickOpenModalPartnerDetail() {
    this.setState({
      openModalPartnerDetail: true,
      modalPartnerDetailHash: new Date().getTime()
    });
  }

  onClickModalAdoptedSave(data) {
    helper_family_tree.saveProfileToRedux(this.props.dispatch, data)
  }

  isHalfSibling() {
    let sibling = this.props.sibling;
    let proband_father_id = this.props.patient.patient.father.id
    let proband_mother_id = this.props.patient.patient.mother.id
    return (sibling.father_id != proband_father_id || sibling.mother_id != proband_mother_id)
  }

  getPartnersCount() {
    if(this.state.partners) return this.state.partners.length

    return 0
  }

  getFirstName() {

    let profile = this.getProfile();
    if(profile == null) return null;

    return profile.first_name;
  }

  getProfile() {
    return helper_family_tree.getProfileFromRedux(this.props.patient, this.props.sibling.rkey)
  }

  getIsAdopted() {
    let profile = this.getProfile();
    if(profile == null) return false;
    return (profile.adopted_in || profile.adopted_out)
  }

  render() {
    return (
      <React.Fragment>
        <tr>
          <td>
            {this.getPartnersCount() > 0 &&
              <a onClick={(e) => this.onCollapseClick(e)}>
                <i className={"fa fa-" + (this.state.collapsed ? "minus-square" : "plus-square")}></i>
              </a>
            }

            {this.state.openModalConfirmDelete &&
              <ModalConfirmDelete
                loading={this.state.loading}
                errorMessages={this.state.errorMessages}
                isOpen={this.state.openModalConfirmDelete}
                onCancel={() => this.setState({ openModalConfirmDelete: false })}
                onOk={() => this.onClickDelete()}
              />
            }

            {this.state.openModalPartnerDetail &&
              <ModalPartnerDetails
                key={this.state.modalPartnerDetailHash}
                ownerRkey={this.state.siblingRkey}
                owner={this.props.sibling}
                parent_side={this.props.parent_side}
                isOpen={this.state.openModalPartnerDetail}
                onClose={() => this.setState({ openModalPartnerDetail: false })}
                onClickSave={(partner_count) => {
                  // helper_family_tree.saveSiblingDetailToRedux(this.props.dispatch, { rkey: this.props.sibling.rkey, partner_count })
                  this.setState({ openModalPartnerDetail: false })
                }}
              />
            }

            {this.state.openModalAdopted &&
              <ModalAdopted
                key={this.state.modalAdoptedHash}
                isOpen={this.state.openModalAdopted}
                onClose={() => this.setState({ openModalAdopted: false })}
                onSave={(data) => this.onClickModalAdoptedSave(data)}
                label={this.props.label}
                adopted={this.getProfile()}
              />
            }

            {this.state.openModalHalfSiblings &&
              <ModalHalfSiblings
                isOpen={this.state.openModalHalfSiblings}
                onClose={() => this.setState({ openModalHalfSiblings: false })}
                onTwins={() => this.onClickOpenModalHalfSiblingsTwinsCheck()}
                isTwin={this.isTwin()}
                label={this.props.label}
                sibling={this.props.sibling}
                maleLabel="Brother"
                femaleLabel="Sister"
                gender={this.props.sibling.gender}
                parent_side={this.props.parent_side}
                dispatch={this.props.dispatch}
                patient={this.props.patient}
              />
            }

            {this.state.openModalHalfSiblingsTwinsCheck && (
              <ModalHalfSiblingsTwinsCheck
              isOpen={this.state.openModalHalfSiblingsTwinsCheck}
              onCancel={() => this.setState({ openModalHalfSiblingsTwinsCheck: false })}
              onOk={() => this.handleFixTwins()}
              />
          )}

          </td>
          <td>
            {this.props.label}
          </td>

          <td>
            <input
              onChange={(e) => this.onChangeName(e.target.value)}
              value={this.getFirstName()}
              type="text" className="inputreg form-control normal-input-text" />

              <ErrorSummary errorMessages={this.state.errorMessages} transparent={true}/>
          </td>
          <td>

            <ButtonAddEdit
              onClick={() => this.onClickOpenModalPartnerDetail()}
              type={this.hasPartners() ? 'edit' : 'add'}
            />

          </td>

          <td>
            {this.hasSiblings() &&
              <button
                onClick={() => this.props.onClickTwin(this.props.sibling.id)}
                className={"btn btn-xs table-btn-add btn-" + (this.isTwin() ? 'teal' : 'light')}>
                <i className="fa fa-pencil"></i> Edit</button>
            }
          </td>

          <td>
            <button
              onClick={() => this.onClickOpenAdopted()}
              className={"btn btn-xs table-btn-add btn-" + (this.getIsAdopted() ? 'teal' : 'light')}>
              <i className="fa fa-pencil"></i> Edit</button>
          </td>

          <td>

            <button
              onClick={() => this.onClickOpenHalfSibling()}
              className={"btn btn-xs table-btn-add btn-" + (this.isHalfSibling() ? 'teal' : 'light')}>
              <i className="fa fa-pencil"></i> Edit</button>

          </td>
          <td>

            <a
              onClick={() => this.onClickOpenModalConfirmDelete()}
              className="btn table-btn-add btn-xs"><i className="fa fa-trash"></i></a>

          </td>
        </tr>

        {this.state.collapsed && this.state.partners &&
          this.state.partners.map((partner, index) => {
            return <FamilyTreePartner
              key={partner.rkey}
              ownerRkey={this.state.siblingRkey}
              collapsed={true}
              level={0}
              extra_indent={1}
              label={'Partner #' + (index + 1)}
              partner={partner}
              owner={this.props.sibling}
              parent_side={this.props.parent_side}
              dispatch={this.props.dispatch}
              patient={this.props.patient}
              deleteMember={this.props.deleteMember}
            />
          })
        }
      </React.Fragment>
    );
  }
}
export default FamilyTreeSibling;
