import React, {useState} from 'react';
import Select from 'react-select';
import settings from '../configs/settings';

const TwinItem = (props) => {

  let twin_set = settings.twins_set_options.find(item => props.profile.twin_set && props.profile.twin_set == item.value)
  twin_set = (typeof(twin_set) === 'undefined') ? null : twin_set;

  let twin_type = settings.twins_type_options.find(item => props.profile.twin_type && props.profile.twin_type.toLowerCase() == item.value.toLowerCase())
  twin_type = (typeof(twin_type) === 'undefined') ? null : twin_type;

  const [isChecked, setIsChecked] = useState(false);

  return (
    <div className={"row margin-two-bottom" + (props.hasError ? 'modal-twin__row-error' : '') }>
      <div className="col-md-2 col-xs-12">
        <div className="checkbox twins-slider">
          <label className="switch">
            <input
              name="twins-slider"
              id="twins-slider"
              onChange={(event) => {
                let value = event.currentTarget.checked ? props.twin_set : null
                setIsChecked(event.currentTarget.checked)
                props.onChange('twin_set', value)
              }}
              checked={twin_set}
              type="checkbox" />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
      <div className="col-md-4 col-xs-12">
        <div className="form-group">
          <label className="text--capitalize">{props.label}</label>
          <input
            value={props.profile.first_name}
            onChange={(e) => props.onChangeName(e.target.value)}
            type="text" className="form-control   " placeholder="First Name" />
        </div>
      </div>
      <div className="col-md-4 col-xs-12">
        <div className="m-top-two">
          <label>Type</label>
          <div className="custom-select">

            <Select
              value={twin_type}
              onChange={(item) => {
                let value = item ? item.value :  null
                props.onChange('twin_type', value)
              }}
              className='react-select-container'
              classNamePrefix="react-select"
              isClearable={true}
              placeholder=""
              options={settings.twins_type_options}
            />

          </div>
        </div>
      </div>
    </div>
  );
}

export default TwinItem;
