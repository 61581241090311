import React, { useEffect, useState, useRef } from "react";
import familyApi from "../api/family-api";
import helper_dom_printing from './../helpers/helper-dom-pdf'
import { skipLogicCheck } from "./react-flow-pedigree/skip_logic_utils";
// Define the props interface
interface FamilyHistoryProps {
    profiles: Profiles;
    proband: any;
    pedigreeNotes: string;
    closeModal: () => void;
}

interface Profiles {
  [key: string]: any;
}

interface skipLogics {
  [key: string]: string;
}

const skipLogicMap: skipLogics = {
  'p' : 'Pos.',
  'n' : 'Neg'
}


const geneMap: any = {
    'p': "Pathogenic",
    'lp': "Likely Pathogenic",
    'vus': "VUS",
    'ln': "Likely Benign",
    'unsure': "Unsure"
}


const yesSkipLogics = ['y']
const postiveSkipLogics = ['p']

const answersInclude = ['y', 'p']
const answersExclude = ['n', '']

// React functional component with TypeScript
const ModalFamilyHistory: React.FC<FamilyHistoryProps> = ({closeModal, profiles, proband, pedigreeNotes=''}) =>{
    const [fullName, setFullName] = useState('')
    const [probandDiseases, setProbandDiseases] = useState([])
    const [probandGenes, setGenes] = useState([])
    const [familyMember, setFamilyMembers] = useState([])


    useEffect(()=>{
        const{first_name, last_name, age, age_string, diseases, genetic_testing, is_dead} = proband
        let member_age = age_string ? age_string : age
        const fullName = `${first_name || ''}${last_name || ''}, ${is_dead ? `d. ${member_age}` : member_age ? `${member_age}` : ''}`;
        setFullName(fullName)
        setProbandDiseases(diseases)
        setGenes(genetic_testing)
    },[])


    useEffect(() => {
      let familyMembers: any = []
      const getFamilyRelationships = async () => {
        try {
          const relationships = await familyApi.get_family_relationships(proband.id)
          for (let [key, profile] of Object.entries(profiles)) {
            const id = profile['id']
              if(relationships.hasOwnProperty(id) && !profile.is_proband && (profile.diseases?.length > 0 || profile.genetic_testing?.length > 0)){
                const relationship = relationships[id]
                if(relationship === 'Non blood related') continue
                profile['relationship_to_proband'] = relationship.charAt(0).toUpperCase() + relationship.slice(1);
                familyMembers.push(profile)
                
              }
          }
        } catch (error) {
          console.dir(error);
        }
        setFamilyMembers(familyMembers);
      };
    getFamilyRelationships();

    }, []);

  const getSkipString = (disease: any) => { 
      let skipString = ''
      skipString = skipLogicCheck(disease)
      if (typeof skipString !== 'string') {
        return ''
      }
      if (skipString && skipString.charAt(skipString.length - 1) === ',') {
        skipString = skipString.slice(0, -1);
      }
      return skipString
  }


  const generatePDF = async () => {
    const [pdfData, pdfHeight] = helper_dom_printing.createSvgFamilyHistory(fullName, probandDiseases, probandGenes, familyMember, pedigreeNotes)

    let printPayload ={
        height: pdfHeight,
        width: 730,
        svgData: pdfData,
        footerHeight: 0
      }

      let lastName = proband.last_name ? `${proband.last_name}-` : ''
      let firstName = proband.first_name ? `${proband.first_name}-` : ''  
      let patientId = proband.id ? `${proband.id}-`: ''
      
      try {
        let data = await familyApi.flux_pdf_generator(printPayload)
        const blob = createBlob(data, 'application/pdf')
        const blubUrl = URL.createObjectURL(blob)
        var downloadLink = document.createElement("a");
        downloadLink.href = blubUrl;
        downloadLink.download = `${lastName}${firstName}${patientId}Summary.pdf`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
      } catch(err){
        console.dir(err)
      }

}
 const  createBlob =  (b64Data: any, contentType='', sliceSize=512) =>{
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }


    const copyToClipboard = () =>{

      const modal = document.getElementById('family-history-modal');
      let range: Range | null;
      let sel: Selection | null;

      if (modal) {
        range = document.createRange();
        sel = window.getSelection();
        const updatedTable = modal.cloneNode(true) as HTMLElement;

        updatedTable.id = 'family-history-modal';
        document.body.appendChild(updatedTable);
        updatedTable.querySelectorAll('*').forEach((el: any) => {
          el.style.backgroundColor = 'transparent';
        });

        if (document.createRange && sel) {
          range = document.createRange();
          sel.removeAllRanges();

          try {
            range.selectNodeContents(updatedTable);
            sel.addRange(range);
          } catch (e) {
            console.log(e);
          }

          document.execCommand('copy');
          document.body.removeChild(updatedTable);
        }
        if (sel) {
          sel.removeAllRanges();
        }
    }
  }

  const noInfoHTML = () => {
    return (
      <div className="no-info">
        <h4 className="text-center">No Family History of Diseases or Genes</h4>
      </div>
    )
  }

  const geneticTestingString = (member: any) => {
    let hasNegativeGenes = member.genetic_testing.some((gene: any)=> gene.result === 'n')
    let hasNonNegative = member.genetic_testing.some((gene: any)=> gene.result !== 'n')
    return (
      <>
        {member.genetic_testing?.map((gene: any, index: number)=> (
          <>
            <span key={gene.id}>
              {geneMap[gene.result] ? `${gene.gene} ${geneMap[gene.result]};`: gene.result !=='n' ?  ` ${gene.gene} ${gene.result};` : ''}
            </span>
          </>
        ))}
        <span>
          {(hasNegativeGenes && !hasNonNegative ) ? ' Negative Genetic Testing; ' : ''}
        </span>
      </>

    )
  }

  const probandGeneticTesting = () => {
    let hasNegativeGenes = probandGenes.some((gene: any)=> gene.result === 'n')
    let hasPositiveGenes = probandGenes.some((gene: any)=> gene.result !== 'n')
    return(
      <>
        {probandGenes.map((gene: any, index: number)=> (
          <>
          <span key={gene.id}>
            {geneMap[gene.result] ? ` ${gene.gene} ${geneMap[gene.result]};`: gene.result !=='n' ?  `, ${gene.gene} ${gene.result};` : ''}
          </span>

          {/* {index !== gene.length - 1 && !hasNegativeGenes? '; ': ''} */}
          </ >
        
        ))}
        <span>
          {(!hasPositiveGenes && hasNegativeGenes) ? ' Negative Genetic Testing; ' : ''}
        </span>      
      </>
    )
  }
  const familyHistoryHTML = () => {
    return (
      <>
        <h4 className="modal-title text-capitalize">Family Health History</h4>
          <div className="family-member-list">
              {familyMember.map((member: any)=>(
                <>
                    <div key={member.id}>
                      <b>{`${member.relationship_to_proband}`}{member.is_dead || member.age_string ? ',': ''}{member.is_dead && ' d. '} {member.age_string ? member.age_string : ''} </b>
                      {member.diseases?.length > 0  || member.genetic_testing?.length > 0 ? '- ': '' }
                        {member.diseases.map((disease: any, index: number)=>(
                          <>
                          <span>
                            {disease.disease_name}{disease.age_diagnosed_string ? `, ${disease.age_diagnosed_string}` : disease.age_diagnosed ?  `, ${disease.age_diagnosed}` :''}
                          </span>
                          {getSkipString(disease) ? ` ${getSkipString(disease)}` : ''}
                          {index !== disease.skip_logics.length - 1 ? '; ': ''}
                          </>
                        ))}
                      {member.genetic_testing && geneticTestingString(member)}
                    </div>
                </>
              ))}
          </div>  
      </>
    )
  }
  const noFamilyHistory = (probandDiseases.length === 0  && probandGenes.length === 0 && familyMember.length === 0  ? true : false)

  return (
    <div
      className="modal fade in" 
      role="dialog"
      style={{ zIndex: 9999, display: "block", paddingTop: '45px', backgroundColor: 'transparent'}}
    >
      <div
        onClick={e => e.stopPropagation()}
        className="modal-dialog modal-lg"
        role="document"
      >
        <div className="modal-content">
            <div className="modal-header">
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={closeModal}
                >
                    <i onClick={closeModal}className="fa fa-close" />
                </button>
                <h4 className="modal-title text-capitalize">
                    Patient Summary
                </h4>
            </div>

          <div id='family-history-modal' className="modal-body">
            <div className="family-history-modal">
              
            {noFamilyHistory && noInfoHTML()}
            
            {(probandDiseases.length > 0  || probandGenes.length > 0) && (
              <>
                <h4 className="modal-title text-capitalize">Personal Health History</h4>
                <div className="family-history-proband">
                    <span>
                      <b> {fullName} </b> {probandDiseases.length > 0 || probandGenes.length > 0? ' - ': ''}
                    </span>
                    {probandDiseases.map((disease: any, index: number) =>(
                      <>
                        <span key={index}>
                            {disease.disease_name}{disease.age_diagnosed_string ? `, ${disease.age_diagnosed_string}` : disease.age_diagnosed ?  `, ${disease.age_diagnosed}` :''}
                            {getSkipString(disease) ? ` ${getSkipString(disease)}` : ''}
                        </span>
                        {index !== disease.skip_logics.length - 1 ? '; ': ''}
                      </>
                    ))}
                   {probandGeneticTesting()}
                </div>
              </>
            )}

            {familyMember.length > 0 && familyHistoryHTML()}
            
            {pedigreeNotes?.length > 0 &&(
              <>
                <h4 className="modal-title text-capitalize">Pedigree Notes</h4>
                  <p>
                    {pedigreeNotes}
                  </p>
              </>
            )}
            </div>
          </div>

        <div className="modal-footer">
          <div style={{textAlign: 'right'}}>
                <button
                    type="button"
                    onClick={closeModal}
                    className="btn btn-light-outline "
                    style={{ marginRight: 5 }}
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-dark "
                    data-toggle="modal"
                    data-dismiss="modal"
                    style={{ marginRight: 5 }}
                    disabled={noFamilyHistory}
                    onClick={copyToClipboard}

                  >
                    Copy
                  </button>
                  <button
                    type="button"
                    disabled={noFamilyHistory}
                    className="btn btn-dark "
                    data-toggle="modal"
                    data-dismiss="modal"
                    style={{ marginRight: 5}}
                    onClick={generatePDF}
                  >
                    Save as PDF
                  </button>
              </div>
              </div>

        </div>
      </div>
    </div>
    );
};

export default ModalFamilyHistory;
