import React, { Component } from 'react';
class BasePage extends Component {

  onBaseClick() {

  }

  render() {
    return (
      <React.Fragment>
        <div onClick={ () => this.onBaseClick() }>
          { this.props.children }
        </div>
      </React.Fragment>
    )
  }
}

export default BasePage;
