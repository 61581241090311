import React, { Component } from "react";
import { connect } from "react-redux";
import ModalError from "../../modal-error";
import { updateSharingSettings, getSharingSettings } from "../../../helpers/helper-notifications";
import family_api from '../../../api/family-api'
import account_api from '../../../api/user-account-api'
import notifications_api from '../../../api/notifications-api'
import { cloneDeep } from 'lodash'
import ErrorSummary from "../../error-summary";
import DatePicker from 'react-date-picker'
import IntlTelInput from 'react-intl-tel-input';
import moment from 'moment';
import * as countryCodes from '../../patient-profile/patient-data-tabs/dial-code'
import { radix_bases } from '../../../helpers/helper-number-bases';

const HIDE_LINK_GENERATE = ["individual"]

class NotificationSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      send_notification: false,
      received_notification: false,
      show_modal_error: false,
      family_id: null,
      missing_required_fields: [],
      connection_date: '',
      show_missing_data_form: false,
      show_generate_link_button: false,
      generated_link: '',
      profile: {
        first_name: '',
        // last_name: '',
        email: '',
        // phone_number: '',
        dob: '',
        age: '',
        // dial_code: '',
        gender: this.props.getPedigreeData().getProband().gender
      },
      errorMessages: []
    };

    this.invitePatient = this.invitePatient.bind(this);
    this.checkLinkGenerated = this.checkLinkGenerated.bind(this);
    this.handleChange = this.handleChange.bind(this)
    this.onChangeDate = this.onChangeDate.bind(this)
  }

  async componentDidMount() {
    this.loadSharingSetting();
    await this.checkLinkGenerated();
    let user = this.props.user;
    let proband = this.props.getPedigreeData().getProband()
    // in here, we either pass proband.duplicate_of_family or proband.family_id depending on which family id (from clinician or from patient)
    // is gonna be used when saving the connection request after using the new workflow that's gonna accept the clinician's invite
    let connection_date = await notifications_api.get_connection_date(user.clinician_id, proband.family_id);
    this.setState({ connection_date })
  }

  async handleUpdateSettings() {
    try {
      let {send_notification,  received_notification} = this.state
      let clinician_id = this.props.user.clinician_id
      let family_id  = this.state.family_id

      await updateSharingSettings(clinician_id, family_id, send_notification, received_notification);
    } catch (error) {
      console.log(error);
      this.setState({show_modal_error: true});
    }
  }

  async loadSharingSetting() {
    // getSharingSettings
    try {
      let family_id = this.props.getPedigreeData().getProband().family_id
      let share_settings = await getSharingSettings(family_id);
      this.setState({...share_settings, family_id});;
    } catch (error) {
      console.log(error);
      this.setState({show_modal_error: true});
    }
  }

  validateInvitePatientFields(){

    let missing_required_fields = []
    let proband = this.props.getPedigreeData().getProband()
    if (!proband.first_name) missing_required_fields.push('first_name')
    // if (!proband.last_name) missing_required_fields.push('last_name')
    if (!proband.email) missing_required_fields.push('email')
    // if (!proband.phone_number) missing_required_fields.push('phone_number')
    if (!proband.dob) missing_required_fields.push('dob')

    if(missing_required_fields.length > 0){
      this.setState({ missing_required_fields, show_missing_data_form: true, show_generate_link_button: false, generated_link: ''})
      throw new Error('Missing required fields.')
    }
  }

  async saveInvitePatientFields(){
    try{
      let proband = this.props.getPedigreeData().getProband()
      let member = await family_api.patch_member_memberid(proband.id, this.state.profile)
      let profile = cloneDeep(this.props.getPedigreeData().getProfile(proband.rkey))

      profile.first_name = member.first_name
      // profile.last_name = member.last_name
      profile.email = member.email
      // profile.phone_number = member.phone_number
      profile.dob = member.dob
      profile.age = member.age
      // profile.dial_code = this.state.profile.dial_code || this.props.getPedigreeData().getProband().dial_code
      profile.gender = member.gender

      this.props.getPedigreeData().setProfile(profile.rkey, profile);

      await this.invitePatient();

      //patch here for the member
      //phone number and dob validate check needed
      //update also the profile on pedigreeData
      //call invitePatient method again
      //setstate generated_link , show_missing_data_form, show_generate_link_button
    }
    catch(error){
      console.log(error)
      this.setState({ errorMessages: [error.message] })
    }

  }

  async invitePatient() {
    try {
      await this.validateInvitePatientFields();

      const org_data = await account_api.get_org_by_id(this.props.user.organization_id);
      const proband = this.props.getPedigreeData().getProband()
      let payload = {
        email: proband.email,
        // phone_number: proband.phone_number,
        // dial_code: proband.dial_code,
        first_name: proband.first_name,
        // last_name: proband.last_name,
        dob: proband.dob,
        duplicate_of_family: proband.family_id,
        clinician_code: org_data.clinician_code.code,
        patient_id: this.props.getPedigreeData().getProband().patient_id
      }

      let link = await family_api.invite_patient(payload)
      if(link && link.url){
        this.setState({ generated_link: link.url, show_missing_data_form: false, show_generate_link_button: false, missing_required_fields: [] })
      }
      else{
        this.setState({ generated_link: '', show_generate_link_button: true })
      }
    } catch (error) {
      console.log(error);
      if (error.message === "This email is already in use.") {
        this.setState({
           show_missing_data_form: true,
           missing_required_fields: ["email"],
           show_generate_link_button: false
         });
        document.getElementById("error-handle").innerText = "This email is already in use.";
      }
    }
  }

  async checkLinkGenerated(){
    try {
      const proband = this.props.getPedigreeData().getProband()
      let payload = {
        duplicate_of_family: proband.family_id,
      }
      let link = await family_api.clin_to_patient_url(payload)
      if(link && link.url){
        this.setState({ generated_link: link.url, show_missing_data_form: false, show_generate_link_button: false, missing_required_fields: [] })
      } else if (proband.duplicate_of_family) {
        this.setState({ generated_link: 'Connection request from patient', show_missing_data_form: false, show_generate_link_button: false, missing_required_fields: [] })
      } else {
        this.setState({ generated_link: '', show_generate_link_button: true })
      }

      // set profile state based on pedigree data store
      let profile = {
        first_name: proband.first_name,
        // last_name: proband.last_name,
        email: proband.email,
        // phone_number: proband.phone_number,
        dob: proband.dob,
        age: proband.age,
        // dial_code: proband.dial_code,
        gender: proband.gender
      }
      this.setState({ profile })
    } catch (error) {
      console.log(error)
      this.setState({ errorMessages: [error.message] })
    }
  }

  handleChange(event) {
    let profile = cloneDeep(this.state.profile);
    if (profile) {
      profile[event.target.name] = event.target.value;
      this.setState({ profile: profile });
    }
  }

  onChangeDate(e){
    let profile = cloneDeep(this.state.profile);
    if(e == null) {
      profile["dob"] = null;
    } else {

      let month = e.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }

      let day = e.getDate();
      if (day < 10) {
        day = `0${day}`;
      }

      const string_dob = e.getFullYear() + "-" + month + "-" + day;
      profile["dob"] = string_dob;

      // Check and make sure age + yob is not greater than current year
      if (this.props.getPedigreeData().getProband().is_dead) {
        let age = profile["age"];
        if (string_dob !== "") {
          let year = moment().year();
          let yob = year - moment().diff(string_dob, "years");
          if (
            parseInt(age, radix_bases.base10) +
              parseInt(yob, radix_bases.base10) >
            parseInt(year, radix_bases.base10)
          ) {
            profile["age"] = "";
            profile["yob"] = moment(string_dob).year();
          }
        }
      } else {
        if (string_dob !== "") {
          let age = moment().diff(string_dob, "years");
          if (age >= 0) profile["age"] = age;
          profile["yob"] = moment(string_dob).year();
        }
      }
    }

    this.setState({ profile });
  }

  async onPhoneSelect(c, country) {
    let profile = cloneDeep(this.state.profile);
    profile['phone_number'] = ''
    this.setState({ profile })

    let proband = this.props.getPedigreeData().getProband()
    let proband_profile = cloneDeep(this.props.getPedigreeData().getProfile(proband.rkey))
    proband_profile.phone_valid = true
    proband_profile.phone_number = ""
    delete proband_profile.unique_id

    this.props.getPedigreeData().setProfile(proband.rkey, proband_profile)
  }

  async onPhoneChange(phone_valid, phone, c) {
    let profile = cloneDeep(this.state.profile);
    profile['phone_number'] = phone
    profile['dial_code'] = c.dialCode
    this.setState({ profile })

    let proband = this.props.getPedigreeData().getProband()
    let proband_profile = cloneDeep(this.props.getPedigreeData().getProfile(proband.rkey))
    proband_profile.phone_valid = phone_valid
    proband_profile.phone_number = phone
    proband_profile.dial_code = c.dialCode
    delete proband_profile.unique_id

    this.props.getPedigreeData().setProfile(proband.rkey, proband_profile)
  }



  render() {
    let disableGenerateLink = HIDE_LINK_GENERATE.includes(this.props.user?.account_type) ? true : false
    let options = {year: 'numeric', month: 'long', day: 'numeric' };
    let { send_notification,  received_notification} = this.state;
    let connection_date = this.state.connection_date;
    let connection_date_text = connection_date ? new Date(connection_date).toLocaleDateString(undefined, options) : undefined

    let dob = '';
    if (this.state.profile.dob) {
      try {
        dob = new Date(this.state.profile.dob + "T12:00:00");
      } catch (error) {
        dob = '';
      }
    }
    let minDate = new Date('1800-01-01' + "T12:00:00")

    let country_dial_code = ' ';
    let proband = this.props.getPedigreeData().getProband()
    if(proband.phone_number && proband.dial_code && country_dial_code !== proband.dial_code){
      let number = proband.phone_number.replace(/[()]/g,'');
      let area_code = number.substring(0,3);
      for(let code of countryCodes.allCountries){
          if(proband.dial_code === code[2]){
              country_dial_code = proband.dial_code ==='1' && countryCodes.canadaAreaCodes.includes(area_code) ? 'ca': code[1];
              break;
          }
        }
    }else if(this.props.getPedigreeData().getProband().dial_code){
      country_dial_code = this.props.getPedigreeData().getProband().dial_code;
    }

    return (
      <div className="col col-right full-height">
        <div className="sidebar-collapsible-wrap">
          <div className="sidebar-collapsible">
            <div className="panel-group toggles-style1 no-border">
              <div className="panel panel-default" id="collapse-one">
                <div role="tablist" id="headingOne" className="panel-heading">
                  <a
                    data-toggle="collapse"
                    data-parent="#collapse-one"
                    href="#collapse-one-link"
                  >
                    <h4 className="panel-title">
                      Settings
                      <span className="pull-right"></span>
                    </h4>
                  </a>
                </div>
                <div id="collapse-one-link1" className="panel-collapse">
                  <div className="panel-body">
                    {/* <form> */}
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-header-2 hidden">
                            <div className="row vcenter">
                              <div className="col-lg-12 ">
                                <h3 className="title">
                                  Connected with Patient
                                </h3>
                              </div>
                            </div>
                          </div>

                          { connection_date_text &&
                          <div className="col-lg-12 margin-six no-margin-bottom hidden">
                            <div className="row">
                              <p>Connected with patient: {connection_date_text}</p>
                            </div>
                          </div>
                          }

                          <div className="col-lg-12 nav--center hidden">
                            <div className="row">
                              <a
                                style={{ marginRight: "5px !important" }}
                                href="patient-data-update--email.html"
                                className="btn btn-teal--outline no-margin"
                              >
                                Email
                              </a>

                              <a
                                href="patient-data-update--text.html"
                                className="btn btn-teal--outline no-margin"
                              >
                                Text
                              </a>
                            </div>
                          </div>

                          <ErrorSummary errorMessages={this.state.errorMessages} />

                          {this.state.show_missing_data_form &&
                          <div>
                            <div className="col-lg-12">
                              <div className="row">
                                <label>
                                  Invite this patient to access their record and be connected
                                </label>
                                <label id="error-handle" style={{ color: 'red', marginTop: '20px' }}>
                                  Fields below are required.
                                </label>
                              </div>
                            </div>
                            <div className="form-vertical">
                              <div className="row">
                                <div style={{ paddingLeft: '15px', paddingRight: '30px' }}>
                                  {this.state.missing_required_fields.map(field => {
                                    if (field === 'first_name') {
                                      return <div className="form-group" style={{ marginBottom: 5 }}>
                                        <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                                          <div className="col-md-4 col-sm-3 col-xs-12">
                                            <label htmlFor="first_name" className="control-label">First Name</label>
                                          </div>
                                          <div className="col-md-8 col-sm-9 col-xs-12">
                                            <input name="first_name" id="first_name" type="text" className="form-control normal-input-text" onChange={(event) => this.handleChange(event)} value={this.state.profile.first_name} />
                                          </div>
                                        </div>
                                      </div>
                                    }
                                    // else if (field === 'last_name') {
                                    //   return <div className="form-group" style={{ marginBottom: 5 }}>
                                    //     <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                                    //       <div className="col-md-4 col-sm-3 col-xs-12">
                                    //         <label htmlFor="last_name" className="control-label">Last Name</label>
                                    //       </div>
                                    //       <div className="col-md-8 col-sm-9 col-xs-12">
                                    //         <input name="last_name" id="last_name" type="text" className="form-control normal-input-text" onChange={(event) => this.handleChange(event)} value={this.state.profile.last_name} />
                                    //       </div>
                                    //     </div>
                                    //   </div>
                                    // }
                                    else if (field === 'email') {
                                      return <div className="form-group" style={{ marginBottom: 5 }}>
                                        <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                                          <div className="col-md-4 col-sm-3 col-xs-12">
                                            <label htmlFor="email" className="control-label">Email</label>
                                          </div>
                                          <div className="col-md-8 col-sm-9 col-xs-12">
                                            <input name="email" id="email" type="email" className="form-control normal-input-text" onChange={(event) => this.handleChange(event)} value={this.state.profile.email} />
                                          </div>
                                        </div>
                                      </div>
                                    }
                                    // else if (field === 'phone_number') {
                                    //   return <div className="form-group" style={{ marginBottom: 5 }}>
                                    //   <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                                    //     <div className="col-md-4 col-sm-3 col-xs-12">
                                    //       <label htmlFor="phone" className="control-label">Phone</label>
                                    //     </div>
                                    //     <div className="col-md-8 col-sm-9 col-xs-12">
                                    //       <IntlTelInput
                                    //         onlyCountries={countryCodes.iso2}
                                    //         name="phone_number"
                                    //         id="phone_number"
                                    //         value={this.state.phone_number}
                                    //         format={true}
                                    //         inputClassName="phone-input"
                                    //         defaultCountry={country_dial_code}
                                    //         onSelectFlag={(phone,c) => {
                                    //           this.onPhoneSelect(phone, c);
                                    //         }}
                                    //         onPhoneNumberChange={(phone_valid,phone,c) => {
                                    //           this.onPhoneChange(phone_valid, phone,c);
                                    //         }}
                                    //       />
                                    //     </div>
                                    //   </div>
                                    // </div>
                                    // }
                                    else if (field === 'dob') {
                                      return <div className="form-group" style={{ marginBottom: 5 }}>
                                      <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                                      <label htmlFor="dob" className="col-md-4 col-sm-3 col-xs-12 control-label">Date of Birth</label>
                                      <div className="col-sm-8">
                                        <DatePicker
                                          id="member_info_dob"
                                          name="dob"
                                          onChange={this.onChangeDate}
                                          value={dob}
                                          className="form-control"
                                          calendarIcon={null}
                                          clearIcon={null}
                                          dayPlaceholder={"dd"}
                                          monthPlaceholder={"mm"}
                                          yearPlaceholder={"yyyy"}
                                          disableCalendar={true}
                                          showLeadingZeros={true}
                                          minDate={minDate}
                                        />
                                      </div>
                                      </div>
                                    </div>
                                    }
                                  })}
                                </div>
                              </div>
                            </div>
                            <div className="text-right">
                              <button onClick={() => this.saveInvitePatientFields()} className="btn btn-teal margin-three-top">Save</button>
                            </div>
                          </div>
                          }

                          {this.state.show_generate_link_button &&
                            <div className="col-lg-12">
                              <div className="row">
                                <label>
                                  Invite this patient to access their record and be connected
                                </label>
                                <div className="text-center">
                                  <button
                                    disabled={disableGenerateLink}
                                    onClick={() => this.invitePatient()}
                                    className={ disableGenerateLink ? "btn btn-teal disabled" : "btn btn-teal margin-three-top"}
                                    title={disableGenerateLink ? "To enable you must upgrade your account." : ""}>
                                      GENERATE LINK
                                  </button>
                                </div>
                              </div>
                            </div>
                          }

                          {this.state.generated_link &&
                            <div className="col-lg-12">
                              <div className="form-vertical">
                                <div className="row">
                                  {this.state.generated_link != 'Connection request from patient' && <div>
                                    <label>
                                      Invite this patient to access their record and be connected
                                    </label>
                                    <div>
                                      <div className="form-group" style={{ marginBottom: 10 }}>
                                        <div className="row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                          <div className="col-md-8 col-sm-9 col-xs-12">
                                            <input name="link" id="link" type="text" className="form-control normal-input-text" value={this.state.generated_link} readOnly/>
                                          </div>
                                          <div className="col-md-4 col-sm-3 col-xs-12">
                                            <button onClick={() => {navigator.clipboard.writeText(this.state.generated_link)}} className="btn btn-teal margin-three-top">Copy</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>}
                                  <span>{connection_date_text ? 'Connected: ' + connection_date_text : 'Not Connected'}</span>
                                </div>
                              </div>
                            </div>
                          }

                          <div className="col-lg-12">
                            <div className="row">
                              <hr />
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="row">
                              <form className="form-horizontal">
                                <div className="margin-ten-bottom">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <label>
                                        Notify me when patient updates data{" "}
                                      </label>
                                    </div>

                                    <div className="col-md-4 text-right">

                                      <div className="checkbox">
                                        <label className="switch">
                                          <input type="checkbox" checked={received_notification} onChange={() => {
                                            this.setState(prev => ({received_notification: !prev.received_notification}),()=>{
                                              this.handleUpdateSettings();
                                            })
                                          }} />
                                          <span className="slider round"></span>
                                        </label>
                                      </div>

                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-8">
                                    <label>
                                      Notify patient when I update data
                                    </label>
                                  </div>

                                  <div className="col-md-4 text-right">
                                    <div className="checkbox">
                                      <label className="switch">
                                        <input type="checkbox" checked={send_notification} onChange={() => {
                                          this.setState(prev => ({send_notification: !prev.send_notification}),()=>{
                                            this.handleUpdateSettings();
                                          });
                                        }} />
                                        <span className="slider round"></span>
                                      </label>
                                    </div>
                                  </div>

                                </div>

                              </form>
                            </div>
                          </div>
                        </div>
                      </div>

                    {/* </form> */}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.show_modal_error && (
          <ModalError
            onClose={() => this.setState({ show_modal_error: false })}
          />
        )}

      </div>
    );
  }
};

const mapStateToProps = state => ({
  user: state.session.user,
  proband: state.patient.patient
});

export default connect(mapStateToProps)(NotificationSettings);
