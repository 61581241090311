export default {
	SAVE_PATIENT: "patient/SAVE_PATIENT",
	SAVE_PATIENT_DATA: "patient/SAVE_PATIENT_DATA",
	SAVE_PROFILE: "patient/SAVE_PROFILE",
	DELETE_PROFILE: "patient/DELETE_PROFILE",
	CLEAR_PATIENT: "patient/CLEAR_PATIENT",
	CLEAR_PROFILES: "patient/CLEAR_PROFILES",
	FILL_PROFILES:  "patient/family_tree/FILL_PROFILES",

	UPDATE_FAMILY: "patient/UPDATE_FAMILY",
	UPDATE_FAMILY_TREE: "patient/UPDATE_FAMILY_TREE",

	POPULATE_PARTNERS_BY_COUNT: "patient/family_tree/POPULATE_PARTNERS_BY_COUNT",
	SAVE_PARTNERS: "patient/family_tree/SAVE_PARTNERS",
	SAVE_PARTNER_DETAIL: "patient/family_tree/SAVE_PARTNER_DETAIL",
	CLEAR_PARTNERS: "patient/family_tree/CLEAR_PARTNERS",
	DELETE_PARTNER: "patient/family_tree/DELETE_PARTNER",
	FILL_PARTNERS:  "patient/family_tree/FILL_PARTNERS",

	FILL_SIBLINGS: "patient/family_tree/FILL_SIBLINGS",
	POPULATE_SIBLINGS_BY_COUNT: "patient/family_tree/POPULATE_SIBLINGS_BY_COUNT",
	SAVE_SIBLINGS: "patient/family_tree/SAVE_SIBLINGS",
	SAVE_SIBLING_DETAIL: "patient/family_tree/SAVE_SIBLING_DETAIL",
	CLEAR_SIBLINGS: "patient/family_tree/CLEAR_SIBLINGS",
	DELETE_SIBLING: "patient/family_tree/DELETE_SIBLING",

	// POPULATE_SONS_DAUGHTERS_BY_COUNT: "patient/family_tree/POPULATE_SONS_DAUGHTERS_BY_COUNT",
	FILL_SONS_DAUGHTERS: "patient/family_tree/FILL_SONS_DAUGHTERS",
	SAVE_SONS_DAUGHTERS_BY_GENDER: "patient/family_tree/SAVE_SONS_DAUGHTERS_BY_GENDER",
	SAVE_SONS_DAUGHTERS: "patient/family_tree/SAVE_SONS_DAUGHTERS",
	SAVE_SON_DAUGHTER_DETAIL: "patient/family_tree/SAVE_SON_DAUGHTER_DETAIL",
	CLEAR_SONS_DAUGHTERS: "patient/family_tree/CLEAR_SONS_DAUGHTERS",
	DELETE_SON_DAUGHTER: "patient/family_tree/DELETE_SON_DAUGHTER",

	FILL_UNCLES_AUNTS: "patient/family_tree/FILL_UNCLES_AUNTS",
	POPULATE_UNCLES_AUNTS_BY_COUNT: "patient/family_tree/POPULATE_UNCLES_AUNTS_BY_COUNT",
	SAVE_UNCLES_AUNTS_BY_GENDER: "patient/family_tree/SAVE_UNCLES_AUNTS_BY_GENDER",
	SAVE_UNCLES_AUNTS: "patient/family_tree/SAVE_UNCLES_AUNTS",
	SAVE_UNCLE_AUNT_DETAIL: "patient/family_tree/SAVE_UNCLE_AUNT_DETAIL",
	CLEAR_UNCLES_AUNTS: "patient/family_tree/CLEAR_UNCLES_AUNTS",
	DELETE_UNCLE_AUNT: "patient/family_tree/DELETE_UNCLE_AUNT",

	DELETE_PARENTS_IDS_ON_TOP_LEVEL_DELETE: "patient/family_tree/DELETE_PARENTS_IDS_ON_TOP_LEVEL_DELETE",

	SET_TWINS: "patient/family_tree/SET_TWINS",

	SAVE_HISTORY_DETAIL: "patient/history/SAVE_HISTORY_DETAIL",

	FILL_HISTORY_DISEASES: "patient/history/FILL_HISTORY_DISEASES",
	SAVE_HISTORY_DISEASES: "patient/history/SAVE_HISTORY_DISEASES",
	SAVE_HISTORY_DISEASE_DETAIL: "patient/history/SAVE_HISTORY_DISEASE_DETAIL",
	DELETE_HISTORY_DISEASE_DETAIL: "patient/history/DELETE_HISTORY_DISEASE_DETAIL",
	CLEAR_HISTORY_DISEASES: "patient/history/CLEAR_HISTORY_DISEASES",

	FILL_HISTORY_GENES: "patient/history/FILL_HISTORY_GENES",
	SAVE_HISTORY_GENES: "patient/history/SAVE_HISTORY_GENES",
	SAVE_HISTORY_GENE_DETAIL: "patient/history/SAVE_HISTORY_GENE_DETAIL",
	DELETE_HISTORY_GENE_DETAIL: "patient/history/DELETE_HISTORY_GENE_DETAIL",
	CLEAR_HISTORY_GENES: "patient/history/CLEAR_HISTORY_GENES",

	SAVE_RISK_FACTORS: "patient/risk_factors/SAVE_RISK_FACTORS",
	CLEAR_RISK_FACTORS: "patient/risk_factors/CLEAR_RISK_FACTORS",

	SAVE_DOCUMENTS: "patient/documents/SAVE_DOCUMENTS",
	SAVE_DOCUMENT: "patient/documents/SAVE_DOCUMENT",
	DELETE_DOCUMENT: "patient/documents/DELETE_DOCUMENT",

	SAVE_RISK_SETTINGS: "patient/risk_settings/SAVE_RISK_SETTINGS",

	SAVE_RISK_RESULTS: "patient/risk_results/SAVE_RISK_RESULTS",
	RESET_RISK_RESULTS: "patient/risk_results/RESET_RISK_RESULTS",

	SAVE_RISK_CRITERIA: "patient/risk_criteria/SAVE_RISK_CRITERIA",

	SAVE_HIDDEN_DISEASE_COLORS: "patient/hidden_disease_colors/SAVE_HIDDEN_DISEASE_COLORS",
	ADD_TO_HIDDEN_DISEASE_COLORS: "patient/hidden_disease_colors/ADD_TO_HIDDEN_DISEASE_COLORS",
	REMOVE_FROM_HIDDEN_DISEASE_COLORS: "patient/hidden_disease_colors/REMOVE_FROM_HIDDEN_DISEASE_COLORS",
	SAVE_HAS_HALF_OR_GREAT_UNCLES_AUNTS: "patient/has_half_or_great_uncles_aunts/SAVE_HAS_HALF_OR_GREAT_UNCLES_AUNTS",

	SAVE_FAMILY_PEDIGREE_NOTES: 'patient/family_pedigree_notes/SAVE_FAMILY_PEDIGREE_NOTES'
}
