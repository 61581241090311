import notifications_api from "../api/notifications-api";
import { get_family } from "../api/family-api";
import { notifications_received } from '../store/notifications/actions';
import moment from 'moment';
import settings from "../configs/settings";

export const populateNotifications = async (dispatch, clinician_id) => {
  let notifications = await notifications_api.post_clinician_notification(
    clinician_id
  );
  dispatch(notifications_received(notifications));
}

export const filterNotificationByType = (notifications, types, status = 'all') => {
  if(notifications === undefined) return [];

  let filtered_notis =  notifications.filter(item => types.includes(item.notification_type));
  if (status !== 'all') return filtered_notis.filter(n => n.status === status);
  return filtered_notis;
}

export const filterClinicianRequest = (notifications, status ='all') => {
  let types = ['clinician_request'];
  return filterNotificationByType(notifications, types, status);
}

export const filterNotificationUpdates = (notifications, status = 'all') => {
  let updates =  filterNotificationByType(notifications, settings.clinician_notification_types, status);

  // Make sure pending is always on top
  return updates.sort((a,b) => {
    if(a.status === 'pending' && b.status !== 'pending') return -1;
    if(a.status !== 'pending' && b.status === 'pending') return 1;
    return 0;
  })
}

export const acceptOrDenyNotification = async (notification_id, clinician_id, user_id, action=undefined) => {
  //Action = accept or deny
  let payload = { clinician_id, user_id, response: action };
  return await notifications_api.post_notification_notificationid_response(notification_id, payload);
}

export const updateSharingSettings = async (clinician_id, family_id, send_notification, received_notification) => {
  let payload = { clinician_id, family_id, send_notification, received_notification };
  return await notifications_api.post_update_sharing_settings(payload);
}

export const getSharingSettings = async (family_id) => {
  let family = await get_family(family_id);
  return {
    send_notification: family.clinician_send_notification,
    received_notification: family.clinician_received_notification
  }
}

export const notificationsGroupedByName = (notifications, is_read=undefined) => {
  let groups = {};
  let filtered_notifications = is_read === undefined ? notifications : notifications.filter(n => n.is_read == is_read);

  // add a family modified at timestamp that is equal to the most recent update from any family member
  for(let item of filtered_notifications) {
    let group = groups[item.from_family_id];
    if(group == undefined) {
      group = {...item, updates: [item], most_recent_created_at: item.created_at}
      groups = {...groups, [item.from_family_id]: group}
    } else {
      let { updates } = group;

      if (moment(group.most_recent_created_at) < moment(item.created_at)) {
        group.most_recent_created_at = item.created_at;
      }

      group = {...group, updates: [...updates, item]};
      groups = {...groups, [item.from_family_id]: group};
    }
  }

  return groups;
}
