import React  from 'react';

export default (props) => {

  if(props.type === 'add') {
      return (
        <button
          onClick={() => props.onClick()}
          className="btn btn-light btn-xs table-btn-add">
          <i className="fa fa-plus"></i> Add
        </button>
      );
  } else {
      return (
        <button
          onClick={() => props.onClick()}
          className="btn btn-teal btn-xs table-btn-edit">
          <i className="fa fa-pencil"></i> Edit
        </button>
      );
  }
}
