import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { components } from 'react-select';


class ModalSaveReport extends Component {
    constructor(props) {
        super(props);
        this.state ={
        title: ''
        }
    
        this.handleChange = this.handleChange.bind(this);
        this.onSave = this.onSave.bind(this);
      }
      
    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    onSave(event){
        this.props.onClickSave(this.state.title);
    }
    

render(){
    return ReactDOM.createPortal(
        <div
        onClick={() => this.props.onClose()}
        style={{ display: 'block' }}
        className="modal fade in"
        role="dialog"
        >

        <div onClick={(e) => e.stopPropagation()} className="modal-dialog modal-sm" role="document">
          <div className="modal-content">

            <div className="modal-header">
              <button onClick={() => this.props.onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="fa fa-close"></i>
              </button>
              <h4 className="modal-title text-white text-capitalize">Save Report</h4>
            </div>
            
            <div className="modal-body">
            <h6 className="modal-title text-white text-capitalize" style={{color: 'grey', fontSize: '13px'}}>Report Name</h6>
                <div className="modal-confirm">
                    <input 
                    name="title"
                    value={this.state.title}
                    type="text"
                    onChange={this.handleChange}
                    />
                </div>
            </div>
            <div className="modal-footer" style={{textAlign: 'center'}}>
              <button onClick={() => this.props.onClose()} className="btn btn-light-outline no-margin-right">Cancel</button>
              <button onClick={this.onSave} className="btn btn-dark" data-dismiss="modal" aria-label="Close">Save</button>
            </div>
          </div>
        </div>
    </div>,
      document.body
    );
}

}


export default ModalSaveReport