import React, { Component } from 'react';

class ValidationErrorSummary extends Component {

  getErrorSummary() {
    if (this.props.validations == null){
      return []
    }

    return this.props.validations.summary
  }

  render() {

    if(this.getErrorSummary().length === 0) return <React.Fragment />

    return (
      <div className="row">
        <div className="col-md-6">
          <div className="alert alert-danger ">
            <ul className="validation-summary">
              {this.getErrorSummary().map((item, index) =>
                <li key={index} className="validation-summary__li">{item}</li>
              )}
            </ul>
          </div>
        </div>
      </div>
    )

  }
}

export default ValidationErrorSummary
